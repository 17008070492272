import React, { useState, Fragment, useCallback } from "react";
import { Modal, ModalBody } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Question from "../../../../assets/images/Question.svg";
import GroupImg from "../../../../assets/images/Group.svg";
import CrossImg from "../../../../assets/images/cross.svg";
// import Api from "../../utils/api/api";
import apiNew from "../../utils/api/apiNew";
import Loader from "../Loader/Loader";

const VoidSelected = ({ orders, onSuccess }) => {
  const [modal, setModal] = useState(false);
  const [voidmodal, setVoidModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorFind, setErrorFind] = useState(false);

  const handleVoidSelect = useCallback(async () => {
  const orderIdList = orders.filter((order)=>{
     return order.transactionName === 'Order'
   }).map((order)=>{
       return order.id 
   });  

 const ticketIdList =  orders.filter((order)=>{
	return order.transactionName !== 'Order'
  }).map((order)=>{
	  return order.id 
  });

    setModal(false);
    setLoading(true);
    try {
      const data = {};
      if(ticketIdList?.length>0){
        data['ticketIdList'] = ticketIdList;
      }
      if(orderIdList?.length>0){
        data['orderIdList'] = orderIdList;
      }
      apiNew.post(
        `${process.env.REACT_APP_VOID_REQUEST_API_ENDPOINT}`,
        data
      ).then((res) => {
        if (res.data.orbitResponse.statusCode !== 500) {
          setLoading(false);
		  setVoidModal(true);
		  if(typeof onSuccess === 'function') {
			onSuccess()
		  }
        } else {
          setLoading(false);
          setErrorFind(true);
        }
      }).catch((err) => {
		setLoading(false);
		setErrorFind(true);
	});
    } catch (err) {
      setLoading(false);
      setErrorFind(true);
    }
        console.log("🚀 ~ file: index.jsx ~ line 55 ~ handleVoidSelect ~ orderIdList", orderIdList)
        console.log("🚀 ~ file: index.jsx ~ line 55 ~ handleVoidSelect ~ orderIdList", orderIdList)
        console.log("🚀 ~ file: index.jsx ~ line 55 ~ handleVoidSelect ~ orderIdList", orderIdList)
  }, [orders, onSuccess]);

  return (
    <Fragment>
      <img src={Question} alt="Question" className="d-none" />
      <img src={GroupImg} alt="GroupImg" className="d-none" />
      <img src={CrossImg} alt="CrossImg" className="d-none" />
      {loading ? (
        <Loader />
      ) : (
        <div className="journal-area">
          <div className="btn-area">
            <button
              className="btn"
              disabled={!orders.length}
              onClick={() => setModal(!modal)}
            >
              Void Selected
            </button>
          </div>
          <Modal
            className="journal-modal"
            show={modal}
            onHide={() => setModal(!modal)}
          >
            <ModalHeader closeButton />
            <ModalBody>
              <img src={Question} alt="GroupImg" />
              <p>Are you sure you</p>
              <p>want to void</p>
              <p>selected record?</p>
              <div className="text-center" style={{marginTop: '80px'}}>
                <button onClick={() => handleVoidSelect()}>Yes</button>
              </div>
              <div className="text-center" onClick={() => setModal(!modal)}>
                <span className="logout-cancel">Cancel</span>
              </div>
            </ModalBody>
          </Modal>
          <Modal
              className="journal-modal"
              show={voidmodal}
              onHide={() => setVoidModal(!voidmodal)}
            >
              <ModalHeader closeButton />
              <ModalBody>
                <img src={GroupImg} alt="GroupImg" style={{marginBottom: '40px'}} />
                <p>Transaction has been </p>
                <p>successfully voided</p>
                <div className="text-center" style={{marginTop: '120px'}}>
                  <button onClick={() => setVoidModal(!voidmodal)}>Ok</button>
                </div>
              </ModalBody>
            </Modal>
        
            <Modal
              className="journal-modal"
              show={errorFind}
              onHide={() => setErrorFind(!errorFind)}
            >
              <ModalHeader closeButton />
              <ModalBody>
                <img src={CrossImg} alt="GroupImg" style={{marginBottom: '40px'}} />
                <p>To void this transaction </p>
                <p>the customer needs to</p>
                <p>contact customer support</p>
                <div className="text-center" style={{marginTop: '120px'}}>
                  <button onClick={() => setErrorFind(!errorFind)}>Ok</button>
                </div>
              </ModalBody>
            </Modal>
        
        </div>
      )}
    </Fragment>
  );
};

export default VoidSelected;
