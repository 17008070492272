import { combineReducers } from "redux";
import busFares from "./busFares/busFaresReducer";
import authReducer from "./auth/authReducer";
import checkout from "./checkout/checkout";
import orderStatus from "./orderStatus/orderStatusReducer";
import generateTickets from "./generateTickets/generateTicketsReducer";
import headerReducer from "./header/headerReducer";
import profileReducer from "./profile/profileReducer";
import appReducer from "./app/appReducer";
import walletReducer from "./wallet/walletReducer";
import ticketFareRuleReducer from './ticketFareRule/ticketFareRuleReducer';
import landingReducer from './landing/landingReducer';

export default combineReducers({
  busFares,
  authReducer,
  checkout,
  orderStatus,
  generateTickets,
  headerReducer,
  profileReducer,
  appReducer,
  walletReducer,
  ticketFareRuleReducer,
  landingReducer
});
