import { get, groupBy, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Modal, ModalBody, Row } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { FaSearch } from 'react-icons/fa';

import CrossImg from '../../../../assets/images/cross.svg';
import Api from '../../utils/api/api';
import apiNew from '../../utils/api/apiNew';
import { matchInObject } from '../../utils/helper';
import { AppTable, rowExpanderColumn } from '../Table';
import Theme from '../Theme';
import VoidSelected from '../VoidSelected';

let firstSelectedOrderType = null;

export const JournalList = () => {
  const [listData, setListData] = useState([]);
  const [alertMessage, setAlertMessage] = useState('');
  const [listOrignalData, setListOrignalData] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState({});
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const fetchOrderData = async () => {
    const userDetails = isEmpty(JSON.parse(localStorage.getItem('cognitoUser')))
      ? { userEmail: '' }
      : JSON.parse(localStorage.getItem('cognitoUser'));
    const response = await apiNew.post(
      `${process.env.REACT_APP_JOURNAL_REQUEST_API_ENDPOINT}`,
      {
        emailId: userDetails.emailID,
        mode: 'preview',
      },
    );
    const tickets = get(response, 'data.orbitResponse.body.list', []);
    const total = get(response, 'data.orbitResponse.body.totalRecord', 0);

    return {
      data: tickets,
      total: total,
    };
  };

  /**
   * For change pymt method name
   */

  const paymentTypeUpdater = (paymentType) => {
    if (paymentType === 'cash') return 'CSH';
    if (paymentType === 'cc') return 'CRD';
    if (paymentType === 'voucher') return 'VCH/CP';
    if (paymentType === 'check') return 'CHK';
    return paymentType;
  };

  /**
   * For change pymt method name
   */

  const paymentModeUpdater = (method) => {
    if (method.paymentMethod === 'cash') {
      return '';
    } else if (method.paymentMethod === 'cc') {
      return ` (x${method.ccFourdigit})`;
    } else if (method.paymentMethod === 'voucher') {
      return `${
        method.voucherNumber.length >= 4
          ? ` (x${method.voucherNumber.slice(method.voucherNumber.length - 4)})`
          : ` (${method.voucherNumber})`
      }`;
    } else if (method.paymentMethod === 'check') {
      return `${
        method.chequeNumber.length >= 4
          ? ` (x${method.chequeNumber.slice(method.chequeNumber.length - 4)})`
          : ` (${method.chequeNumber})`
      }`;
    } else {
      return '';
    }
  };

  /**
   * When Row Expands
   */
  const handleRowExpand = useCallback(async (row, rest) => {
    if (row.depth === 1) {
      return;
    }
    const rows = rest.data;
    const orderId = row.original.id;
    const oldSeldctedIndex = {};
    if (!row.isExpanded && row.depth === 0) {
      setLoading(true);
      const ticketResponse = await Api.fetch(
        `${process.env.REACT_APP_JOURNAL_ORDER_TICKETS_API_ENDPOINT}${orderId}?requestedFor=journal`,
      );
      setLoading(false);

      const tickets = get(
        ticketResponse,
        'data.orbitResponse.body.ticketList.Items',
        [],
      );
      const updatedListData = rows.map((order, index) => {
        if (order.id === orderId) {
          order.expanded = true;
          order.subRows = tickets;
        } else {
          const orderRow = rest.rows.filter(
            (row) => row.original.id === order.id,
          )[0];
          order.isExpanded =
            orderRow.isExpanded || orderRow.original.isExpanded;
          if (orderRow.isSelected || orderRow.original.isSelected) {
            oldSeldctedIndex[orderRow.id.toString()] = true;
          }
          // checkSubRowisSelcted
          if (orderRow?.subRows?.length) {
            orderRow.subRows.forEach((subRow) => {
              if (subRow.isSelected) {
                oldSeldctedIndex[subRow.id.toString()] = true;
              }
            });
          }
        }
        return { ...order };
      });
      setSelectedIndexes(oldSeldctedIndex);
      setListData(updatedListData);
    } else {
      const updatedListData = rows.map((order, index) => {
        if (order.id === orderId) {
          order.subRows = [];
          order.expanded = false;
        } else {
          const orderRow = rest.rows.filter(
            (row) => row.original.id === order.id,
          )[0];
          order.isExpanded =
            orderRow.original.isExpanded || orderRow.isExpanded;
          if (orderRow.isSelected || orderRow.original.isSelected) {
            oldSeldctedIndex[orderRow.id.toString()] = true;
          }
          // checkSubRowisSelcted
          if (orderRow?.subRows?.length) {
            orderRow.subRows.forEach((subRow) => {
              if (subRow.isSelected) {
                oldSeldctedIndex[subRow.id.toString()] = true;
              }
            });
          }
        }

        return { ...order };
      });
      setSelectedIndexes(oldSeldctedIndex);
      setListData(updatedListData);
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Time Log',
        accessor: 'created_at',
        Cell: ({ row }) => {
          const key = row.depth === 0 ? 'createdAt' : 'created_at';
          const date = row.original[key]
            ? moment(row.original[key]).format('MMDDYYYY hh:mm A')
            : '';
          return date ? date.substring(0, date.length - 1) : '';
        },
        // className: "child-text-truncate",
        // isSortable: true,
      },
      {
        Header: 'Agent Id',
        accessor: 'agentLoginId',
        className: 'child-text-truncate',
        // isSortable: true,
      },
      rowExpanderColumn(
        'Order No.',
        {
          // isSortable: true,
          accessor: 'id',
          id: 'orderNo',
          subRowAccessor: 'order_id',
          className: 'text-highlighter child-text-truncate',
        },
        handleRowExpand,
      ),
      {
        Header: 'Ticket Id',
        accessor: 'id',
        Cell: ({ row }) => {
          const ticketNo = row.depth > 0 ? row.original['id'] : '';
          return ticketNo ? ticketNo.split('-')[0] : '';
        },
        className: 'child-text-truncate',
        // isSortable: true,
      },
      {
        Header: 'origin',
        accessor: 'origin_city_name',
        Cell: ({ row }) => {
          return row.original.origin_city_name
            ? `${row.original.origin_city_name}, ${row.original.origin_state}`
            : '';
        },
        // isSortable: true,
      },
      {
        Header: 'dstn',
        accessor: 'destination',
        Cell: ({ row }) => {
          return row.original.destination_city_name
            ? `${row.original.destination_city_name}, ${row.original.destination_state}`
            : '';
        },
        // isSortable: true,
      },
      {
        Header: 'carrier',
        accessor: 'issued_by',
        // isSortable: true,
      },
      {
        Header: 'psgr',
        accessor: 'passenger_type',
        // isSortable: true,
      },
      {
        Header: 'Trns Type',
        accessor: 'paymentType',
        // isSortable: true,
        Cell: ({ row }) => {
          if (row.original.orderStatus === 'Void') {
            return row.depth === 0 ? 'VOID' : null;
          }
          const paymentMethods = get(row, 'original.paymentDetailList', []);

          return (
            <span>
              {row.depth === 0
                ? paymentMethods
                    .map(
                      (method) =>
                        paymentTypeUpdater(method.paymentMethod) +
                        paymentModeUpdater(method),
                    )
                    .join('/')
                : null}
            </span>
          );
        },
      },
      {
        Header: 'Ticket Fee',
        accessor: 'ticket_service_fee_detail',
        Cell: ({ row }) => {
          const key = row.depth === 1 && 'ticket_fare';
          const amount = row.original[key];
          const serviceFee = !isEmpty(row.values.ticket_service_fee_detail)
            ? map(row.values.ticket_service_fee_detail, (fee) => {
                return fee;
              })
            : [];

          return (
            <div>
              <div style={{ textAlign: 'right' }}>
                {!isEmpty(amount) ? `$${parseFloat(amount).toFixed(2)}` : ''}
              </div>
              <table style={{ fontSize: '12px' }}>
                {!isEmpty(serviceFee) &&
                  map(serviceFee, (fee) => {
                    return (
                      <tr>
                        {' '}
                        <td>{fee.display_name}(s):</td>{' '}
                        <td>${parseFloat(fee.amount).toFixed(2)} </td>{' '}
                      </tr>
                    );
                  })}
              </table>
            </div>
          );
        },
        align: 'right',
        className: 'out-from-scrollbar admin-fee',
        headerClass: 'out-from-scrollbar admin-fee',
      },
      {
        Header: 'Total',
        accessor: 'ticket_fare',
        Cell: ({ row }) => {
          const key = row.depth === 0 ? 'price' : 'ticket_fare';
          const amount = row.original[key] ? parseFloat(row.original[key]) : 0;
          const serviceFee = !isEmpty(row.values.ticket_service_fee_detail)
            ? map(row.values.ticket_service_fee_detail, (fee) => {
                return fee;
              })
            : [];
          let serviceFeeTotal = 0;
          !isEmpty(serviceFee) &&
            map(
              serviceFee,
              (fee) => (serviceFeeTotal += parseFloat(fee.amount)),
            );

          return `$${parseFloat(amount + serviceFeeTotal).toFixed(2)}`;
        },
        align: 'right',
        className: 'out-from-scrollbar',
        headerClass: 'out-from-scrollbar',
        // isSortable: true,
      },
    ],
    [handleRowExpand],
  );

  /**
   * Call Api when page loads
   */
  useEffect(() => {
    setSearch('');
    setLoading(true);

    fetchOrderData()
      .then(({ data, total }) => {
        setListData(data);
        setListOrignalData(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  /**
   * Set data when search changes
   */
  useEffect(() => {
    setSelectedRows([]);
    setSelectedIndexes({});
    firstSelectedOrderType = null;
    setListData(
      search
        ? listOrignalData.filter((order) => {
            return matchInObject(search, { ...order });
          })
        : listOrignalData.slice(),
    );
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    return () => {
      firstSelectedOrderType = null;
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Theme contentClassName="d-flex" footer={2}>
      <Container fluid className="d-flex flex-column journal-page-container">
        <div className="journal-page-wrapper">
          <PageHeading />
          <div className="journal-content-box">
            <TotalRecordAndSearch
              search={search}
              setSearch={setSearch}
              total={total}
            />
            <AppTable
              data={listData}
              columns={columns}
              busy={loading}
              initialState={{ selectedRowIds: selectedIndexes }}
              onSelectedRowChange={(selected) => {
                setSelectedIndexes(selected);
                const selectedArr = Object.keys(selected);
                const totalSelectObject = [];
                const selctedParentIds = []; // parent Row Order Id
                selectedArr.forEach((selectedIndex) => {
                  const indexes = selectedIndex.toString().split('.');
                  // if ()
                  const parentObject = listData[parseInt(indexes[0])];

                  if (indexes.length === 1) {
                    totalSelectObject.push(parentObject);
                    selctedParentIds.push(parentObject.id);
                  }

                  if (
                    indexes[1] &&
                    parentObject.subRows[parseInt(indexes[1])] &&
                    !selctedParentIds.includes(
                      parentObject.subRows[parseInt(indexes[1])].order_id,
                    )
                  ) {
                    const subObject =
                      parentObject.subRows[parseInt(indexes[1])];
                    totalSelectObject.push(subObject);
                  }
                });
                setSelectedRows(totalSelectObject);
              }}
              shouldEnable={(row, rest) => {
                if (
                  row.original.orderStatus === 'Void' ||
                  row.original.ticket_status === 'Void'
                ) {
                  return false;
                }

                if (row.depth === 0) {
                  const paymentMethods = Object.keys(
                    groupBy(
                      row.original.paymentDetailList
                        ? row.original.paymentDetailList
                        : [],
                      'paymentMethod',
                    ),
                  );

                  return (
                    paymentMethods &&
                    paymentMethods.length === 1 &&
                    paymentMethods[0] === 'cash'
                  );
                } else {
                  const rowIndex = parseInt(row.id.split('.')[0]);
                  const parentRow = rest.data[rowIndex]
                    ? rest.data[rowIndex]
                    : {};
                  const parentPaymentMethods = Object.keys(
                    groupBy(
                      parentRow.paymentDetailList
                        ? parentRow.paymentDetailList
                        : [],
                      'paymentMethod',
                    ),
                  );

                  return (
                    parentPaymentMethods &&
                    parentPaymentMethods.length === 1 &&
                    parentPaymentMethods[0] === 'cash'
                  );
                }
              }}
              rawClassFnc={(row) => {
                if (
                  (row.depth === 0 && row.original.orderStatus === 'Void') ||
                  (row.depth > 0 && row.original.ticket_status === 'Void')
                ) {
                  return 'journal-row-highlighter';
                }
                return '';
              }}
              onSelect={(event, row, rest) => {
                const selectedFlatRows = rest.selectedFlatRows;
                const oldSelected = {};
                selectedFlatRows.forEach((s) => {
                  oldSelected[s.id] = true;
                });
                // Order is partially void
                if (
                  row.depth === 0 &&
                  !isEmpty(row.original.voidHistory) &&
                  row.original.orderStatus !== 'Void'
                ) {
                  setAlertMessage(
                    'This order contains voided ticket(s). Please select individual ticket(s).',
                  );
                  return;
                }
                // If ticket is fully redeem then show the error message while user selecting the checkbox
                if (row.depth === 1 && row.original.ticket_fully_redeemed) {
                  setAlertMessage('Ticket is already used.');
                  return;
                }
                if (!event.target.checked) {
                  if (
                    firstSelectedOrderType === null ||
                    (row.original.transactionName === 'Order' &&
                      firstSelectedOrderType === 'order') ||
                    (!row.original.transactionName &&
                      firstSelectedOrderType === 'ticket')
                  ) {
                    delete oldSelected[row.id.toString()];
                    // If a Order unchecked then uncheck all tickets for that order
                    if (row.depth === 0) {
                      selectedFlatRows.forEach((subRow) => {
                        const subRowIdPathArr = subRow.id.split('.');
                        if (
                          subRow.depth === 1 &&
                          subRowIdPathArr[0] === row.id.toString()
                        ) {
                          delete oldSelected[subRow.id.toString()];
                        }
                      });
                    } else {
                      ///
                      const parentRowId = row.id.split('.')[0];
                      delete oldSelected[parentRowId];
                      rest.toggleRowSelected(parentRowId, false);
                      //
                      Object.keys(oldSelected).forEach((key) => {
                        const idPathArr = key.split('.');
                        if (
                          idPathArr[0] === parentRowId &&
                          idPathArr.length > 1
                        ) {
                          setTimeout(() => {
                            rest.toggleRowSelected(key, true);
                          });
                        }
                      });
                    }
                    rest.toggleRowSelected(row.id, false);

                    if (!Object.keys(oldSelected).length) {
                      firstSelectedOrderType = null;
                    }
                  }
                } else if (firstSelectedOrderType === null) {
                  oldSelected[row.id.toString()] = true;
                  rest.toggleRowSelected(row.id, true);
                  firstSelectedOrderType =
                    row.original.transactionName === 'Order'
                      ? 'order'
                      : 'ticket';
                } else if (
                  (firstSelectedOrderType === 'order' &&
                    row.original.transactionName === 'Order') ||
                  (firstSelectedOrderType === 'ticket' &&
                    !row.original.transactionName)
                ) {
                  oldSelected[row.id.toString()] = true;
                  rest.toggleRowSelected(row.id, true);
                } else {
                  event.preventDefault();
                }
                setSelectedIndexes(oldSelected);
              }}
            />
          </div>
          <Row>
            <Col>
              <VoidSelected
                orders={selectedRows}
                onSuccess={() => {
                  setSelectedRows([]);
                  setSelectedIndexes({});
                  firstSelectedOrderType = null;
                  setLoading(true);
                  setSearch('');
                  fetchOrderData()
                    .then(({ data, total }) => {
                      setListData(data);
                      setListOrignalData(data);
                      setTotal(total);
                      setLoading(false);
                    })
                    .catch(() => {
                      setLoading(false);
                    });
                }}
              />
            </Col>
          </Row>
        </div>
        <Modal
          className="journal-modal hight-auto"
          show={!!alertMessage}
          onHide={() => setAlertMessage('')}
        >
          <ModalHeader closeButton />
          <ModalBody>
            <img src={CrossImg} alt="GroupImg" />
            <p>{alertMessage}</p>
            <div className="text-center" style={{ marginTop: '30px' }}>
              <button onClick={() => setAlertMessage('')}>Ok</button>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </Theme>
  );
};

const PageHeading = () => {
  return (
    <Row>
      <Col>
        <h2 className="heading">AGENT JOURNAL </h2>
      </Col>
    </Row>
  );
};

const TotalRecordAndSearch = ({ search, setSearch, total }) => {
  const inputRef = React.useRef();
  return (
    <div className="table-top-wrapper">
      <Row>
        <Col className="cell-border d-flex justify-content-center flex-column">
          <label>
            Records Found :{' '}
            <strong className="text-highlighter">{total}</strong>
          </label>
        </Col>
        <Col className="cell-border d-flex align-items-center justify-content-end">
          <label>Search</label>
          <div className="input-wrapper">
            <input
              type="text"
              ref={inputRef}
              name="search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              className="form-control table-search-input"
            />
            {!search ? (
              <FaSearch
                onClick={() => {
                  if (inputRef) {
                    inputRef.current.focus();
                  }
                }}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};
