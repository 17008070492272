import { Auth } from 'aws-amplify';
import _ from 'lodash';
import React from 'react';
import { Container } from 'react-bootstrap';
import FooterLogo from '../../../../assets/images/Artboard.svg';
import Logo from '../../../../assets/images/COACHUSA_logo.svg';
import Loader from '../Loader/Loader';
import LoginView from '../Login/LoginView';
// import jwt_decode from "jwt-decode";

class Login extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			email: '',
			password: '',
			error: '',
			hidden: true,
			loggedInUser: {},
			isLoading: false,
			authState: null,
			emailError: '',
			passError: ''
		};
	}

	handleChange = (e) => {
		const value = e.target.value;
		const name = e.target.name;

		switch (name) {
			case 'email':
				const emailValue = value.toLowerCase();
				const emailpattern = new RegExp(/^([\w.%+-]+)$/i);
				if (!emailpattern.test(emailValue) && !_.isEmpty(value)) {
					this.setState({
						//emailError : 'Please enter valid Employee ID'
						emailError: _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'Please enter valid Employee ID' : 'Please enter a valid Agent ID',
						email: emailValue
					});
				} else {
					this.setState({
						emailError: '',
						email: emailValue
					});
				}
				break;

			case 'password':
				if (_.isEmpty(value)) {
					this.setState({
						passError: 'Please enter a valid Password',
						password: value
					});
				} else {
					this.setState({
						passError: '',
						password: value
					});
				}
				break;

			default:
				break;
		}
	};
	componentDidMount = async () => {
		let cognitoUser = localStorage.getItem('cognitoUser');
		let agentLocation = localStorage.getItem('agent_Location');
		if (_.isEmpty(agentLocation)) {
			this.props.history.push('./');
			return;
		}
		else{
			if(cognitoUser){
				this.props.history.push('./home');
				return;
			}
		}
		window.scrollTo(0, 0);
	};

	// validate email address
	 
	validateEmail (email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	handleSubmit = (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });
		const { email, password } = this.state;
		Auth.signIn(email.toLowerCase(), password)
			// If we are successful, navigate to Home screen
			.then((user) => {
				
				Auth.currentAuthenticatedUser();
			    const userEmail=	this.validateEmail(user.username)
				? user.username
				: `agent${user.username}@coachusa.com`
				user['userEmail'] = email;
				user['emailID'] = userEmail;
				localStorage.setItem('cognitoUser', JSON.stringify(user));
				
				this.setState({ isLoading: false });
				this.props.history.push('./home');
				window.location.reload();
				// const token = JSON.stringify(user);
				// const decodeToken = token.signInUserSession.accessToken.jwtToken;
				// console.log('dsjdshjdhsdjTokenToken==>',jwt_decode(decodeToken))
				// localStorage.setItem('cognitoUserToken', JSON.stringify(user));
				//document.location = document.location; // Reload page to refresh values (e.g. credit card)
			})
			.catch((err) => {
				this.setState({ isLoading: false });
				let errorKey = 'Please confirm your login credentials';
				if (err !== null) {
					if (err === 'UserNotConfirmedException') {
						errorKey = 'Account verification required: please check your email to confirm account.';
					}
					if (err === 'NotAuthorizedException') {
						errorKey = 'The email is not authenticated.';
					}
				}
				this.setState({
					error: errorKey
				});
			});
		// }
	};

	toggleShow = () => {
		this.setState({ hidden: !this.state.hidden });
	};

	validate = () => {
		const { email, password, emailError, passError } = this.state;
		return email !== '' && password !== '' && emailError === '' && passError === '';
	};

	render () {
		const { error } = this.props;

		return (
			<div>
				{this.state.isLoading && <Loader />}
				<Container>
					<div className="login-logo-wrapper">
						<img src={Logo} className="login-logo" alt="Logo" />
					</div>
					<div className={_.isEmpty(error) ? 'no-error' : 'error-msg'}>{error}</div>
					<LoginView
						email={this.state.email}
						password={this.state.password}
						handleChange={this.handleChange}
						handleSubmit={this.handleSubmit}
						passError={this.state.passError}
						emailError={this.state.emailError}
						hidden={this.state.hidden}
						error={this.state.error}
						validation={!this.validate()}
						toggleShow={this.toggleShow}
					/>
					<div className="login-footer-logo-wrapper">
						<img src={FooterLogo} className="login-logo" alt="Footer Logo" />
					</div>
				</Container>
			</div>
		);
	}
}
export default Login;
