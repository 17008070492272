import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import printJS from "print-js";
import Loader from "../../Loader/Loader";

const PDFPrint = ({ file, orderId }) => {
  const [isLoading, setIsLoading] = useState(true);

  const printPDF = () => {
    setTimeout(function () {
      printJS({
        printable: file,
      });
      setTimeout(function () {
        setIsLoading(false);
      }, 1000);
    }, 500);
  };

  useEffect(() => {
    setTimeout(function () {
      const order_id_printed = orderId + "_printed";
      localStorage.setItem(order_id_printed, "true");
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
          <div style={{ display: "none" }}>
            <iframe
              src={file}
              name="print_frame"
              type="application/pdf"
              width="100%"
              style={{ height: "80vh", width: "100%" }}
              onLoad={printPDF}
            ></iframe>
          </div>
        </>
      ) : null}
    </>
  );
};

PDFPrint.propTypes = {
  file: PropTypes.string.isRequired,
};

export default PDFPrint;
