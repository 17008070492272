export function uniqueArrayCollection(items, key) {
  const result = [];
  const map = new Map();
  for (const item of items) {
    if (!map.has(item[key])) {
      map.set(item[key], true); // set any value to Map
      result.push(item);
    }
  }
  return result;
}

export function uniqueNo() {
  return '_' + Math.random().toString(36).substr(2, 9);
}

export function  matchInObject(keyword, obj) {
  const serachforKey = keyword.toLowerCase()
  const keyArr = Object.keys(obj)
  return keyArr.some((key) => {
    let value =  obj[key]
    value = value ? value.toString().toLowerCase() : ''
    return value.indexOf(serachforKey) !== -1
  })
}