import _ from "lodash";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Container, Modal, ModalBody, Table } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import CongnitoTokenContext from "../../../../CognitoTokenContext";
import GroupImg from "../../../../assets/images/Group.svg";
import CrossImg from "../../../../assets/images/cross.svg";
import {
  commitCashoutReport,
  previewCashoutReport,
} from "../../utils/api/cashout";
import { signOut } from "../../utils/auth";
import { formatTimestamp } from "../../utils/datetime";
import Loader from "../Loader/Loader";
import Theme from "../Theme/index";

const CashOut = ({ history }) => {
  const [modal, setModal] = useState(false);
  const [cashOut, setCashOut] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errorFind, setErrorFind] = useState(false);

  const context = useContext(CongnitoTokenContext);

  const userDetails = _.isEmpty(JSON.parse(localStorage.getItem("cognitoUser")))
    ? { userEmail: "" }
    : JSON.parse(localStorage.getItem("cognitoUser"));

  useEffect(() => {
    if (userDetails.userEmail) {
      try {
        setLoading(true);
        previewCashoutReport(userDetails.emailID)
          .then((res) => {
            if (res.data.orbitResponse.statusCode !== 500) {
              setCashOut(res.data.orbitResponse.body);
              setLoading(false);
            } else {
              setLoading(false);
              setErrorFind(true);
            }
          })
          .catch((err) => {
            setLoading(false);
            setErrorFind(true);
          });
      } catch (err) {
        setLoading(false);
        setErrorFind(true);
      }
    }
  }, [userDetails.emailID, userDetails.userEmail]);

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    body[0].classList.toggle("bgWhite");
    return () => body[0].classList.toggle("bgWhite");
  }, []);

  const handleCashout = () => {
    setModal(false);

    window.addEventListener("afterprint", async () => {
      const { statusCode } = await commitCashoutReport(userDetails.emailID);
      if (statusCode === 200) {
        signOut();
      } else {
        setErrorFind(true);
      }
    });

    setTimeout(() => window.print());
  };

  const voucherNumberUpdater = (method) => {
    return `${
      method.length >= 4 ? ` x${method.slice(method.length - 4)}` : ` ${method}`
    }`;
  };

  useMemo(
    () =>
      setTotal(
        _.isNaN(
          parseFloat(cashOut.cash) +
            parseFloat(cashOut.check) +
            parseFloat(cashOut.creditCard) +
            parseFloat(cashOut.voucher)
        )
          ? 0
          : parseFloat(cashOut.cash) +
              parseFloat(cashOut.check) +
              parseFloat(cashOut.creditCard) +
              parseFloat(cashOut.voucher)
      ),
    [cashOut]
  );

  return (
    <Fragment>
      {/* Trick to load image  with dom element  */}
      <img src={GroupImg} alt="GroupImg" className="d-none" />
      {/* <CashOutReceipt /> */}
      <Theme isPrintable={true}>
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <h2>Shift Report</h2>
            <div className="cash-divider" />
            <div className="main-area">
              <div className="summary">Summary</div>
              <div className="agent-id-cashout">
                {userDetails.attributes.given_name}{" "}
                {userDetails.attributes.family_name}
              </div>
              <div style={{ marginTop: "20px" }}>
                <span className="summary-agentdetail">
                  Agent ID: {userDetails.userEmail}
                </span>
                <span className="summary-agentdetail">
                  Last shift ended:{" "}
                  {cashOut.from ? formatTimestamp(cashOut.from) : "-"}
                </span>
                <span className="summary-agentdetail">
                  Current date & time:{" "}
                  {formatTimestamp(cashOut.to ?? new Date())}
                </span>
              </div>
              <div className="table-responsive cashout_tb">
                <Table borderless size="sm">
                  <tbody>
                    <tr className="refund">
                      <td>Total Transactions</td>
                      <td></td>
                      <td>
                        ${parseFloat(_.isNaN(total) ? 0 : total).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Cash</td>
                      <td>{_.get(cashOut, "cashTransactionCount", 0)}</td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "cash", 0))
                            ? 0
                            : _.get(cashOut, "cash", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Card</td>
                      <td>{_.get(cashOut, "creditCardTransactionCount", 0)}</td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "creditCard", 0))
                            ? 0
                            : _.get(cashOut, "creditCard", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Check</td>
                      <td>{_.get(cashOut, "checkTransactionCount", 0)}</td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "check", 0))
                            ? 0
                            : _.get(cashOut, "check", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {!_.isEmpty(context.cognitoTokenValue) &&
                        _.isEqual(
                          localStorage.getItem("agent_Location"),
                          "CUST_AP"
                        )
                          ? "Voucher/Comp"
                          : "Voucher"}
                      </td>
                      <td>{_.get(cashOut, "voucherTransactionCount", 0)}</td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "voucher", 0))
                            ? 0
                            : _.get(cashOut, "voucher", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    {!_.isEmpty(context.cognitoTokenValue) &&
                      _.isEqual(
                        localStorage.getItem("agent_Location"),
                        "CUST_AP"
                      ) &&
                      _.get(cashOut, "voucherCompDetail", []).map(
                        (value, index) => (
                          <tr key={index} className="bg-light">
                            <td>{voucherNumberUpdater(value.voucherNumber)}</td>
                            <td>{value.voucherCount}</td>
                            <td>${value.voucherTotalAmount.toFixed(2)}</td>
                          </tr>
                        )
                      )}

                    <tr className="refund">
                      <td>Void / Cash Refund</td>
                      <td>
                        {_.get(cashOut, "refundAmountTransactionCount", 0)}
                      </td>
                      <td>
                        ($
                        {parseFloat(
                          _.isNull(_.get(cashOut, "refundAmount", 0))
                            ? 0
                            : _.get(cashOut, "refundAmount", 0)
                        ).toFixed(2)}
                        )
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {_.size(_.get(cashOut, "voidTicketList", [])) ? (
                          <Table borderless size="sm">
                            <tr className="tickets-list-header">
                              <td>Ticket Reference</td>
                              <td>Origin</td>
                              <td>Destination</td>
                              <td>Pay Type</td>
                              <td>Amount</td>
                            </tr>
                            {_.map(
                              _.get(cashOut, "voidTicketList", []),
                              (voidTicket, index) => (
                                <tr
                                  className="tickets-list"
                                  key={`tickets-list-${index}`}
                                >
                                  <td title={voidTicket.id}>
                                    {_.split(voidTicket.id, "-")[0]}
                                  </td>
                                  <td>
                                    {voidTicket.originCityName},{" "}
                                    {voidTicket.originState}
                                  </td>
                                  <td>
                                    {voidTicket.destinationCityName},{" "}
                                    {voidTicket.destinationState}
                                  </td>
                                  <td>CASH</td>
                                  <td>
                                    ($
                                    {parseFloat(
                                      voidTicket.fare ? voidTicket.fare : 0
                                    ).toFixed(2)}
                                    )
                                  </td>
                                </tr>
                              )
                            )}
                          </Table>
                        ) : null}
                      </td>
                    </tr>
                    <tr className="refund">
                      <td>Paid In</td>
                      <td />
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "paidIn", 0))
                            ? 0
                            : _.get(cashOut, "paidIn", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Driver</td>
                      <td></td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "driverPaidIn", 0))
                            ? 0
                            : _.get(cashOut, "driverPaidIn", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Agent</td>
                      <td></td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "agentPaidIn", 0))
                            ? 0
                            : _.get(cashOut, "agentPaidIn", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td></td>
                      <td>
                        $
                        {parseFloat(
                          _.isNull(_.get(cashOut, "otherPaidIn", 0))
                            ? 0
                            : _.get(cashOut, "otherPaidIn", 0)
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr className="table-divider">
                      <td
                        colSpan={4}
                        style={{
                          paddingTop: _.size(
                            _.get(cashOut, "voidTicketList", [])
                          )
                            ? 14
                            : 14,
                        }}
                      />
                    </tr>
                    <tr className="total">
                      <td colSpan={4} className="bold">
                        Cash for Deposit
                      </td>
                      <td></td>
                    </tr>
                    <tr className="total table-divider">
                      <td colSpan={2} className="pt-0 bold">
                        Total Amount
                      </td>
                      <td className="pt-0">
                        $
                        {parseFloat(
                          _.isNaN(
                            parseFloat(
                              _.isNull(_.get(cashOut, "cash", 0))
                                ? 0
                                : _.get(cashOut, "cash", 0) +
                                    parseFloat(cashOut.paidIn)
                            ).toFixed(2) -
                              parseFloat(_.get(cashOut, "refundAmount", 0))
                          )
                            ? 0
                            : parseFloat(
                                _.isNull(_.get(cashOut, "cash", 0))
                                  ? 0
                                  : _.get(cashOut, "cash", 0) +
                                      parseFloat(cashOut.paidIn)
                              ).toFixed(2) -
                                parseFloat(_.get(cashOut, "refundAmount", 0))
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="btn-area d-print-none">
                <button className="go-back" onClick={() => history.goBack()}>
                  Go back
                </button>
                <button onClick={() => setModal(!modal)}>End Shift</button>
                {/* <button onClick={window.print}>Print</button> */}
              </div>
              <Modal
                className="logout-modal"
                show={modal}
                onHide={() => setModal(!modal)}
              >
                <ModalHeader closeButton />
                <ModalBody>
                  <img src={GroupImg} alt="GroupImg" />
                  <p>
                    Are you sure you want to
                    <br />
                    end your shift?
                  </p>
                  <div className="text-center mt-3">
                    <button onClick={() => handleCashout()}>End Shift</button>
                  </div>
                  <div className="text-center" onClick={() => setModal(!modal)}>
                    <span className="logout-cancel">Cancel</span>
                  </div>
                </ModalBody>
              </Modal>

              <Modal
                className="journal-modal"
                show={errorFind}
                onHide={() => setErrorFind(!errorFind)}
              >
                <ModalHeader closeButton />
                <ModalBody>
                  <img
                    src={CrossImg}
                    alt="GroupImg"
                    style={{ marginBottom: "40px" }}
                  />
                  <p>Something went wrong</p>
                  <p>contact customer support</p>
                  <div className="text-center" style={{ marginTop: "120px" }}>
                    <button onClick={() => setErrorFind(!errorFind)}>Ok</button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
          </Container>
        )}
      </Theme>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleEmptyCart: () => dispatch({ type: "EMPTY_CART" }),
});
export default compose(withRouter, connect(null, mapDispatchToProps))(CashOut);
