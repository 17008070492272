import {
  defaultTo,
  isDate,
  isEmpty,
  isEqual,
  map,
  range,
  reduce,
  replace,
  some,
  get,
  filter,
} from "lodash";
import React, { useState, useMemo } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormLabel,
  Modal,
  ModalBody,
  Nav,
  Row,
} from "react-bootstrap";
import Theme from "../Theme/index";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CalenderIcon from "../../../../assets/images/calendar-icon.svg";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import GroupImg from "../../../../assets/images/Group.svg";
import Api from "../../utils/api/api";
import ApiNew from "../../utils/api/apiNew";
import moment from "moment";
import Loader from "../Loader/Loader";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import { Fragment } from "react";
import { withRouter } from "react-router-dom";
import AgentPrintReceipt from "./AgentPrintReceipt";
import Select from "react-select";
import OtherPrintReceipt from "./OtherPrintReceipt";
import DriverPrintReceipt from "./DriverPrintReceipt";
import CrossImg from '../../../../assets/images/cross.svg';
import * as FullStory from '@fullstory/browser';
import { useEffect } from "react";
import { format } from 'date-fns';

const PaidIn = () => {
  const { goBack } = useHistory();
  const userDetails = isEmpty(JSON.parse(localStorage.getItem("cognitoUser")))
    ? { userEmail: "" }
    : JSON.parse(localStorage.getItem("cognitoUser"));
  const [loader, setLoader] = useState(false);
  const [errorFind, setErrorFind] = useState(false);
  const [modal, setModal] = useState(false);
  const [tab, setTab] = useState("1");
  const [totalAmounts, setTotalAmounts] = useState([]);
  const [runTripNumber, setRunTripNumber] = useState([]);
  const [issuingOPR, setIssuingOPR] = useState([]);
  const [issuingOPRs, setissuingOPRs] = useState([]);

  const [state, setState] = useState({
    driverId: "",
    amount: "",
    envelopesNo: null,
    date: new Date(),
    agentId: "",
    reason: "",
    source: "",
    transactionID: "",
  });

  const currentDate = new Date();
  // const operatorData = [
  //   {
  //     id: 1,
  //     code: "COM",
  //     partnerCode: "CUSA",
  //     name: "Community",
  //     description: "Community service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: false,
  //       nonOrbitTicket: true,
  //       transfer: false,
  //       transferMinFare: 0,
  //       transferMaxFare: 0,
  //       minFare: 1,
  //       maxFare: 10.25,
  //       adminFee: 0,
  //     },
  //   },
  //   {
  //     id: 2,
  //     code: "NAE",
  //     partnerCode: "CUSA",
  //     name: "Newark Airport Express",
  //     description: "Newark Airport Express service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: true,
  //       nonOrbitTicket: true,
  //       transfer: false,
  //       transferMinFare: 0,
  //       transferMaxFare: 0,
  //       minFare: 1,
  //       maxFare: 18.5,
  //       adminFee: 1.5,
  //     },
  //   },
  //   {
  //     id: 3,
  //     code: "RCK",
  //     partnerCode: "CUSA",
  //     name: "Rockland",
  //     description: "Rockland service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: false,
  //       nonOrbitTicket: true,
  //       transfer: false,
  //       transferMinFare: 0,
  //       transferMaxFare: 0,
  //       minFare: 1,
  //       maxFare: 12.05,
  //       adminFee: 0,
  //     },
  //   },
  //   {
  //     id: 4,
  //     code: "HTL",
  //     partnerCode: "CUSA",
  //     name: "ShortLine",
  //     description: "ShortLine service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: false,
  //       nonOrbitTicket: true,
  //       transfer: false,
  //       transferMinFare: 0,
  //       transferMaxFare: 0,
  //       minFare: 1,
  //       maxFare: 31.95,
  //       adminFee: 0,
  //     },
  //   },
  //   {
  //     id: 5,
  //     code: "SUB",
  //     partnerCode: "CUSA",
  //     name: "Suburban",
  //     description: "Suburban service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: false,
  //       nonOrbitTicket: true,
  //       transfer: false,
  //       transferMinFare: 0,
  //       transferMaxFare: 0,
  //       minFare: 1,
  //       maxFare: 25,
  //       adminFee: 0,
  //     },
  //   },
  //   {
  //     id: 6,
  //     code: "VGB",
  //     partnerCode: "CUSA",
  //     name: "Van Galder Bus Company",
  //     description: "Van Galder Bus Company service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: true,
  //       nonOrbitTicket: true,
  //       transfer: false,
  //       minFare: 1,
  //       maxFare: 31,
  //       transferMinFare: 0,
  //       transferMaxFare: 0,
  //       adminFee: 1,
  //     },
  //   },
  //   {
  //     id: 7,
  //     code: "WCC",
  //     partnerCode: "WCC",
  //     name: "Washington County Commuter",
  //     description: "Washinton county service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: false,
  //       transfer: true,
  //       transferMinFare: 1,
  //       transferMaxFare: 50,
  //       nonOrbitTicket: true,
  //       minFare: 1,
  //       maxFare: 50,
  //       adminFee: 0,
  //     },
  //   },
  //   {
  //     id: 8,
  //     code: "WCL",
  //     partnerCode: "CUSA",
  //     name: "Wisconsin Coach Lines",
  //     description: "Wisconsin Coach Lines service provider",
  //     configuration: {
  //       cashOnBus: true,
  //       airportTransport: true,
  //       transfer: false,
  //       transferMinFare: 1,
  //       transferMaxFare: 50,
  //       nonOrbitTicket: true,
  //       minFare: 1,
  //       maxFare: 50,
  //       adminFee: 1,
  //     },
  //   },
  // ];

  const reactSelectStyleEnvelop = {
    control: (styles) => ({ ...styles, minHeight: "50px" }),
    indicatorSeparator: () => ({
      display: "none",
      border: "1px solid #A8A8A8",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#495057",
      marginTop: "5px",
    }),
    //menu: (styles) => ({ ...styles, width:'100px', height:'180px', left:'auto', right: 0}),
    //menuList: (styles) => ({ ...styles, width:'100px', height:'180px', left:'auto', right: 0}),
  };

  const reactSelectStyle = {
    control: (styles) => ({ ...styles, minHeight: "50px" }),
    indicatorSeparator: () => ({
      display: "none",
      border: "1px solid #A8A8A8",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#495057",
      marginTop: "5px",
    }),
  };

  async function fetchIssuingOpertaor() {
    try {
      const response = await Api.fetch("driver/serviceproviderlist");
      const operatorData = get(response, "data");
      return operatorData.map((operator) => {
        return {
          label: operator.name,
          value: operator.name,
          code: operator.code,
        };
      });
    } catch (e) {
      return [];
    }
  }

  const _getOperatorData = async () => {
    const operatorDetail = await fetchIssuingOpertaor();
    setissuingOPRs(operatorDetail);
  };

  useEffect(() => {
    _getOperatorData();
  }, []);

  const handleChange = ({ target: { id, value } }) => {
    setState({
      ...state,
      [id]: value,
    });
  };
  const handleDateChange = (dt) => {
    const date = new Date(dt);
    setState({
      ...state,
      date,
    });
  };
  const formattedAmount = (number) => {
    const formattedValue = parseFloat(number).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
    return !isNaN(parseFloat(number)) && formattedValue ? formattedValue : "";
  };
  const validateDriver = () => {
    let isDisabled = [];
    map(range(1, parseInt(state.envelopesNo?.value) + 1), (i) => {
      isDisabled = [
        ...isDisabled,
        isEmpty(runTripNumber[i - 1]) ||
          isEmpty(issuingOPR[i - 1]) ||
          isEmpty(totalAmounts[i - 1]),
      ];
    });
    return (
      isEmpty(state.driverId) ||
      isEmpty(state.amount) ||
      isEmpty(state.envelopesNo) ||
      !isDate(state.date) ||
      some(isDisabled, Boolean)
    );
  };
  const validateAgent = () => {
    return (
      isEmpty(state.agentId) ||
      isEmpty(state.reason) ||
      isEmpty(state.amount) ||
      !isDate(state.date)
    );
  };
  const validateOther = () => {
    return (
      isEmpty(state.source) ||
      isEmpty(state.reason) ||
      isEmpty(state.amount) ||
      !isDate(state.date)
    );
  };
  const handleFocus = () => {
    setState({
      ...state,
      amount: replace(replace(state.amount, "$", ""), /,/g, ""),
    });
  };
  const handleBlur = () => {
    setState({
      ...state,
      amount: formattedAmount(state.amount),
    });
  };
  const handleSubmit = () => {
    try {
      const data = { requestSource: "agent_portal" };
      if (isEqual(tab, "1")) {
        data["itemTransactionType"] = "driver_cash_deposit";
        data["driverId"] = state.driverId;
        data["driverEmail"] = `driver_${state.driverId}@coachusa.com`;
        data["runTripNumber"] = state.runTripNumber;
        data["amountCollected"] = parseFloat(
          replace(replace(state.amount, "$", ""), /,/g, "")
        );
        data["paymentAt"] = moment(state.date).format("YYYY-MM-DD");
        data["loggedAgentId"] = userDetails.userEmail;
        data["loggedAgentEmail"] = userDetails.emailID;
        data["numberOfEnvelopes"] = parseInt(state.envelopesNo?.value);
        data["agentLocationId"] = localStorage.getItem("agent_Location");
        data["envelopes"] = map(
          range(1, parseInt(state.envelopesNo?.value) + 1),
          (i) => {
            return {
              envelopeNumber: i,
              runTripNumber: runTripNumber[i - 1],
              operatorName: issuingOPR[i - 1]?.value,
              amount: parseFloat(
                replace(replace(totalAmounts[i - 1], "$", ""), /,/g, "")
              ),
            };
          }
        );
      } else if (isEqual(tab, "2")) {
        data["itemTransactionType"] = "agent_cash_deposit";
        data["agentId"] = state.agentId;
        data["agentEmail"] = `agent${state.agentId}@coachusa.com`;
        data["reasonText"] = state.reason;
        data["amountCollected"] = parseFloat(
          replace(replace(state.amount, "$", ""), /,/g, "")
        );
        data["paymentAt"] = moment(state.date).format("YYYY-MM-DD");
        data["loggedAgentId"] = userDetails.userEmail;
        data["loggedAgentEmail"] = userDetails.emailID;
        data["agentLocationId"] = localStorage.getItem("agent_Location");
      } else {
        data["itemTransactionType"] = "other_cash_deposit";
        data["sourcePaidIn"] = state.source;
        data["reasonText"] = state.reason;
        data["amountCollected"] = parseFloat(
          replace(replace(state.amount, "$", ""), /,/g, "")
        );
        data["paymentAt"] = moment(state.date).format("YYYY-MM-DD");
        data["loggedAgentId"] = userDetails.userEmail;
        data["loggedAgentEmail"] = userDetails.emailID;
        data["agentLocationId"] = localStorage.getItem("agent_Location");
      }
      setLoader(true);
     ApiNew.post(
        `${process.env.REACT_APP_CASHIN_REQUEST_ENDPOINT}`,
        data
     ).then((res) => {
       if (res.data.orbitResponse.statusCode === 200 && !isEmpty(res.data.orbitResponse.body) ) {
         FullStory.event('Paid In Success', {
           statusCode: res.data.orbitResponse.statusCode,
           padIn_response: res.data.orbitResponse,
           paidIn_date: format(new Date(), 'MM-dd-yyyy h:mm a')
         });
         setLoader(false);
         setModal(res.data.orbitResponse.body.saved);
         setState({
           ...state,
           transactionID: res.data.orbitResponse.body.id,
         });
       } else {
         FullStory.event('Paid In Failed', {
           paidIn_input: data,
           statusCode: !isEmpty(res.data.orbitResponse) ? get(res.data.orbitResponse,"statusCode",""):null,
           padIn_response: !isEmpty(res.data.orbitResponse)? get(res.data.orbitResponse,"body",{}):null,
           paidIn_date: format(new Date(), 'MM-dd-yyyy h:mm a')
         });
         setLoader(false);
         setErrorFind(true);
       }
     })
       .catch((err) => {
         FullStory.event('Paid In Failed', {
           paidIn_input: data,
           padIn_error: err,
           paidIn_date: format(new Date(), 'MM-dd-yyyy h:mm a')
         });
         setLoader(false);
         setErrorFind(true);
       });
    } catch (e) {
      FullStory.event('Paid In Failed', {
        paidIn_input: null,
        padIn_error: e,
        paidIn_date: format(new Date(), 'MM-dd-yyyy h:mm a')
      });
      setLoader(false);
      setErrorFind(true);
      console.log(e);
    }
  };
  const handleModel = () => {
    if (isEqual(tab, "1")) {
      setModal(false);
      window.setTimeout(() => {
        window.print();
      }, 500);
      window.onafterprint = function () {
        window.location.reload();
      };
    } else if (isEqual(tab, "2")) {
      setModal(false);
      window.setTimeout(() => {
        window.print();
      }, 500);
      window.onafterprint = function () {
        window.location.reload();
      };
    } else {
      setModal(false);
      window.setTimeout(() => {
        window.print();
      }, 500);
      window.onafterprint = function () {
        window.location.reload();
      };
    }
    setModal(false);
  };
  const handleAmountChange = ({ target: { value } }) => {
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (
      (floatRegex.test(value) &&
        !isEqual(parseFloat(value), 0) &&
        parseFloat(value) <= 9999.99) ||
      isEmpty(value)
    ) {
      setState({
        ...state,
        amount: value,
      });
    }
  };
  const handleTabChange = (key) => {
    setTab(key);
    setState({
      driverId: "",
      amount: "",
      date: new Date(),
      agentId: "",
      reason: "",
      source: "",
      envelopesNo: "",
    });
  };
  const handleIssuingChange = (value, key) => {
    const type = issuingOPR;
    type[key] = value;
    setIssuingOPR([...type]);
  };
  const handleRunTripChange = ({ target: { value } }, key) => {
    const type = runTripNumber;
    type[key] = value;
    setRunTripNumber([...type]);
  };
  const handleAmountsChange = ({ target: { value } }, key) => {
    const type = totalAmounts;
    type[key] = value;
    const floatRegex = /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    if (
      (floatRegex.test(value) &&
        // !isEqual(parseFloat(value), 0) &&
        parseFloat(value) <= 9999.99) ||
      isEmpty(value)
    ) {
      setTotalAmounts([...type]);
    }
  };

  const handleTotalAmountFocus = (e, key) => {
    const type = totalAmounts;
    type[key] = parseFloat(replace(replace(type[key], "$", ""), /,/g, ""));
    setTotalAmounts([...type]);
  };

  const handleTotalAmountBlur = (e, key) => {
    const type = totalAmounts;
    type[key] = formattedAmount(type[key]);
    setTotalAmounts([...type]);
  };
  const getEnvelopesFields = useCallback(() => {
    if (state.envelopesNo) {
      let rows = [];
      for (let i = 0; i < parseInt(state.envelopesNo.value); i++) {
        rows.push(
          <Fragment key={`envelop-${i}`}>
            <Col sm="12" className="mt-3">
              <label className="text-uppercase bold">
                Envelope {i + 1 < 10 ? "0" : ""}
                {i + 1}
              </label>
            </Col>
            <Col sm="12" md="4">
              <Form.Group controlId={`runTripNumber-${i}`}>
                <div className="float-label-control">
                  <Form.Control
                    placeholder="Run/Trip Number*"
                    value={defaultTo(runTripNumber[i], "")}
                    onChange={(e) => handleRunTripChange(e, i)}
                    autoComplete="off"
                  />
                  <FormLabel className="form-control-placeholder">
                    Run/Trip Number*
                  </FormLabel>
                </div>
              </Form.Group>
            </Col>
            <Col sm="12" md="4">
              <Form.Group controlId={`issuingOPR-${i}`}>
                <div className="float-label-control">
                  <Select
                    placeholder="Issuing OPR*"
                    styles={reactSelectStyle}
                    value={defaultTo(issuingOPR[i], null)}
                    onChange={(value) => handleIssuingChange(value, i)}
                    options={issuingOPRs}
                  />
                  {issuingOPR[i] && (
                    <FormLabel className="form-control-placeholder">
                      Issuing OPR*
                    </FormLabel>
                  )}
                </div>
              </Form.Group>
            </Col>
            <Col sm="12" md="4">
              <Form.Group controlId={`totalAmounts-${i}`}>
                <div className="float-label-control">
                  <Form.Control
                    placeholder="Amount*"
                    onFocus={(e) => handleTotalAmountFocus(e, i)}
                    onBlur={(e) => handleTotalAmountBlur(e, i)}
                    value={defaultTo(totalAmounts[i], "")}
                    onChange={(e) => handleAmountsChange(e, i)}
                    autoComplete="off"
                  />
                  <FormLabel className="form-control-placeholder">
                    Amount*
                  </FormLabel>
                </div>
              </Form.Group>
            </Col>
          </Fragment>
        );
      }
      return rows;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.envelopesNo]);

  useMemo(() => {
    setState({
      ...state,
      amount: formattedAmount(
        isEmpty(filter(totalAmounts, (t) => t))
          ? ""
          : reduce(
              totalAmounts,
              (prev, curr) => {
                return (
                  prev +
                  defaultTo(
                    parseFloat(replace(replace(curr, "$", ""), /,/g, "")),
                    0
                  )
                );
              },
              0
            )
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalAmounts]);
  const handleEnvelopNoChange = (value) => {
    setState({
      ...state,
      envelopesNo: value,
      amount: "",
    });
    setTotalAmounts([]);
    setIssuingOPR([]);
    setRunTripNumber([]);
  };
  return (
    <Theme contentClassName="bg-white">
      <img src={GroupImg} alt="GroupImg" className="d-none" />
      {loader ? (
        <Loader />
      ) : (
        <Container className="d-print-none">
          <div className="paidin-wrapper">
            <h3 className="heading">Paid In</h3>
            <Row>
              <Col sm="12">
                <Nav
                  variant="pills"
                  fill
                  activeKey={tab}
                  onSelect={handleTabChange}
                >
                  <Nav.Item className="first-item">
                    <Nav.Link eventKey="1" active={isEqual(tab, "1")}>
                      DRIVER
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2" active={isEqual(tab, "2")}>
                      AGENT
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="last-item">
                    <Nav.Link eventKey="3" active={isEqual(tab, "3")}>
                      OTHER
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row hidden={!isEqual(tab, "1")}>
              <Col sm="12">
                <h1 className="tab-heading">DRIVER</h1>
              </Col>
              <Col sm="12" md="4">
                <Form.Group controlId="date">
                  <div className="float-label-control">
                    <DatePicker
                      dateFormat="MM/dd/yyyy"
                      isClearable={true}
                      minDate={new Date().setDate(currentDate.getDate() - 30)}
                      customInput={<DepartureDatePicker />}
                      selected={state.date}
                      onChange={handleDateChange}
                      maxDate={new Date().setDate(currentDate.getDate() + 30)}
                      
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" md="4">
                <Form.Group controlId="driverId">
                  <div className="float-label-control">
                    <Form.Control
                      placeholder="Driver ID*"
                      value={state.driverId}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                    <FormLabel className="form-control-placeholder">
                      Driver ID*
                    </FormLabel>
                  </div>
                </Form.Group>
              </Col>
              <Col sm="12" md="4">
                <Form.Group controlId="envelopesNo" className="envelopesNo">
                  <div className="float-label-control">
                    <Select
                      as="select"
                      placeholder="Envelope No*"
                      styles={reactSelectStyleEnvelop}
                      value={state.envelopesNo}
                      onChange={handleEnvelopNoChange}
                      options={map(range(1, 11), (op) => {
                        return { label: op, value: op };
                      })}
                    />
                    {state.envelopesNo && (
                      <FormLabel className="form-control-placeholder">
                        Envelope No*
                      </FormLabel>
                    )}
                  </div>
                </Form.Group>
              </Col>
              {getEnvelopesFields()}
              {!isEmpty(state.envelopesNo) && (
                <Col sm="12" md="4" className="mt-2">
                  <Form.Group controlId="amount">
                    <label className="text-uppercase bold">Total Amount</label>
                    <div className="float-label-control">
                      <Form.Control
                        placeholder="Amount*"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={state.amount}
                        disabled
                        onChange={handleAmountChange}
                        autoComplete="off"
                      />
                      <FormLabel className="form-control-placeholder">
                        Amount*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
              )}
              <Col md="8" />
              <Col sm="6" />
              <Col sm="6" className="btn-grp mt-2">
                <Row>
                  <Col sm="6">
                    <Button variant="secondary" onClick={goBack}>
                      CANCEL
                    </Button>
                  </Col>
                  <Col sm="6">
                    <Button
                      variant="primary"
                      disabled={validateDriver()}
                      onClick={handleSubmit}
                    >
                      SAVE AND PRINT
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div hidden={!isEqual(tab, "2")}>
              <Row>
                <Col sm="12">
                  <h1 className="tab-heading">AGENT</h1>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="4">
                  <Form.Group controlId="agentdate">
                    <div className="float-label-control">
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        isClearable={true}
                        minDate={new Date().setDate(currentDate.getDate() - 30)}
                        customInput={<DepartureDatePicker />}
                        selected={state.date}
                        onChange={handleDateChange}
                        maxDate={new Date().setDate(currentDate.getDate() + 30)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col sm="12" md="4">
                  <Form.Group controlId="agentId" className="ml-lg-4">
                    <div className="float-label-control">
                      <Form.Control
                        placeholder="Agent ID*"
                        value={state.agentId}
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      <FormLabel className="form-control-placeholder">
                        Agent ID*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
                <Col sm="12" md="4">
                  <Form.Group controlId="agentamount" className="float-right">
                    <div className="float-label-control">
                      <Form.Control
                        placeholder="Amount*"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={state.amount}
                        onChange={handleAmountChange}
                        autoComplete="off"
                      />
                      <FormLabel className="form-control-placeholder">
                        Amount*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Group controlId="reason">
                    <div className="float-label-control">
                      <Form.Control
                        placeholder="Reason for paid in*"
                        value={state.reason}
                        onChange={handleChange}
                        autoComplete="off"
                        className="mw-100 mr-9"
                      />
                      <FormLabel className="form-control-placeholder">
                        Reason for paid in*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="12" className="btn-grp text-right">
                  <Button variant="secondary" onClick={goBack}>
                    CANCEL
                  </Button>
                  <Button
                    className="ml-4"
                    variant="primary"
                    disabled={validateAgent()}
                    onClick={handleSubmit}
                  >
                    SAVE AND PRINT
                  </Button>
                </Col>
              </Row>
            </div>
            <div hidden={!isEqual(tab, "3")}>
              <Row>
                <Col sm="12">
                  <h1 className="tab-heading">OTHER</h1>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="4">
                  <Form.Group controlId="otherdate">
                    <div className="float-label-control">
                      <DatePicker
                        dateFormat="MM/dd/yyyy"
                        isClearable={true}
                        startDate={new Date() - 30}
                        minDate={new Date().setDate(currentDate.getDate() - 30)}
                        customInput={<DepartureDatePicker />}
                        selected={state.date}
                        onChange={handleDateChange}
                        className="d-flex justify-content-end"
                        maxDate={new Date().setDate(currentDate.getDate() + 30)}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col sm="12" md="4">
                  <Form.Group controlId="source" className="ml-lg-4">
                    <div className="float-label-control">
                      <Form.Control
                        placeholder="Source of paid in*"
                        value={state.source}
                        autoComplete="off"
                        onChange={handleChange}
                      />
                      <FormLabel className="form-control-placeholder">
                        Source of paid in*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
                <Col sm="12" md="4">
                  <Form.Group controlId="otheramount" className="float-right">
                    <div className="float-label-control d-flex justify-content-center">
                      <Form.Control
                        placeholder="Amount*"
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={state.amount}
                        onChange={handleAmountChange}
                        autoComplete="off"
                      />
                      <FormLabel className="form-control-placeholder">
                        Amount*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Group controlId="reason">
                    <div className="float-label-control">
                      <Form.Control
                        placeholder="Reason for paid in*"
                        value={state.reason}
                        onChange={handleChange}
                        autoComplete="off"
                        className="mw-100 mr-9"
                      />
                      <FormLabel className="form-control-placeholder">
                        Reason for paid in*
                      </FormLabel>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="btn-grp text-right">
                  <Button variant="secondary" onClick={goBack}>
                    CANCEL
                  </Button>
                  <Button
                    className="ml-4"
                    variant="primary"
                    disabled={validateOther()}
                    onClick={handleSubmit}
                  >
                    SAVE AND PRINT
                  </Button>
                </Col>
              </Row>
            </div>
            <Modal className="paidin-modal" show={modal} onHide={handleModel}>
              <ModalBody>
                <img src={GroupImg} alt="GroupImg" />
                <h3>All Set</h3>
                <div className="message">Your Paid In section</div>
                <div className="message">has been saved successfully.</div>
                <Button onClick={handleModel}>Print Receipt</Button>
              </ModalBody>
              </Modal>
             
              <Modal className="journal-modal" show={errorFind} onHide={() => setErrorFind(!errorFind)}>
                <ModalHeader closeButton />
                <ModalBody>
                  <img src={CrossImg} alt="GroupImg" style={{ marginBottom: '40px' }} />
                  <p>Something went wrong</p>
                  <p>contact customer support</p>
                  <div className="text-center" style={{ marginTop: '120px' }}>
                    <button onClick={() => setErrorFind(!errorFind)}>Ok</button>
                  </div>
                </ModalBody>
              </Modal>
          </div>
        </Container>
      )}
      {isEqual(tab, "1") ? (
        <DriverPrintReceipt
          state={state}
          issuingOPR={issuingOPR}
          totalAmounts={totalAmounts}
          runTripNumber={runTripNumber}
          userDetails={userDetails}
        />
      ) : isEqual(tab, "2") ? (
        <AgentPrintReceipt state={state} userDetails={userDetails} />
      ) : (
        <OtherPrintReceipt state={state} userDetails={userDetails} />
      )}
    </Theme>
  );
};

const DepartureDatePicker = React.forwardRef(({ value, onClick }, ref) => {
  return (
    <div className="position-relative" onClick={onClick}>
      <Form.Control
        value={value}
        ref={ref}
        placeholder="Date*"
        type="text"
        readOnly
      />
      <FormLabel className="form-control-placeholder">Date*</FormLabel>
      <img src={CalenderIcon} alt="calendar icon" className="calendar-icon" />
    </div>
  );
});

export default withRouter(PaidIn);
