import React from "react";

const Footer = () => {
  return (
    <div>
      <footer className="content-container footer-container">
            <div className="container">
              <div className="row">
                  <div className="col-sm-6 content-right">
                  </div>
              </div>
          </div>
      </footer>
    </div>
  );
};

export default Footer;
