import React from "react";
import { Route, Switch } from "react-router-dom";

import BusFares from "../pages/agentportal/components/BusFares/BusFares";
import CashOut from "../pages/agentportal/components/CashOut/CashOut";
import Checkout from "../pages/agentportal/components/Checkout/Checkout";
import EmailSuccess from "../pages/agentportal/components/Checkout/EmailSuccess";
import Dashboard from "../pages/agentportal/components/Dashboard/Dashboard";
import { DashboardContext } from "../pages/agentportal/components/Dashboard/DashboardContext";
import { JournalList } from "../pages/agentportal/components/Journal/JournalList";
import LaunchScreen from "../pages/agentportal/components/LandingPage/LaunchScreen";
import Login from "../pages/agentportal/components/Login/Login";
import OrderStatus from "../pages/agentportal/components/OrderStatus/OrderStatus";
import PaidIn from "../pages/agentportal/components/PaidIn/PaidIn";
import ShoppingCart from "../pages/agentportal/components/ShoppingCart/ShoppingCart";
import GenerateTickets from "../pages/agentportal/components/Tickets/GenerateTickets/GenerateTickets";
import { AppliedRoute } from "./AppliedRoute";
import AgentPrintReceipt from '../pages/agentportal/components/PaidIn/AgentPrintReceipt';
import OtherPrintReceipt from '../pages/agentportal/components/PaidIn/OtherPrintReceipt';
import TransactionFailed from "../pages/agentportal/components/Checkout/TransactionFailed";

export default ({ childProps }) => (
  <>
    <Switch>
      <AppliedRoute
        exact
        path="/"
        component={LaunchScreen}
        props={childProps}
      />
      <Route exact path="/login" component={Login} props={childProps} />
      <Route path={["/home", "/expresscart", "/checkout"]}>
        <DashboardContext.Provider>
          <Route exact path="/home" component={Dashboard} props={childProps} />
          <Route
            exact
            path="/expresscart"
            component={BusFares}
            props={childProps}
          />
          <Route path="/checkout" component={Checkout} props={childProps} />
        </DashboardContext.Provider>
      </Route>
      <Route path="/shoppingcart" component={ShoppingCart} />
      <Route path="/cashout" component={CashOut} props={childProps} />
      <Route exact path={"/tickets"} component={GenerateTickets} />
      <Route path="/orderstatus" component={OrderStatus} />
      <Route path="/journal" component={JournalList} />
      <Route path="/paidin" component={PaidIn} />
      <Route path="/agentprintreceipt" component={AgentPrintReceipt}/>
      <Route path="/otherprintreceipt" component={OtherPrintReceipt}/> 
      <Route path="/emailsuccess" component={EmailSuccess} />
      <Route path="/transactionfailed" component={TransactionFailed} />
    </Switch>
  </>
);
