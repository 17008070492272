import _ from "lodash";
import React, {Fragment, useContext, useEffect, useState} from "react";
import { Card, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import COACHUSA_logo from "../../../../../assets/images/COACHUSA_logo.png";
import ShowTicket from "../../../../../assets/images/Shadow.png";
import {TicketContext} from "../TicketContext/TicketContext";

export default function TicketContainer({data, printValue}) {
    const {setBarcodeStatus} = useContext(TicketContext);

    let cognitoUser = localStorage.getItem('cognitoUser');
    let theID = '';
    if (cognitoUser) {
        const agentData = JSON.parse(cognitoUser);
        if (typeof agentData.username !== 'undefined') {
            theID = agentData.username;
        }
    }
    const ticketID = data.id;
    const theReference = data.id.split("-")[0].toUpperCase();
    const way = data.ticket_type.replace('ONE-WAY','O/W').replace('ROUND-TRIP','R/T').replace(/MONTHLY-PASS-N|MONTHLY-PASS/, 'MONTHLY');
    const passengerType = data.passenger_type;
    const ticketOrigin = `${data.origin_city_name}, ${data.origin_state}`;
    const ticketDestination = `${data.destination_city_name}, ${data.destination_state}`;
    let barcode_s3_url = data.barcode_s3_url;
    const shadowUrl = ShowTicket;
    const ticketExpirationFrom = data.valid_from;
    let expTime = new Date(data.valid_to);
    expTime = new Date( expTime.getTime() + ( expTime.getTimezoneOffset() * 60000 ) );
    const ticketExpirationTO = expTime;
    const ticketExpiration = expTime;
    // const ticket_fare = !_.isEmpty(data.ticket_service_fee_detail) ? parseFloat(data.ticket_service_fee_detail[0].amount) + parseFloat(data.ticket_fare) : data.ticket_fare;
    const ticket_fare =parseFloat(data.ticket_fare);
    const operator_name = data.operator_name;
    const issued_by = data.issued_by;

    const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    const date = new Date();
    const nextMonth = new Date().getMonth() + 1 === 12 ? 0 : new Date().getMonth() + 1;
    const month_of_sale = (data.ticket_type === 'MONTHLY-PASS') ? months[parseInt(date.getMonth())] : months[parseInt(nextMonth)];
    return (
      <Fragment>
          {/* New Ticket Start Here */}
          <img
            style={{width: '180px', height: '180px'}}
            src={barcode_s3_url + ".png"}
            alt="barcode"
            className="d-none"
            onLoad={() => setBarcodeStatus(ticketID, true)}
          />
          {printValue === "true" ? (
            <div style={{height: 'auto'}}>
                <div className="printed-text" style={{display: 'block', height: '20px'}}>
                    <h2 className="printed-text" style={{textAlign: 'right', color: '#000', fontWeight: 'bold',  fontSize: '18px', margin: '0'}}>{way}{(way === 'MONTHLY') ? '-'+month_of_sale : null}&nbsp;&nbsp;{passengerType}</h2>
                </div>
                <div className="printed-text" style={{width: '100%', height: '20px', display: 'block', margin: '0'}}>
                    {passengerType !== "ADULT" &&
                    passengerType !== "CHILD" &&
                    !_.isEmpty(passengerType) ? (
                        <h2 className="printed-text" style={{margin: '0', float: 'right', width: '75%', fontSize: '12px', textTransform: 'uppercase', textAlign: 'right', color: '#000'}}>
                            *VALID ID REQUIRED
                        </h2>
                      )
                      : null
                    }
                </div>
                <hr style={{margin: '0', padding: '0'}} />
                <div className="printed-text ticket-body">
                    <div className="printed-text ticket-valid-price d-flex justify-content-center justify-content-between" style={{width: '100%', height: '20px'}}>
                        <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'left', color: '#000', margin: '1px 0'}}>
                            <span className="printed-text" style={{fontWeight: 'bold'}}>FROM:</span> <span className="printed-text" style={{fontSize: '12px', fontWeight: 'bold'}}>{_.truncate(ticketOrigin.toUpperCase(), {'length': 32})}</span>
                        </h2>
                    </div>
                    <div className="printed-text  ticket-valid-price d-flex justify-content-center justify-content-between" style={{width: '100%', height: '20px'}}>
                        <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'left', color: '#000', margin: '1px 0'}}>
                            <span className="printed-text" style={{fontWeight: 'bold'}}>TO:</span> <span className="printed-text" style={{fontSize: '12px', fontWeight: 'bold'}}>{_.truncate(ticketDestination.toUpperCase(), {'length': 32})}</span>
                        </h2>
                    </div>
                    <div className="printed-text ticket-valid-price d-flex justify-content-center justify-content-between" style={{width: '100%', height: '20px', display: 'block'}}>
                        <h2 className="printed-text" style={{fontSize: '12px', margin: '3px 0 1px'}}>
                            <span className="printed-text" style={{fontWeight: 'bold'}}>Issuing OPR:</span>{" "}
                            {!_.isEmpty(operator_name) ? _.truncate(operator_name , {'length': 26}) : issued_by}
                        </h2>
                    </div>
                    <div className="printed-text ticket-valid-price d-flex justify-content-center justify-content-between" style={{width: '100%', height: '20px', display: 'block', margin: '3px 0 0 0'}}>
                        <h2 className="printed-text" style={{float: 'right', fontSize: '12px', color: '#000'}}><span className="printed-text" style={{fontWeight: 'bold'}} >AGENT ID:</span> {theID}</h2>
                    </div>
                    <div className="printed-text ticket-valid-price d-flex justify-content-center justify-content-between" style={{width: '100%', height: '20px', display: 'block', whiteSpace: 'nowrap'}}>
                        <h2 className="printed-text" style={{fontSize: '12px'}}>
                            <span className="printed-text" style={{fontWeight: 'bold'}}>Valid 123:</span>{" "}
                            <span>
                    <Moment format=" MM/DD/YYYY" withTitle>
                      {ticketExpirationFrom}
                    </Moment>-
                                <Moment format=" MM/DD/YYYY" withTitle>
                      {ticketExpirationTO}
                    </Moment>
                  </span>
                        </h2>
                        <h2 style={{fontSize: '12px', fontWeight: 'bold'}}>${parseFloat(ticket_fare).toFixed(2)}</h2>
                    </div>
                </div>
                <hr style={{margin: '0', padding: '0'}} />
                <div className="barcode">
                    <h2 className="printed-text" style={{margin: '0 auto', fontSize: '12px', color: '#000', marginTop: '2px', textTransform: 'none'}}><span className="printed-text" style={{fontSize: '12px', fontWeight: 'bold'}}>Ticket Reference:</span> {theReference}</h2>
                    <img style={{width: '180px', height: '180px'}} src={barcode_s3_url + ".png"} alt="barcode" />
                    <h2 className="printed-text" style={{margin: '0 auto', fontSize: '12px', color: '#000', marginTop: '2px', textTransform: 'none'}}>Go Digital Download Our App</h2>
                    <h2 className="printed-text" style={{margin: '0 auto', fontSize: '12px', color: '#000', marginTop: '2px', textTransform: 'none'}}>www.coachusa.com/travel-app</h2>
                </div>
            </div>
          ) : (
            <div className="print-box page-break">
                <div className="main-ticket-area">
                    <Card
                      className="ticket-card"
                      style={{ backgroundImage: "url(" + shadowUrl + ")" }}
                    >
                        <Row className="padding0 text-center">
                            <div className="tickets-white-top" />
                            <div className="tickets-white-bottom" />
                            <Col md={6} xs={12} className="left-box">
                                <div className="printed-text ticket-container-adjust-space">
                                    <Row>
                                        <Col>
                                            <div>
                                                <Row>
                                                    <Col className="printed-text origin ticket-title">Origin</Col>
                                                </Row>
                                                <Row>
                                                    <Col className="printed-text origin-name">
                                                        {ticketOrigin.toUpperCase()}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div>
                                                <Row>
                                                    <Col className="printed-text destination ticket-title">
                                                        Destination
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="printed-text destination-name">
                                                        {ticketDestination.toUpperCase()}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="printed-text passenger-type">
                                                        <span className="printed-text pass-way">{way}{way==='MONTHLY' ? '-'+month_of_sale : null}</span>{" "}
                                                        <span className="printed-text type-pass">
                                  {passengerType}
                                </span>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="printed-text ticket-expiration">
                            <span className="printed-text expiration-title">
                              Expiration date
                            </span>
                                                &nbsp;
                                                <span className="printed-text expiration-date">
                              <Moment format=" MM/DD/YYYY" withTitle>
                                {ticketExpiration}
                              </Moment>
                            </span>
                                            </div>

                                            {passengerType !== "ADULT" &&
                                            passengerType !== "CHILD" &&
                                            !_.isEmpty(passengerType) ? (
                                              <div className="printed-text ticket-valid">
                                                  *VALID ID REQUIRED
                                              </div>
                                            ) : null}
                                            <p className="printed-text note">Please print your tickets, or display to our driver on your mobile device.</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={6} xs={12} className="right-box">
                                <Row>
                                    <Col className="logo-img">
                                        <img src={COACHUSA_logo} alt="Coach USA Logo" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="printed-text barcode-text">
                                        Ticket Reference {theReference}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="barcode-img">
                                        <img src={barcode_s3_url + ".png"} alt=" " />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
          )}
      </Fragment>
    );
}