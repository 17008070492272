const initialState = {
  isAuthenticated: true,
  isLoading:false,
  authUser:{},
  userError:"",
  newUser:null,
  confirmUser:"",
  logOutRespose:null,
  loginError:null,
  data:"",
  confirmUserPwd:false,
  currentAuthSession:null,
  loggedInUser:null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case "COGNITO_AUTH_SUCCESS":
      return { ...state, isAuthenticated: true, loggedInUser:action.payload};
    case "COGNITO_AUTH_FAILURE":
      return { ...state, isAuthenticated: false, loginError:action.payload};
    case "COGNITO_LOGOUT_SUCCESS":
      return { ...state, isAuthenticated: false ,logOutRespose:action.payload, confirmUser:"",newUser:null,userError:null};
    case "COGNITO_AUTH_SESSION_SUCCESS":
      return { ...state, currentAuthSession: action.payload,isAuthenticated: true };
    case "COGNITO_AUTH_SESSION_FAILURE":
      return { ...state, isAuthenticated: false };
    case "REQUEST_START":
        return {
          ...state,
          isLoading: action.payload
        };
    case "REQUEST_STOP":
        return {
          ...state,
          isLoading: action.payload
        };
    case "COGNITO_AUTHENTICATED_USER":
        return { ...state, isAuthenticated: true, authUser: action.payload,userError:null };
      
    case "COGNITO_AUTHENTICATED_USER_ERROR":
        return { ...state, userError: action.payload };
    case "COGNITO_AUTHENTICATED_USER_END":
        return { ...state, authUser: action.payload };
    case "COGNITO_SIGNUP_SUCCESS":
          return { ...state, newUser: action.payload,userError:null  };
    case "COGNITO_SIGNUP_FAILED":
          return { ...state, userError: action.payload,newUser:null  };
    case "COGNITO_CONFIRM_SIGNUP_SUCCESS":
            return { ...state, confirmUser: action.payload,userError:null  };
    case "COGNITO_CONFIRM_SIGNUP_FAILED":
            return { ...state, userError: action.payload,confirmUser:""  };
    case "COGNITO_FORGOTPWD_SUCCESS":
            return { ...state, data: action.payload,userError:null  };
    case "COGNITO_FORGOTPWD_FAILED":
            return { ...state, userError: action.payload,data:""  };
    case "COGNITO_CONFIRM_FORGOTPWD_SUCCESS":
              return { ...state, confirmUserPwd: action.payload,userError:null  };
    case "COGNITO_CONFIRM_FORGOTPWD_FAILED":
              return { ...state, userError: action.payload,confirmUserPwd:""  };
    default:
      return state;
  }
};
