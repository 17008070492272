import React from "react";
import Logo from '../../../../assets/images/coach-icon-spinner.gif';

import {
  Row,
  Col,
} from "react-bootstrap";

function CoachLoader(props) {
  return (
    <div className="container">
        <Row>
          <Col xs={12}>
            <div className="coach-loader">
              <h3> {props.message} </h3> 
              <img
                src={Logo}
                alt="Spinner"
                />
            </div>
          </Col>
        </Row>
    </div>
  );
}

export default CoachLoader;
