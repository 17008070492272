import React from "react";
import { useState } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Button,
  FormLabel,
  Form,
  Modal,
  ModalBody,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import CheckoutFields from "./CheckoutFields";
import GroupImg from "../../../../assets/images/Group.svg";
import { useContext } from "react";
import CongnitoTokenContext from "../../../../CognitoTokenContext";

const CheckoutUI = (props) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [editEmail, setEditEmail] = useState(true);

  const context = useContext(CongnitoTokenContext);
  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    switch (name) {
      case "emailAdd":
        const emailValue = value.toLowerCase();
        const emailpattern = new RegExp(
          /^([\w.%+-]+)@([\w-]+\.)+([a-zA-Z]{2,15})$/i
        );
        if (!emailpattern.test(value) && !_.isEmpty(value)) {
          setEmailAddress(emailValue);
          setErrorEmail("Please enter a valid Email Address");
        } else {
          setEmailAddress(emailValue);
          setErrorEmail("");
        }
        break;
      default:
        break;
    }
  };

  const handleModel = () => {
    setModal(false);
  };

  const savedEmail = () => {
    setModal(true);
  };

  const savedEmailSuccess = (e) => {
    e.preventDefault();
    let error = "";
    if (typeof emailAddress !== "undefined") {
      let emailpattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
      if (!emailAddress.match(emailpattern)) {
        error = "Please enter a valid email address";
      }
      setErrorEmail(error);
    }

    const email = emailAddress;
    localStorage.setItem("ticketEmail", email.toLowerCase());
    setEmailAddress(email);
    setEditEmail(false);
    setModal(false);
  };

  const handleEditEmail = () => {
    setEditEmail(!editEmail);
  };

  return (
    <div>
      <img src={GroupImg} alt="GroupImg" className="d-none" />
      <Row className="checkout-form-field">
        <Col md={12} xs={12}>
          <Row>
            <Col md={12} xs={12}>
              <div style={{ minHeight: 233 }}>
                <Row>
                  <Col>
                    <div className="splitCreditCard">
                      <h4>PAYMENT 1</h4>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={3} xs={12}>
                    <Button
                      name="btnContinue"
                      type="submit"
                      disabled={props.paymentMode1 === "cc"}
                      onClick={() => props.handlePaymentMode1Change("cc")}
                      className="btnCheckout"
                    >
                      CREDIT CARD
                    </Button>
                  </Col>

                  <Col md={3} xs={12}>
                    <Button
                      name="btnContinue"
                      type="submit"
                      disabled={props.paymentMode1 === "cash"}
                      onClick={() => props.handlePaymentMode1Change("cash")}
                      className="btnCheckout"
                    >
                      CASH
                    </Button>
                  </Col>
                  <Col md={3} xs={12}>
                    <Button
                      name="btnContinue"
                      type="submit"
                      disabled={props.paymentMode1 === "check"}
                      onClick={() => props.handlePaymentMode1Change("check")}
                      className="btnCheckout"
                    >
                      CHECK
                    </Button>
                  </Col>
                  <Col md={3} xs={12}>
                    <Button
                      name="btnContinue"
                      type="submit"
                      disabled={props.paymentMode1 === "voucher"}
                      onClick={() => props.handlePaymentMode1Change("voucher")}
                      className="btnCheckout"
                    >
                      {!_.isEmpty(context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'VOUCHER/COMP' : 'VOUCHER'}
                    </Button>
                  </Col>
                  <Col>
                    <CheckoutFields
                      type={props.paymentMode1}
                      method="first"
                      handlePaymentMethodChanges={
                        props.handlePaymentMethodChanges
                      }
                      paymentDetail={props.paymentDetail}
                      validationErrors={props.validationErrors}
                      handleBlur={props.handleBlur}
					  handleFocus={props.handleFocus}
					  cognitoTokenValue={context.cognitoTokenValue}
                    />
                  </Col>
                </Row>
              </div>
              {props.splitPayment ? null : (
                <div style={{ minHeight: 230 }}>
                  <Row>
                    <Col>
                      <div className="splitCreditCard">
                        <h4>PAYMENT 2</h4>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={3} xs={12}>
                      <Button
                        name="btnContinue"
                        type="submit"
                        disabled={props.paymentMode2 === "cc"}
                        onClick={() => props.handlePaymentMode2Change("cc")}
                        className="btnCheckout"
                      >
                        CREDIT CARD
                      </Button>
                    </Col>
                    {/*  hidden={props.paymentMode1 === 'cash'} */}
                    <Col md={3} xs={12}>
                      <Button
                        name="btnContinue"
                        type="submit"
                        disabled={props.paymentMode2 === "cash"}
                        onClick={() => props.handlePaymentMode2Change("cash")}
                        className="btnCheckout"
                      >
                        CASH
                      </Button>
                    </Col>
                    <Col md={3} xs={12}>
                      <Button
                        name="btnContinue"
                        type="submit"
                        disabled={props.paymentMode2 === "check"}
                        onClick={() => props.handlePaymentMode2Change("check")}
                        className="btnCheckout"
                      >
                        CHECK
                      </Button>
                    </Col>
                    <Col md={3} xs={12}>
                      <Button
                        name="btnContinue"
                        type="submit"
                        disabled={props.paymentMode2 === "voucher"}
                        onClick={() =>
                          props.handlePaymentMode2Change("voucher")
                        }
                        className="btnCheckout"
                      >
                        {!_.isEmpty(context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'VOUCHER/COMP' : 'VOUCHER'}
                      </Button>
                    </Col>
                    <Col>
                      <CheckoutFields
                        type={props.paymentMode2}
                        method="second"
                        handlePaymentMethodChanges={
                          props.handlePaymentMethodChanges
                        }
                        paymentDetail={props.paymentDetail}
                        validationErrors={props.validationErrors}
                        handleBlur={props.handleBlur}
						handleFocus={props.handleFocus}
						cognitoTokenValue={context.cognitoTokenValue}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              <div className="breaker mb-5"></div>
              <Row className="checkout">
                <Col md={5} className="test ">
                  {!_.isEmpty(context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') && (
                    <div className="bottom-text mb-2">
                      Enter email address for ticket delivery
                    </div>
                  )}
                  <span className="emailed_text">
                    {!editEmail ? (
                      <span>Tickets and receipt will be emailed to</span>
                    ) : null}
                    {!editEmail ? <b>{emailAddress}</b> : null}
                    {!editEmail ? (
                      <Button
                        onClick={handleEditEmail}
                        className="btn-link btn btn-primary"
                      >
                        Edit
                      </Button>
                    ) : null}
                  </span>

                  {editEmail && !_.isEmpty(context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? (
                    <>
                      <Form.Group controlId="formEmail">
                        <div className="float-label-control emailboxpostition">
                          <Form.Control
                            type="text"
                            placeholder="Email Address*"
                            name="emailAdd"
                            value={emailAddress}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <FormLabel className="form-control-placeholder text-left">
                            Email Address*
                          </FormLabel>
                          {!_.isEmpty(errorEmail) ? (
                            <div className="error-msg">{errorEmail}</div>
                          ) : null}
                        </div>
                      </Form.Group>
                    </>
                  ) : null}
                </Col>
                <Col>
                  <div className="checkout-btn-group mt-4">
                    <Button
                      name="btnContinue"
                      type="submit"
                      className="btn"
                      disabled={
                        props.validateBuy() || (!_.isEmpty(context.cognitoTokenValue) && editEmail && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP'))
                      }
                      onClick={props.handleSubmit}
                      size="lg"
                    >
                      BUY NOW
                    </Button>
                  </div>
                </Col>
              </Row>
              {editEmail && !_.isEmpty(context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? (
                <Row className="mb-3">
                  <Col>
                    <Button
                      type="submit"
                      onClick={savedEmail}
                      disabled={
                        !_.isEmpty(errorEmail) || _.isEmpty(emailAddress)
                          ? true
                          : false
                      }
                      size="sm"
                      className="px-4"
                    >
                      SAVE
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal className="checkout-modal" show={modal} onHide={handleModel}>
        <ModalHeader closeButton />
        <ModalBody>
          <img src={GroupImg} alt="GroupImg" />
          <h3>The email for ticket delivery is saved successfully.</h3>
          <span>{emailAddress}</span>
          <div className="message">
            Please confirm email for ticket delivery.
          </div>
          <div className="text-center">
            <Button onClick={savedEmailSuccess}>OK</Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CheckoutUI;
