import _, { get, isEmpty, groupBy, sum,map } from "lodash";
import React, { Fragment } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import PlusLogo from "../../../../assets/images/plus.svg";
import MinusLogo from "../../../../assets/images/minus.svg";
import busFaresActions from "../../actions/busFares/busFares";
import MapKeys from "../../utils/transformObjectKeys/mapKeys";
import Theme from "../Theme/index";
import { getMonthlyTicketType, validity} from "../../utils/commonUtils/commonUtils";
import jwt_decode from "jwt-decode";
class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oneWayTicketData: [],
      searchParam: localStorage.getItem("qSearch")
        ? localStorage.getItem("qSearch")
        : "",
        
      cognitoTokenValue:''
    };
    if (this.props.buyAgainProductId) {
      this.props.fetchByTicket(this.props.buyAgainProductId);
    }
  }

  componentDidMount() {
    const body = document.getElementsByTagName("body");
    body[0].classList.add("bgWhite");
    window.scrollTo(0, 0);
    const userDetails = JSON.parse(localStorage.getItem('cognitoUser'));
    const decodeToken = userDetails.signInUserSession.accessToken.jwtToken;
    const finalDecodeToken = jwt_decode(decodeToken);
    this.setState({
      cognitoTokenValue : _.get(finalDecodeToken, 'cognito:groups', {})
    })
  }

  getTicketItemList = (data, count) => {
    const operatorCode = new URLSearchParams(this.state.searchParam);
    return [
      {
        ticketId: data.ticketId,
        productId: data.productId,
        quantity: 1,
        added: count > 0 ? true : false,
        resultantQuantity: parseInt(data.quantity) + count,
        fare: parseFloat(data.fare).toFixed(2),
        ticketType: data.ticketType,
        passengerType: data.passengerType,
        originCity: data.originCity,
        originState: data.originState,
        destinationCity: data.destinationCity,
        destinationState: data.destinationState,
        fareZone: data.fareZone,
        operator: data.operater,
        hasServiceFee: data.hasServiceFee,
        hasFuelCharge: data.hasFuelCharge,
        serviceFeeList: data.serviceFeeList,
        serviceName: data.serviceName,
        serviceType: data.serviceType,
        sod: data.sod,
        boardingDate:
          data.serviceType === "airport" && data.boardingDate
            ? data.boardingDate
            : "",
        boardingStartTime:
          data.serviceType === "airport" && data.boardingStartTime
            ? data.boardingStartTime
            : "",
        boardingEndTime:
          data.serviceType === "airport" && data.boardingEndTime
            ? data.boardingEndTime
            : "",
        issuedBy: operatorCode.get("operator_code"),
      },
    ];
  };

  handlePlus = (data) => {
    let count = 1;
    const cartId = _.get(this.props.ticketInfo, "cartId");
    const ticketItemList = this.getTicketItemList(data, count);
  
    const cart = {
      cartId: cartId,
      ticketitems: ticketItemList,
      agentLocationId: localStorage.getItem("agent_Location"),
      isCustomerAgent: !_.isEmpty(this.state.cognitoTokenValue) ? true : false,
      cognitoRoleName: !_.isEmpty(this.state.cognitoTokenValue) ? "customer_agent" : "agent",
    };

    this.props.sendCartInfo(cart);
  };

  handleMinus = (data) => {
    let count = -1;
    const cartId = _.get(this.props.ticketInfo, "cartId");
    const ticketItemList = this.getTicketItemList(data, count);
    const cart = {
      cartId: cartId,
      ticketitems: ticketItemList,
      agentLocationId: localStorage.getItem("agent_Location"),
      isCustomerAgent: !_.isEmpty(this.state.cognitoTokenValue) ? true : false,
      cognitoRoleName: !_.isEmpty(this.state.cognitoTokenValue) ? "customer_agent" : "agent",
    };

    this.props.sendCartInfo(cart);
  };

  handlePurchase = () => {
    this.props.history.push("./checkout");
  };

  newSearch = () => {
    this.props.history.push("./home");
  };

  componentWillUnmount() {
    const body = document.getElementsByTagName("body");
    body[0].classList.remove("bgWhite");
  }

  passengerSubLabel = (row) => {
    const {data, isRequesting}  = this.props.ticketFareRuleLabels;
    if (isRequesting) {return ''}
    const key = `${row.issuedBy}_${row.ticketType}_${row.passengerType}.ageGroup`;
    return get(data, key, '');
  }

  ticketTypeName = (row) => {
    let ticketType = row.ticketType
      ticketType = _.includes(ticketType, 'MONTHLY') ? getMonthlyTicketType(ticketType) : ticketType;
   return ticketType;
  };

  render() {
    const passengerLabeling = MapKeys;
    const ticketsInCart = _.get(this.props, "ticketInfo.ticketitems", []);
    let bookedTickets = [];
    if (!_.isEmpty(ticketsInCart)) {
      bookedTickets = _.map(ticketsInCart, (item, i) => {
		const issuedBy = item.issuedBy ? item.issuedBy : "HTL";
        let newValues = passengerLabeling[issuedBy];
        // let newValues = passengerLabeling['HTL'];
        let passName = newValues ? newValues[item.passengerType] : '';
        return {
          ...passName,
          ...item,
        };
      });
    }

    let ticketFare = _.get(this.props, "ticketFare", {});
    let totalFare = _.get(this.props, "ticketInfo.totalFare", 0);

    let sortedData = {};
    let sodList = [];

    let totalServiceFeeList = [];
    if (bookedTickets.length) {
      bookedTickets.forEach((ticket) => {
        let sod = ticket.sod;
        if (!sortedData[sod]) {
          sortedData[sod] = [];
          sodList.push(ticket);
        }
        if (!isEmpty(ticket.serviceFeeList)) {
          totalServiceFeeList = totalServiceFeeList.concat(ticket.serviceFeeList.map(fee => {
						fee.quantity = ticket.quantity;
						return fee
					  }));
        }
        sortedData[sod].push(ticket);
      });
    }
    totalServiceFeeList = groupBy(totalServiceFeeList, "displayName");
    const subTotal = sum(bookedTickets.map(ci => (parseFloat(ci.fare) * parseInt(ci.quantity))))

    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const date = new Date();

    return (
      <Theme>
        <div className="container">
          {this.props.isCartFill && (
            <div className="spinner-bg">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
          <div className="row">
            <div className="col-sm-12">
              <div className="d-flex justify-content-between justify-content-center">
                <h2 className="shcrt">Shopping Cart</h2>
                <div className="shopping-cart-new-search">
                  <Button
                    className="btn btn-secondary w260"
                    onClick={this.newSearch}
                  >
                    NEW SEARCH
                  </Button>
                </div>
                <div
                  className="shopping-cart-new-search-mobile"
                  onClick={this.newSearch}
                >
                  NEW SEARCH
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="table-responsive">
                <Table borderless className="shopping-table">
                  {_.isEmpty(ticketFare) && bookedTickets.length === 0
                    ? this.props.history.push("./home")
                    : sodList.map((sodObj, index) => {
                        return (
                          <Fragment key={index}>
                            <thead>
                              <tr>
                                <th colSpan={3}>
                                  <span>Origin</span>
                                  <span>
                                    {sodObj.originCity}, {sodObj.originState}
                                  </span>
                                  <span>Destination</span>
                                  <span>
                                    {sodObj.destinationCity},{" "}
                                    {sodObj.destinationState}
                                  </span>
                                </th>
                              </tr>
                              <tr className="shopbackticket">
                              <td>
                                      <span className="shoptickdetail">
                                     Ticket
                                      </span>
                                      <span>Passenger</span>
                                      <span className="final-admin-fee-label containerbox shopbackticket">             
                                          QTY                                      
                                      </span>
                                    </td>
                                    <td className="minus-plus">
                                    
                                    </td>
                                    <td className="text-right">
                                     Amount
                                    </td>
      </tr>
                            </thead>
                            <tbody>
                              {sortedData[sodObj.sod].map((data, index2) => {
                                return (
                                  <tr key={`${index}-${index2}`}>
                                    
                                    <td>
                                      <span>
                                      {this.ticketTypeName(data)} 
                                      </span>
                                      <span>{data.passengerType}</span>
                                      <span className="age">
                                      {_.includes(data.ticketType, 'MONTHLY') ? (
                                          validity(data.ticketType)
                                        ) : (
                                            this.passengerSubLabel(data)
                                          )}
                                      </span>
                                      <span className="final-admin-fee-label containerbox">
                                        <div
                                          onClick={() =>
                                            this.handleMinus(data, index)
                                          }
                                        >
                                          <img
                                            src={
                                              data.quantity > 0
                                                ? MinusLogo
                                                : MinusLogo
                                            }
                                            alt="minus"
                                          />
                                        </div>
                                        <div className="qnty">
                                          {data.quantity}
                                        </div>
                                        <div
                                          onClick={() =>
                                            this.handlePlus(data, index)
                                          }
                                        >
                                          <img src={PlusLogo} alt="plus" />
                                        </div>
                                      </span>
                                    </td>
                                   
                                    <td>
                                      <div class="text-xs">
                                        
                                         
                                        {!isEmpty(data.serviceFeeList) &&
                                          map(data.serviceFeeList, (fee) => {
                                            return <div className="row mb-1" key={fee.displayName}>
                                              <div className="col-6 text-right pr-0">
                                                {_.get(fee, "displayName")}(s):
                                            </div>
                                              <div className="col-6 pl-2">
                                                ${(parseFloat(_.get(fee, "amount")) * parseInt(data.quantity)).toFixed(2)}
                                              </div>
                                            </div>
                                            })}
                                            
                                        </div>
                                    </td>
                                    <td>
                                      $
                                      {Number(
                                        parseFloat(
                                          Math.round(
                                            data.fare * data.quantity * 100
                                          ) / 100
                                        ).toFixed(2)
                                      ).toLocaleString(navigator.language, {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Fragment>
                        );
                      })}
                  <tfoot>
                  {Object.keys(totalServiceFeeList).length && subTotal ? 
                  <tr>
                    <th className="bottomTh" colSpan={2}>
                      <span className="final-admin-fee-label">
                        Subtotal
                      </span>
                    </th>
                    <th className="bottomTh">
                      <span className="final-admin-fee-price">
                        ${subTotal.toFixed(2)}
                      </span>
                    </th>
                  </tr> : null }
				  {Object.keys(totalServiceFeeList).map((feeName,i) => {
                   return <tr key={i}>
                      <th className="bottomTh" colSpan={2}>
                        <span className="final-admin-fee-label">
                        {feeName}(s)
                        </span>
                      </th>
                      <th className="bottomTh">
                        <span className="final-admin-fee-price">
                          ${ sum(totalServiceFeeList[feeName].map(f => parseFloat(f.amount) * parseInt(f.quantity))).toFixed(2) } 
                        </span>
                      </th>
                    </tr>
                })}
                    <tr>
                      <th className="bottomTh mb-2" colSpan={2}>Amount Due</th>
                      <th className="bottomTh mb-2">
                        $
                        {Number(
                          parseFloat(totalFare).toFixed(2)
                        ).toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                        })}
                      </th>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>

            <div className="text-center max-width mt-5">
              <Button
                className="btn"
                disabled={bookedTickets.length === 0}
                onClick={() => this.handlePurchase()}
                style={{ width: "320px" }}
              >
                BUY NOW
              </Button>
            </div>
          </div>
        </div>
      </Theme>
    );
  }
}

const mapStateToProps = (state) => ({
  oneWayOrder: state.busFares.oneWayOrder,
  roundTripOrder: state.busFares.roundTripOrder,
  ticketInfo: state.busFares.ticketInfo,
  ticketFare: state.busFares.ticketFare,
  isLoading: state.busFares.isLoading,
  isCartFill: state.busFares.isCartFill,
  buyAgainProductId: state.profileReducer.buyAgainProductId,
  ticketFareRuleLabels: state.ticketFareRuleReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchByTicket: (productId) =>
      dispatch(busFaresActions.fetchByTicket(productId)),
    setOneWayOrder: (updatedOneWayCardData) =>
      dispatch(busFaresActions.setOneWayOrder(updatedOneWayCardData)),
    setRoundTripOrder: (updatedRoundTripCardData) =>
      dispatch(busFaresActions.setRoundTripOrder(updatedRoundTripCardData)),
    sendCartInfo: (cart) => dispatch(busFaresActions.sendCartInfo(cart)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ShoppingCart);
