import React from "react";
import _, { trim, get, isEmpty, groupBy, sum, defaultTo, isNumber,map } from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Nav,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Loader from "../Loader/Loader";
import busFaresActions from "../../actions/busFares/busFares";
import MapKeys from "../../utils/transformObjectKeys/mapKeys";
import TicketFareTab from "./TicketFareTab";
import DesktopView from "./DesktopView";
import appConstants from "../../constants/appConstants";
import Theme from "../Theme/index";
import { queryStringToObject } from "../../utils/commonUtils/commonUtils";
import {
  getMonthlyTicketType,
  validity,
} from "../../utils/commonUtils/commonUtils";
import jwt_decode from "jwt-decode";

function getBoardingTime(time, index) {
  const timeArr = time ? time.split("-") : [];
  return timeArr[index] ? trim(timeArr[index]) : "";
}

const apiToUiTransformKeys = {
  oneWay: "ONE-WAY",
  roundTrip: "ROUND-TRIP",
  multiTrip: "MULTI-RIDE",
};

const toolTipLabelText = {
  oneWay: "See One-Way Fare Rules",
  roundTrip: "See Round-Trip Fare Rules",
  multiTrip: "See Multi-Ride Fare Rules",
};

const { APP_ASSETS_FOLDER, APP_PROXY_FOLDER } = appConstants;

let heading = "See One-Way Fare Rules";
// const rule_tool_tip1 = (
// 	<Tooltip>One-way tickets are good for travel ninety (90) days in addition to the date of sale.</Tooltip>
// );
// const rule_tool_tip2 = (
// 	<Tooltip>
// 		Round-trip tickets are good for travel one hundred and twenty (120) days in addition to the date of sale, unless
// 		otherwise specified.
// 	</Tooltip>
// );
// const rule_tool_tip3 = (
// 	<Tooltip>
// 		Ten (10) One-Way trips will be limited for use to twenty (20) days from and including the date of sale by one
// 		individual. Forty (40) One-Way trips will be limited for use to forty (40) days from and including the date of
// 		sale by one individual.
// 	</Tooltip>
// );

// const FareRules1 = () => (
// 	<OverlayTrigger trigger="click" placement="right" overlay={rule_tool_tip1} rootClose>
// 		<span className="cursor-pointer">{heading}</span>
// 	</OverlayTrigger>
// );
// const FareRules2 = () => (
// 	<OverlayTrigger trigger="click" placement="right" overlay={rule_tool_tip2} rootClose>
// 		<span className="cursor-pointer">{heading}</span>
// 	</OverlayTrigger>
// );
// const FareRules3 = () => (
// 	<OverlayTrigger trigger="click" placement="right" overlay={rule_tool_tip3} rootClose>
// 		<span className="cursor-pointer">{heading}</span>
// 	</OverlayTrigger>
// );

class BusFares extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      cart: [],
      show: false,
      tabSelected: "oneWay",
      ticketEmail: !_.isEmpty(localStorage.getItem("ticketEmail"))
        ? localStorage.getItem("ticketEmail")
        : "",
      emailError: "",
      ticketCount: [],
    };
  }

  // renderTicketFareRule() {
  // 	const [firstTicket] =  this.props.data && this.props.data.length ? this.props.data : [];
  // 	const operatorCode = this.getOperatorCode()
  // 	const {tabSelected}  = this.state
  // 	const {data, defaultData, isRequesting}  = this.props.ticketFareRuleLabels;
  // 	let toolTipText = ''
  // 	/**
  // 	 * If data did not fetch from the server
  // 	 */
  // 	if (isRequesting) {
  // 	  toolTipText =  ''
  // 	} else {
  // 	  const fareRule = get(data, ['rules', operatorCode, apiToUiTransformKeys[tabSelected]]);
  // 	  // If Received the operatorCode Related data
  // 	  if (data &&  fareRule ) {
  // 		toolTipText =  fareRule;
  // 	  } else {
  // 		const dataKey =  firstTicket ? (firstTicket.serviceType === 'airport' ? 'airportRules' : 'busRules') : 'busRules'
  // 		toolTipText =  get(defaultData, [dataKey, apiToUiTransformKeys[tabSelected]]);
  // 	  }
  // 	}
  // 	const heading =  toolTipLabelText[tabSelected] ? toolTipLabelText[tabSelected] : 'See One-Way Fare Rules';
  // 	return <OverlayTrigger trigger="click" placement="right" overlay={<Tooltip>{toolTipText}</Tooltip>} rootClose>
  // 	  <span className="cursor-pointer">{heading}</span>
  // 	</OverlayTrigger>

  //   }
  getOperatorCode = () => {
    const qParams = new URLSearchParams(this.props.location.search);
    return qParams.get("operator_code", "");
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let searchParam = this.props.location.search;
    let qParams;
    if (searchParam) {
      qParams = new URLSearchParams(searchParam);
      if (qParams.get("operator_code"))
        this.setState({ operatorCode: qParams.get("operator_code") });
      if (
        qParams.get("origin_fare_zone") &&
        qParams.get("destination_fare_zone")
      ) {
        localStorage.setItem("qSearch", searchParam);
        this.props.fetchBusFares(
          "originFareZone=" +
            qParams.get("origin_fare_zone") +
            "&destinationFareZone=" +
            qParams.get("destination_fare_zone") +
            "&operatorCode=" +
            qParams.get("operator_code")
        );
      }
    } else {
      searchParam = localStorage.getItem("qSearch");
      if (searchParam) {
        qParams = new URLSearchParams(localStorage.getItem("qSearch"));
        if (
          qParams.get("origin_fare_zone") &&
          qParams.get("destination_fare_zone")
        ) {
          this.props.fetchBusFares(
            "originFareZone=" +
              qParams.get("origin_fare_zone") +
              "&destinationFareZone=" +
              qParams.get("destination_fare_zone") +
              "&operatorCode=" +
              qParams.get("operator_code")
          );
        }
      }
    }
    this.getTicketCount();
  }

  showModal = () => {
    this.setState({ show: !this.state.show });
  };

  ///new code started
  handleAddRemoveTicketFromCart = (data, addedCount) => {
    if (addedCount < 1 && !this.ticketCountInCart[data.id]) {
      return;
    }
    const key = [this.props.tabSelected + "Cart"];
    const updatedData = _.reject(this[key], (item) => {
      return item.passengerTypeName === data.passengerTypeName;
    });
    this[key] = updatedData;

    const alltickets = _.get(this.props.ticketInfo, "ticketitems");
    const ticketId = _.result(
      _.find(alltickets, function (obj) {
        return obj.productId === data.id;
      }),
      "ticketId",
      null
    );
    const itemToAddRemove = this.getAddRemoveItemList(
      data,
      ticketId,
      addedCount
    );
    const cartId = _.get(this.props.ticketInfo, "cartId");
    const userDetails = JSON.parse(localStorage.getItem("cognitoUser"));
    const decodeToken = userDetails.signInUserSession.accessToken.jwtToken;
    const finalDecodeToken = jwt_decode(decodeToken);
    const cognitoTokenValue = _.get(finalDecodeToken, "cognito:groups", {});
    let cart = {
      cartId: cartId,
      ticketitems: itemToAddRemove,
      email: this.email,
      agentLocationId: localStorage.getItem("agent_Location"),
      isCustomerAgent: !_.isEmpty(cognitoTokenValue) ? true : false,
      cognitoRoleName: !_.isEmpty(cognitoTokenValue)
        ? "customer_agent"
        : "agent",
    };
    this.props.sendCartInfo(cart);
    // let ticketInfo = this.props.ticketInfo;
    const ticket = {
      ...this[key],
    };
    if (key === "oneWayCart") {
      this.props.setOneWayOrder(updatedData);
      return;
    }
    if (key === "roundTripCart") {
      this.props.setRoundTripOrder(ticket);
      return;
    }
  };

  getAddRemoveItemList = (data, ticketId, addedCount, isInput = false) => {
    const operatorCode = this.state.operatorCode;
    const queryStringObj = queryStringToObject(window.location.href);
    return [
      {
        ticketId: ticketId,
        productId: data.id,
        quantity : isInput ? this.ticketCountInCart[data.id] > addedCount ? this.ticketCountInCart[data.id] - addedCount : addedCount - defaultTo(this.ticketCountInCart[data.id], 0) : 1,
        added: isInput ? this.ticketCountInCart[data.id] < addedCount : addedCount > 0 ? true : false,
        resultantQuantity: isInput ? addedCount : this.ticketCountInCart[data.id] + addedCount,
        fare: parseFloat(data.computedFare).toFixed(2),
        ticketType: data.ticketTypeName,
        passengerType: data.passengerTypeName,
        originCity: data.originCityName,
        originState: data.originState,
        destinationCity: data.destinationCityName,
        destinationState: data.destinationState,
        operator: data.operatorName,
        serviceName: data.serviceName,
        hasServiceFee: data.hasServiceFee,
        hasFuelCharge: data.hasFuelCharge,
        serviceFeeList: data.serviceFeeList,
        serviceType: data.serviceType,
        boardingDate:
          data.serviceType === "airport" && queryStringObj.departureDate
            ? queryStringObj.departureDate
            : "",
        boardingStartTime:
          data.serviceType === "airport" && queryStringObj.boardingTime
            ? getBoardingTime(queryStringObj.boardingTime, 0)
            : "",
        boardingEndTime:
          data.serviceType === "airport" && queryStringObj.boardingTime
            ? getBoardingTime(queryStringObj.boardingTime, 1)
            : "",
        sod: data.sod,
        issuedBy: operatorCode,
      },
    ];
  };

  handleTicketCount = (e) => {
    const { ticketCount } = this.state;
    const { id, value } = e.target;
    const regexp = /^[0-9\b]+$/;
    if((parseInt(value) < 51 && regexp.test(value)  && !isNaN(parseInt(value))) || isEmpty(value) ) {
      ticketCount[id] = value;
      this.setState({
        ticketCount
      });
    }
  };

  handleTicketCountRound = (e, index) => {
    const { ticketCountRound } = this.state;
    ticketCountRound[index] = e.target.value;
    this.setState({
      ticketCountRound,
    });
  };

  handleTicketCountMulti = (e, index) => {
    const { ticketCountMulti } = this.state;
    ticketCountMulti[index] = e.target.value;
    this.setState({
      ticketCountMulti,
    });
  };

  handlePurchase = () => {
    this.props.history.push("./checkout");
  };

  handleNavTab = (eventKey) => {
    if (eventKey === "2") {
      heading = "See Round-Trip Fare Rules";
      return this.setState({ tabSelected: "roundTrip" });
    } else if (eventKey === "3") {
      heading = "See Multi-Ride Fare Rules";
      return this.setState({ tabSelected: "multiTrip" });
    }
    heading = "See One-Way Fare Rules";
    return this.setState({ tabSelected: "oneWay" });
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  gotoHome = () => {
    this.props.history.push("./home");
  };

  ticketTypeName = (row) => {
    let ticketType = row.ticketType;
    ticketType = _.includes(ticketType, "MONTHLY")
      ? getMonthlyTicketType(ticketType)
      : ticketType;
    return ticketType;
  };

  handleInputAddRemove = (data, count) =>{
    let addedCount = 0;
    if(!isNaN(count)) {
      addedCount = count;
    }else {
      this.setState({
        ticketCount: {
          ...this.state.ticketCount,
          [data.id] : 0
        }
      })
    }
    
    if ((addedCount <= 0 && !this.ticketCountInCart[data.id]) || (addedCount === this.ticketCountInCart[data.id] || !this.ticketCountInCart)) {
      return;
    }
	  const key = [this.props.tabSelected + "Cart"];
	  const updatedData = _.reject(this[key], (item) => {
		  return item.passengerTypeName === data.passengerTypeName;
	  });
	  this[key] = updatedData;
	  const alltickets = _.get(this.props.ticketInfo, "ticketitems");
	  const ticketId = _.result(
		_.find(alltickets, function (obj) {
		    return obj.productId === data.id;
		  }),
      "ticketId",
      null
	  );
	  const itemToAddRemove = this.getAddRemoveItemList(
      data,
      ticketId,
      addedCount,
      true
	  );
	  const cartId = _.get(this.props.ticketInfo, "cartId");
	  const userDetails = JSON.parse(localStorage.getItem("cognitoUser"));
	  const decodeToken = userDetails.signInUserSession.accessToken.jwtToken;
	  const finalDecodeToken = jwt_decode(decodeToken);
	  const cognitoTokenValue = _.get(finalDecodeToken, "cognito:groups", {});
	  let cart = {
		cartId: cartId,
		ticketitems: itemToAddRemove,
		email: this.email,
		agentLocationId: localStorage.getItem("agent_Location"),
		isCustomerAgent: !_.isEmpty(cognitoTokenValue) ? true : false,
		cognitoRoleName: !_.isEmpty(cognitoTokenValue)
		  ? "customer_agent"
		  : "agent",
	  };
	  this.props.sendCartInfo(cart);
	  // let ticketInfo = this.props.ticketInfo;
	  const ticket = {
		...this[key],
	  };
	  if (key === "oneWayCart") {
      this.props.setOneWayOrder(updatedData);
      return;
	  }
	  if (key === "roundTripCart") {
      this.props.setRoundTripOrder(ticket);
      return;
	  }
  }

  getTicketCount = () => {
    let ticketCountInCart = {};
      const cartItems = _.get(this.props, "ticketInfo.ticketitems", []);
      if (cartItems.length) {
        cartItems.forEach((ticket) => {
          ticketCountInCart[ticket.productId] = ticket.quantity;
        });
      }
      this.setState({
        ticketCount: ticketCountInCart
      });
    
  }

  componentDidUpdate(prevProps) {
    if(prevProps.ticketInfo.ticketitems !== this.props.ticketInfo.ticketitems){
      this.getTicketCount();
    }
  }

  render() {
    const passengerLabeling = MapKeys;
    const ticketsInCart = _.get(this.props, "ticketInfo.ticketitems", []);

    let totalServiceFeeList = [];
    const newMappedData = _.map(this.props.data, (item, i) => {
      let newValues = passengerLabeling["HTL"];
      let passName = newValues[item.passengerTypeName];
      return {
        ...passName,
        ...item,
        ticketsInCart: ticketsInCart,
      };
    });
    const [oneWayFare, roundTripFare] = _.partition(newMappedData, [
      "ticketTypeName",
      "ONE-WAY",
    ]);
    let apiMultiRideData = _.get(this.props, "multiRide", []);
    apiMultiRideData = _.map(apiMultiRideData, (item) => {
      return {
        ...item,
        computedFare: parseFloat(item.computedFare),
      };
    });
    this.email = _.get(this.props.authUser.attributes, "email");
    if (!this.email) {
      this.email = _.get(
        this.props,
        "currentAuthSession.idToken.payload.email",
        null
      );
    }
    let multiRide = _.orderBy(apiMultiRideData, ["computedFare"], ["asc"]);
    let totalFare = _.get(this.props, "ticketInfo.totalFare", 0);
    const totalTickets = _.get(this.props, "ticketInfo.cart_total_tickets", 0);
    const isPurchaseBtnActive =
      !this.props.isCartFill && !_.isEmpty(ticketsInCart);
    let originState = _.get(this.props, "data[0].originState", "");
    let originCity = _.get(this.props, "data[0].originCityName", "");
    let destinationCity = _.get(this.props, "data[0].destinationCityName", "");
    let destinationState = _.get(this.props, "data[0].destinationState", "");
    let originStateMulti = _.get(this.props, "multiRide[0].originState", "");
    let originCityMulti = _.get(this.props, "multiRide[0].originCityName", "");
    let destinationCityMulti = _.get(
      this.props,
      "multiRide[0].destinationCityName",
      ""
    );
    let destinationStateMulti = _.get(
      this.props,
      "multiRide[0].destinationState",
      ""
    );
    let origin = `${!_.isEmpty(originCity) ? originCity : originCityMulti}, ${
      !_.isEmpty(originState) ? originState : originStateMulti
    }`;
    let destination = `${
      !_.isEmpty(destinationCity) ? destinationCity : destinationCityMulti
    }, ${
      !_.isEmpty(destinationState) ? destinationState : destinationStateMulti
    }`;
    let sortedData = {};
    let sodList = [];
    let ticketCountInCart = {};
    const cartItems = _.get(this.props, "ticketInfo.ticketitems", []);
    const subTotal = sum(
      cartItems.map((ci) => parseFloat(ci.fare) * parseInt(ci.quantity))
    );
    if (cartItems.length) {
      cartItems.forEach((ticket) => {
        let sod = ticket.sod;
        if (!sortedData[sod]) {
          sortedData[sod] = [];
          sodList.push(ticket);
        }
        sortedData[sod].push(ticket);
        ticketCountInCart[ticket.productId] = ticket.quantity;
        if (!isEmpty(ticket.serviceFeeList)) {
          totalServiceFeeList = totalServiceFeeList.concat(
            ticket.serviceFeeList.map((fee) => {
              fee.quantity = ticket.quantity;
              return fee;
            })
          );
        }
      });
    }
    this.ticketCountInCart = ticketCountInCart;
    let tabSelected = this.state.tabSelected;
    totalServiceFeeList = groupBy(totalServiceFeeList, "displayName");

    // const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    // const date = new Date();
    return (
      <Theme>
        <div className="ticketfare">
          {this.props.isLoading ? (
            <Loader />
          ) : (
            <div className="container">
              <div className="ticket-fare">
                <Row>
                  <Col>
                    <h2>Ticket Fares</h2>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={8}>
                    <Card>
                      <Card.Body style={{ paddingTop: 7 }}>
                        <Row>
                          <Col md={6} xs={12} style={{ paddingLeft: "7px" }}>
                            <div className="fare-origin">
                              <div className="origin mobile-label">Origin</div>
                              <div className="origin-label">{origin}</div>
                            </div>
                          </Col>
                          <Col md={6} xs={12}>
                            <div className="fares-destination">
                              <div className="destination mobile-label">
                                Destination
                              </div>
                              <div className="destination-label">
                                {destination}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12} xs={12}>
                            <Nav
                              fill
                              variant="tabs"
                              onSelect={this.handleNavTab}
                            >
                              <Nav.Item className="first-tab">
                                <Nav.Link
                                  eventKey="1"
                                  className={
                                    tabSelected === "oneWay" ? "active" : null
                                  }
                                >
                                  ONE-WAY
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="2"
                                  className={
                                    tabSelected === "roundTrip"
                                      ? "active"
                                      : null
                                  }
                                >
                                  ROUND-TRIP
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="last-tab">
                                <Nav.Link
                                  eventKey="3"
                                  className={
                                    tabSelected === "multiTrip"
                                      ? "active"
                                      : null
                                  }
                                >
                                  MULTI-RIDE
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table className="table ticket-fare-table">
                              <tbody>
                                {/* <tr>
																		<th colSpan="3">
																		<span>
																																				{!_.isEmpty(oneWayFare) ?  this.renderTicketFareRule(): null }
																																	</span>
																		</th>
																	</tr>	 */}
                                {tabSelected === "oneWay" && (
                                  <TicketFareTab
                                    fare={oneWayFare}
                                    operatorCode={this.getOperatorCode()}
                                    ticketFareRuleLabels={
                                      this.props.ticketFareRuleLabels
                                    }
                                    tabSelected="oneWay"
                                    countList={ticketCountInCart}
                                    handleTicketCount={this.handleTicketCount}
                                    handleSelectAddMethod={(data, index) =>
                                      this.handleAddRemoveTicketFromCart(
                                        data,
                                        1
                                      )
                                    }
                                    handleSelectRemoveMethod={(data, index) =>
                                      this.handleAddRemoveTicketFromCart(
                                        data,
                                        -1
                                      )
                                    }
                                    proxyAssetEndpoint={APP_ASSETS_FOLDER}
                                    ticketCount={this.state.ticketCount}
                                    getAddRemoveItemList={
                                      this.getAddRemoveItemList
                                    }
									                  handleInputAddRemove={this.handleInputAddRemove}
                                  />
                                )}
                                {tabSelected === "roundTrip" && (
                                  <TicketFareTab
                                    fare={roundTripFare}
                                    isLoading={this.props.isLoading}
                                    operatorCode={this.getOperatorCode()}
                                    ticketFareRuleLabels={
                                      this.props.ticketFareRuleLabels
                                    }
                                    tabSelected="roundTrip"
                                    countList={ticketCountInCart}
                                    handleSelectAddMethod={(data, index) =>
                                      this.handleAddRemoveTicketFromCart(
                                        data,
                                        1
                                      )
                                    }
                                    handleSelectRemoveMethod={(data, index) =>
                                      this.handleAddRemoveTicketFromCart(
                                        data,
                                        -1
                                      )
                                    }
                                    proxyAssetEndpoint={APP_ASSETS_FOLDER}
                                    ticketCount={this.state.ticketCount}
                                    getAddRemoveItemList={
                                      this.getAddRemoveItemList
                                    }
									                  handleInputAddRemove={this.handleInputAddRemove}
                                    handleTicketCount={this.handleTicketCount}
                                  />
                                )}
                                {tabSelected === "multiTrip" && (
                                  <TicketFareTab
                                    fare={multiRide}
                                    isLoading={this.props.isLoading}
                                    operatorCode={this.getOperatorCode()}
                                    ticketFareRuleLabels={
                                      this.props.ticketFareRuleLabels
                                    }
                                    tabSelected="multiTrip"
                                    countList={ticketCountInCart}
                                    handleSelectAddMethod={(data, index) =>
                                      this.handleAddRemoveTicketFromCart(
                                        data,
                                        1
                                      )
                                    }
                                    handleSelectRemoveMethod={(data, index) =>
                                      this.handleAddRemoveTicketFromCart(
                                        data,
                                        -1
                                      )
                                    }
                                    proxyAssetEndpoint={APP_ASSETS_FOLDER}
                                    ticketCount={this.state.ticketCount}
                                    getAddRemoveItemList={
                                      this.getAddRemoveItemList
                                    }
									                  handleInputAddRemove={this.handleInputAddRemove}
                                    handleTicketCount={this.handleTicketCount}
                                  />
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Button
                      name="btnBack"
                      type="submit"
                      className="btn-secondary"
                      style={{
                        float: "right",
                        width: "186px",
                        marginTop: "15px",
                      }}
                      onClick={this.handleBack}
                    >
                      GO BACK
                    </Button>

                    {this.props.isCartFill && <Loader />}
                  </Col>

                  <Col sm={12} xs={12} md={12} lg={4}>
                    <Card className="order-summary-box order-card">
                      <div className="order-summary">
                        <Row>
                          {_.isEmpty(sodList) ? (
                            <Col md={12} xs={12}>
                              <h3>Shopping Cart</h3>
                            </Col>
                          ) : (
                            <Col md={12} xs={12} className="pl-3">
                              <div className="d-flex justify-content-center justify-content-between">
                                <h4>Order Summary</h4>
                                <Link
                                  className="view-cart-icon"
                                  to={`${APP_PROXY_FOLDER}shoppingcart`}
                                >
                                  View&nbsp;Cart&nbsp;<span>&gt;</span>
                                </Link>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                      {_.isEmpty(sodList) ? (
                        <div className="details">
                          <Row>
                            <Col md={12} xs={12}>
                              <span
                                className="origin-name"
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                  lineHeight: "22px",
                                }}
                              >
                                [SHOPPING CART IS EMPTY]
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        sodList.map((sodObj, index) => {
                          return (
                            <div key={`sod-${index}`}>
                              {index > 0 ? (
                                <div className="breaker mb-3" />
                              ) : null}
                              <div
                                className="details"
                                style={{
                                  minHeight:
                                    _.keys(sortedData).length > 1
                                      ? "50px"
                                      : "120px",
                                }}
                              >
                                <div>
                                  <div className="desktop-view-sod">
                                    <DesktopView sodObj={sodObj} />
                                  </div>
                                </div>
                                {_.map(sortedData[sodObj.sod], (cart, i) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                      key={`order-listing-${i}`}
                                    >
                                      <ul
                                        className="row-box order-listing shopping-cart-admin-free"
                                        key={`shopping-cart-${i}`}
                                      >
                                        <li>
                                          <span className="num-of-ticket">
                                            {" "}
                                            {cart.quantity}{" "}
                                          </span>
                                          {!isEmpty(cart.serviceFeeList) &&
                                            map(cart.serviceFeeList, (fee) => {
                                            return <span className="d-block text-center" id={fee.displayName}>
                                              {cart.quantity}
                                             </span>
                                          })
                                          }
                                          
                                        </li>
                                        <li>
                                          <span className="one_way">
                                            {this.ticketTypeName(cart)}
                                            {/* {cart.ticketType} */}
                                          </span>
                                          {!isEmpty(cart.serviceFeeList) &&
                                            map(cart.serviceFeeList, (fee) => {
                                            return (<span className="d-block" id={fee.displayName}>
                                              {_.get(fee, "displayName")}(s)
                                             </span>)
                                          })}
                                          
                                        </li>
                                        <li>
                                          <span className="pasngr-type d-block">
                                            {" "}
                                            {cart.passengerType}{" "}
                                          </span>
                                          
                                          {!isEmpty(cart.serviceFeeList) &&
                                            map(cart.serviceFeeList, (fee) => {
                                              return <span className="d-block pasngr-type pl-3" id={fee.displayName}>
                                              -
                                             </span>
                                            })}
                                          
                                          
                                        </li>
                                        <li className="text-right">
                                          <span className="price">
                                            {" "}
                                            $
                                            {Number(
                                              parseFloat(
                                                cart.fare * cart.quantity
                                              ).toFixed(2)
                                            ).toLocaleString(
                                              navigator.language,
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}{" "}
                                          </span>
                                          {!isEmpty(cart.serviceFeeList) &&
                                            <>
                                            {map(cart.serviceFeeList, (fee) => {
                                              return <span className="d-block admin_fee_price">
                                                  $
                                                    {parseFloat(
                                                      _.get(fee, "amount") * cart.quantity
                                                    ).toFixed(2)}{" "}
                                             </span>
                                              })}
                                            
                                             
                                            </>
                                          }
                                        </li>
                                      </ul>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      )}
                      <Card.Footer className="amount">
                        {Object.keys(totalServiceFeeList).length && subTotal ? (
                          <Row>
                            <Col md={6} xs={6}>
                              <span className="final-admin-fee-label">
                                Subtotal
                              </span>
                            </Col>
                            <Col md={6} xs={6} className="text-right">
                              <span className="final-admin-fee-price">
                                ${subTotal.toFixed(2)}{" "}
                              </span>
                            </Col>
                          </Row>
                        ) : null}
                        {Object.keys(totalServiceFeeList).map((feeName) => {
                          return (
                            <Row>
                              <Col md={6} xs={6}>
                                <span className="final-admin-fee-label">
                                  {feeName}(s)
                                </span>
                              </Col>
                              <Col md={6} xs={6} className="text-right">
                                <span className="final-admin-fee-price">
                                  $
                                  {sum(
                                    totalServiceFeeList[feeName].map(
                                      (f) =>
                                        parseFloat(f.amount) *
                                        parseInt(f.quantity)
                                    )
                                  ).toFixed(2)}{" "}
                                </span>
                              </Col>
                            </Row>
                          );
                        })}
                        <Row>
                          <Col md={6} xs={6}>
                            Amount Due
                          </Col>
                          <Col md={6} xs={6} className="text-right">
                            <span>
                              $
                              {Number(
                                parseFloat(totalFare).toFixed(2)
                              ).toLocaleString(navigator.language, {
                                minimumFractionDigits: 2,
                              })}
                            </span>
                          </Col>
                        </Row>
                      </Card.Footer>
                      <Row className="button text-center">
                        <Col md={12} xs={12} className="padding0">
                          <div>
                            <Button
                              className="btn"
                              onClick={this.handlePurchase}
                              disabled={isPurchaseBtnActive ? false : true}
                              style={{
                                marginBottom: "10px",
                                width: "100%",
                                opacity: "1",
                              }}
                            >
                              BUY NOW
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      {parseInt(totalTickets) > 0 ? (
                        <Row className="button text-center">
                          <Col md={12} xs={12} className="padding0">
                            <div>
                              <Button
                                className="btn-secondary"
                                style={{
                                  marginBottom: "10px",
                                  width: "100%",
                                  opacity: "1",
                                }}
                                onClick={this.gotoHome}
                              >
                                KEEP SHOPPING
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
      </Theme>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.busFares.data,
  ticketInfo: state.busFares.ticketInfo,
  oneWayOrder: state.busFares.oneWayOrder,
  roundTripOrder: state.busFares.roundTripOrder,
  isLoading: state.busFares.isLoading,
  tokenAccepted: state.checkout.tokenAccepted,
  isCartFill: state.busFares.isCartFill,
  multiRide: state.busFares.multiRide,
  viewCartItems: state.appReducer.viewCartItems,
  authUser: state.authReducer.authUser,
  currentAuthSession: state.authReducer.currentAuthSession,
  oneWayCountObj: state.busFares.oneWayCountObj,
  roundWayCountObj: state.busFares.roundWayCountObj,
  multiWayCountObj: state.busFares.multiWayCountObj,
  translationItems: state.appReducer.translationItems,
  ticketFareRuleLabels: state.ticketFareRuleReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    fetchBusFares: (od) => dispatch(busFaresActions.fetchBusFares(od)),
    sendCartInfo: (cart) => dispatch(busFaresActions.sendCartInfo(cart)),
    setOneWayOrder: (oneWayticket) =>
      dispatch(busFaresActions.setOneWayOrder(oneWayticket)),
    setRoundTripOrder: (rouneTripTicket) =>
      dispatch(busFaresActions.setRoundTripOrder(rouneTripTicket)),
    updateOneWayCount: (count) =>
      dispatch(busFaresActions.updateOneWayCount(count)),
    updateRoundWayCount: (count) =>
      dispatch(busFaresActions.updateRoundWayCount(count)),
    updateMultiWayCount: (count) =>
      dispatch(busFaresActions.updateMultiWayCount(count)),
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BusFares);
