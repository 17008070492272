import React from 'react';
import * as QueryString from 'query-string';
import Api from "../../../utils/api/api";
import _, { get, isEmpty, map } from "lodash";
import AdminFeeDetails from "../../../utils/serviceFeeDetails/AdminFeeDetails";
import FuelSurchargeFee from "../../../utils/serviceFeeDetails/FuelSurchargeFee";
class PrintReceipt extends React.Component {

  state = {
    payments: [],
    agentID: '',
    order_id: ''
  };

  componentDidMount = async () => {
    const params = QueryString.parse(this.props.location.search);
    const order_id = params.order_id;
    const ticketResponse = await Api.fetch(
      `${process.env.REACT_APP_JOURNAL_ORDER_TICKETS_API_ENDPOINT}${order_id}`
    );
    const payments = get(
      ticketResponse,
      "data.orbitResponse.body.orderDetail.paymentDetail",
      []
    );
    let cognitoUser = localStorage.getItem('cognitoUser');
    let agentID = '';
    if (cognitoUser) {
      const agentData = JSON.parse(cognitoUser);
      if (typeof agentData.username !== 'undefined') {
        agentID = agentData.username;
      }
    }
    this.setState({payments, agentID, order_id});
  };

  render () {
    const ticketProductIds = [];
    const ticketCounts = [];
    const ticketSummary = [];
    
    let orderTotal = 0;
    let adminFee = 0;
    let fuelFee = 0;
    for (let c = 0; c < this.props.tickets.length; c++) {
      orderTotal += parseFloat(this.props.tickets[c].ticket_fare);
      adminFee += !isEmpty(AdminFeeDetails(this.props.tickets[c].ticket_service_fee_detail)) ? get(AdminFeeDetails(this.props.tickets[c].ticket_service_fee_detail), "amount"): 0;
      fuelFee += !isEmpty(FuelSurchargeFee(this.props.tickets[c].ticket_service_fee_detail)) ? get(FuelSurchargeFee(this.props.tickets[c].ticket_service_fee_detail), "amount") : 0;
      if (typeof ticketCounts[this.props.tickets[c].product_id] === 'undefined') {
        ticketCounts[this.props.tickets[c].product_id] = 1;
        ticketProductIds[ticketProductIds.length] = this.props.tickets[c].product_id;
        ticketSummary[this.props.tickets[c].product_id] = Object.assign({}, this.props.tickets[c]);
      } else {
        ticketCounts[this.props.tickets[c].product_id]++;
      }
    }
    const amount_due = '$' + (orderTotal + adminFee+ fuelFee).toFixed(2);
    const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    const date = new Date();
    const nextMonth = new Date().getMonth() + 1 === 12 ? 0 : new Date().getMonth() + 1;
    const time_zone = date.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const date_of_sale = date.getDate() + ' ' + months[parseInt(date.getMonth())] + ' ' + date.getFullYear();
    const trans_date = date.getUTCFullYear() + '-' +
      ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
      ('00' + date.getUTCDate()).slice(-2);
    const trans_time = ('00' + date.getHours()).slice(-2) + ':' +
      ('00' + date.getMinutes()).slice(-2) + ' ' + time_zone;
    let pageNum = 0;
    let pageTotal = Math.ceil(ticketProductIds.length / 5);
    let ticketCountOnPage = 0;
    let showCoupon = false;
    for (let c = 0; c < this.props.tickets.length; c++) {
      if (this.props.tickets[c].sod === 'NYCP|WDBC') showCoupon = true;
    }
  
    let serviceFee = [];
    return (
      <div style={{ position: 'relative' }} id="print-wrapper">
            <div className="printed-text content" style={{width: '100%'}}>
              <h2 className="printed-text" style={{fontSize: '16px', textAlign: 'right', fontWeight: 'bold', color: '#000', margin: '0', paddingTop: '20px'}}>
                RECEIPT
              </h2>
              <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'right', color: '#000', margin: '2px 0'}}>
                THIS IS NOT A TICKET
              </h2>
            </div>
          <hr style={{margin: '0'}} />
          <div className="printed-text" style={{width: '100%', height: '10px'}} />
          {ticketProductIds.map((data, index) => {
            let pageBreak = <div/>;
            let pageHeader = (<div/>);
            if ((parseInt(index / 5) === (index / 5)) && (index !== 0)) {
              ticketCountOnPage = 0;
              pageNum++;
              pageBreak = (
                <div>
                  <div className="printed-text" style={{ textAlign: 'right', fontSize: '8px' }}>Page {pageNum}/ {pageTotal}</div>
                  <div className="page-break clearfix" />
                </div>
              );
              pageHeader = (
                <div>
                  <div className="printed-text" style={{width: '100%', height: 'auto'}} />
                  <div className="printed-text">
                    <div className="printed-text content" style={{width: '100%'}}>
                      <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'right', fontWeight: 'bold', color: '#000', margin: '0', paddingTop: '20px'}}>
                        RECEIPT
                      </h2>
                      <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'right', color: '#000', margin: '6px 0'}}>
                        THIS IS NOT A TICKET
                      </h2>
                    </div>
                  </div>
                  <hr style={{margin: '0'}} />
                </div>
              );
              if ((ticketProductIds.length - index) < 5) {
                pageNum++;
              }
            } else {
              ticketCountOnPage++;
            }
            if (!_.isEmpty(ticketSummary[ticketProductIds[index]].ticket_service_fee_detail)) {
              serviceFee = this.props.tickets[index].ticket_service_fee_detail ? map(this.props.tickets[index].ticket_service_fee_detail, (fee) => {
                return fee
              }) : [];
              
            }
            return (
              <div key={`receipt-${index}`}>
                {pageBreak}
                {pageHeader}
                <table>
                  <tbody>
                  <tr style={{ fontSize: '9px', paddingBottom:'10px'}}>
                    <td className="pb-print-5 pt-print-5">
                      <table cellSpacing="0" cellPadding="0">
                        <tbody>
                        <tr className="pb-print-5 pt-print-5">
                              <td valign="top"><div className="uppercase" style={{
                                fontWeight: "900", paddingBottom: "2px", width: '130px'
                          }}
                            >
                            {_.truncate(ticketSummary[ticketProductIds[index]].origin_city_name, { 'length': 20 })},
                                {ticketSummary[ticketProductIds[index]].origin_state}
                          </div>
                          </td>
                          <td valign="top" className="text-right">
                            {ticketSummary[ticketProductIds[index]].passenger_type}, 
                                {ticketSummary[ticketProductIds[index]].ticket_type.replace('ONE-WAY', 'O/W').replace('ROUND-TRIP', 'R/T').replace(/MONTHLY-PASS-N|MONTHLY-PASS/,
                              'MTH')}{ticketSummary[ticketProductIds[index]].ticket_type === 'MONTHLY-PASS' ? ' - ' + months[parseInt(date.getMonth())] :
                                ticketSummary[ticketProductIds[index]].ticket_type === 'MONTHLY-PASS-N' ? ' - ' + months[parseInt(nextMonth)] : null}
                          </td>
                        </tr>
                        <tr className="pb-print-5 pt-print-5">
                              <td valign="top"><div className="uppercase" style={{ width: '135px', fontWeight:'900'}}>
                            {_.truncate(ticketSummary[ticketProductIds[index]].destination_city_name, { 'length': 20 })},
                                    {ticketSummary[ticketProductIds[index]].destination_state}
                          </div>
                          </td>
                          <td valign="top" className="text-right">QTY {ticketCounts[ticketProductIds[index]]}</td>
                        </tr>
                        <tr className="pb-print-5 pt-print-5">
                          <td valign="top">
                              <table cellSpacing="0" cellPadding="0">
                                <tbody>
                              <tr>
                                      <td valign="top">
                                        <div style={{ width: '70px' }}>ISSUING OPR:</div>
                                        </td>
                                <td valign="top"><div className="uppercase"
                                        style={{ fontWeight: '900', paddingBottom: '2px', width: '60px'}}>{!_.isEmpty(ticketSummary[ticketProductIds[index]].operator_name) ?
                                    _.truncate(ticketSummary[ticketProductIds[index]].operator_name, { 'length': 10 }) :
                                    ticketSummary[ticketProductIds[index]].issued_by}</div>
                                </td>
                              </tr>
                                </tbody>
                            </table>
                          </td>
                          <td valign="top" className="bold text-right" style={{ fontWeight: '900'}}>
                            ${parseFloat(ticketSummary[ticketProductIds[index]].ticket_fare).toFixed(2)}
                          </td>
                        </tr>
                        {serviceFee.map((row,i) => {
                          return (
                            <tr key={i} style={{fontSize: '7px'}}>
                              <td valign="top" style={{ paddingBottom: '1px' }}>{row.display_name}(s)</td>
                              <td valign="top paddingBottom: 1px;" className="text-right">
                                ${(parseFloat(row.amount) * ticketCounts[ticketProductIds[index]]).toFixed(2)}
                              </td>
                            </tr>)
                        })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            )})}
        <table>
          <tbody>
          <tr>
            <td>
                <table cellSpacing="0" cellPadding="0">
                  <tbody>
                <tr>
                  <td style={{ paddingTop: '8px', paddingBottom: '7px'}} valign="top">Amount Due:</td>
                  <td style={{paddingTop:'8px', paddingBottom:'7p',fontWeight: '900'}} valign="top"
                    className="text-right bold">{amount_due}</td>
                    </tr>
                  </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              {this.state.payments.map((data, index) => {
                return (
                  <table key={`receipt-${index}`} cellSpacing="0" cellPadding="0">
                    <tbody>
                    <tr>
                      <td valign="top" style={{ paddingBottom: '3px' }}>
                        {(index === 0) ? 'Payment Method: ' : null} ({data.paymentMethod})
                            </td>
                      <td valign="top" style={{paddingBottom: '3px', fontWeight: '900'}} className="text-right bold">
                        ${parseFloat(data.amount).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: '3px', paddingBottom: '0px', fontSize: '7px' }} valign="top">
                        Admin Fee(s)
                            </td>
                      <td style={{paddingTop:'3px', paddingBottom:'0px', fontWeight: '900'}} valign="top"
                        className="text-right bold">${parseFloat(adminFee).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td style={{ paddingTop: '3px', paddingBottom: '0px', fontSize: '7px' }}valign="top">Fuel
                      Surcharge(s)
                            </td>
                      <td style={{ paddingTop: '3px', paddingBottom: '0px', fontWeight: '900' }} valign="top"
                        className="text-right bold">${parseFloat(fuelFee).toFixed(2)}</td>
                    </tr>
                    </tbody>
                  </table>
                );
              })}
            </td>
          </tr>
          <tr>
            <td className="pb-print-5" style={{ paddingTop: '10px !important' }}> ORDER
            NO:<br/>
              <span className="uppercase"
                style={{ fontWeight: '900'}}>{this.state.order_id}</span>
                </td>
          </tr>
          <tr>
            <td className="pb-print-5"> TRANSACTION DATE: <span style={{ fontWeight: '900'}}>{trans_date}</span> </td>
          </tr>
          <tr>
            <td className="pb-print-5"> TRANSACTION TIME: <span style={{ fontWeight: '900' }}>{trans_time}</span>
            </td>
          </tr>
          <tr>
            <td className="pb-print-5"> ORDER STATUS: <span style={{ fontWeight: '900' }}> PAID</span> </td>
          </tr>
       </tbody>
        </table>
        <table style={{ paddingTop: '10px', width: '100%' }}>
          <tbody>
          <tr style={{ paddingTop: '15px' }}>
            <td className="pb-print-5">ID: test{this.state.agentID}</td>
              <td className="pb-print-5 text-right">Page {pageNum === 0 ? 1:pageNum}/{pageTotal}</td>
          </tr>
          </tbody>
        </table>
        </div>
     
    );
  }
}

export default PrintReceipt;