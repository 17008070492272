import React from "react";

const SVG = () =>
  <svg
    fill={"#318ed5"}
    width="23"
    height="12.503"
    viewBox="0 0 23 12.503"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g transform="translate(-4.5 -10)">
      <path
      className="a"
      d="M27.41,15.71a13.9,13.9,0,0,0-22.82,0,.51.51,0,0,0,0,.58,13.9,13.9,0,0,0,22.82,0,.51.51,0,0,0,0-.58ZM16,20a4,4,0,1,1,4-4A4,4,0,0,1,16,20Z"/>
    </g>

  </svg>;

export default SVG;
