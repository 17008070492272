const initialState = {
  viewTicketData: {},
  ticketError:{},
  validateReponse: {},
  isLoading: false,
  showSpinner: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "EMAIL_VALIDATE_START":
      return {
        ...state,
        isLoading: action.payload
      };
    case "EMAIL_VALIDATE_ERROR":
      return {
        ...state,
        isLoading: action.payload
      };
    case "EMAIL_VALIDATED":
      return {
        ...state,
        validateReponse: action.payload,
        isLoading:false
      };
    case "FETCH_TICKETS_START":
      return {
        ...state,
        data: true
      };
    case "FETCH_TICKETS_COMPLETE":
      return {
        ...state,
        viewTicketData: action.payload.orbitResponse.body.ticketList ? action.payload.orbitResponse.body.ticketList: action.payload.orbitResponse.body,
        orderDetails: action.payload.orbitResponse.body.orderDetail ? action.payload.orbitResponse.body.orderDetail: null
      };
    case "FETCH_TICKETS_FAILED":
      return {
        ...state,
        ticketError: action.payload
      };
    case "FETCH_TICKETS_STOP":
      return {
        ...state,
        data: false
      };
    case 'CLEAR_TICKET_DATA': 
      return {
        ...state,
        viewTicketData: {}
    };
    default:
      return state;
  }
};
