import appConstants from '../../constants/appConstants';

const { UPDATE_TICKET_FARE_RULE_DATA, TICKET_FARE_RULE_FETCH_STATUS } = appConstants;

const initialState = {
	data: {},
	isRequesting: true,
	// defaultData: {
	// 	// these default values are based on the ticket ->  https://coachusa.atlassian.net/browse/RBT-3820
	// 	busRules: {
	// 		'ONE-WAY': 'One-way tickets are good for travel ninety (90) days in addition to the date of sale.',
	// 		'ROUND-TRIP':
	// 			'Round-trip tickets are good for travel one hundred and twenty (120) days in addition to the date of sale, unless otherwise specified.',
	// 		'MULTI-RIDE':
	// 			'Eight(8) One - Way trips will be limited for use to thirty(30) days from and including the date of sale by one individual. Ten(10) One - Way trips will be limited for use to twenty(20) days from and including the date of sale by one individual. Twenty(20) One - Way trips will be limited for use to sixty(60) days from and including the date of sale by one individual. Forty(40) One - Way trips will be limited for use to forty(40) days from and including the date of sale by one individual. Fifty(50) One - Way trips will be limited for use to ninety(90) days from and including the date of sale by one individual.'
	// 	},
	// 	// these default values are based on the ticket -> https://coachusa.atlassian.net/browse/RBT-4057
	// 	airportRules: {
	// 		'ONE-WAY': 'One-way tickets are good for travel one (1) Year in addition to the date of sale.',
	// 		'ROUND-TRIP':
	// 			'Round-trip tickets are good for travel one (1) Year in addition to the date of sale, unless otherwise specified.',
	// 		'MULTI-RIDE':
	// 			'Ten(10) One - Way trips will be limited for use to one (1) Year from and including the date of sale by one individual.'
	// 	},
	// 	categoryLabels: {
	// 		ADULT: {
	// 			ageGroup: '18 and up',
	// 			idRequired: false
	// 		},
	// 		CHILD: {
	// 			ageGroup: '5 to 17',
	// 			idRequired: false
	// 		},
	// 		SENIOR: {
	// 			ageGroup: '65 and up',
	// 			idRequired: true
	// 		},
	// 		STUDENT: {
	// 			ageGroup: 'With College ID',
	// 			idRequired: true
	// 		},
	// 		DISABLED: {
	// 			ageGroup: '',
	// 			idRequired: true
	// 		}
	// 	},
	// 	airportCategoryLabels: {
	// 		ADULT: {
	// 			ageGroup: '17 years old and over',
	// 		},
	// 		CHILD: {
	// 			ageGroup: '0-16 years old',
	// 		},
	// 		SENIOR: {
	// 			ageGroup: 'Valid State ID Required',
	// 		},
	// 		STUDENT: {
	// 			ageGroup: '',
	// 		},
	// 		DISABLED: {
	// 			ageGroup: '',
	// 		}
	// 	}
	// }
};

export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_TICKET_FARE_RULE_DATA:
			return {
				...state,
				data: action.payload.data,
				defaultData: action.payload.defaultData,
				isRequesting: false
			};
		case TICKET_FARE_RULE_FETCH_STATUS:
			return {
				...state,
				isRequesting: false
			};
		default:
			return state;
	}
};
