import React, {Fragment} from 'react';
import {Button} from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as QueryString from 'query-string';
import generateTicketsActions from '../../../actions/generateTickets/generateTickets';
import Loader from '../../Loader/Loader';
import TicketContainer from '../TicketContainer/TicketContainer';
import PrintAllTickets from '../PrintTickets/PrintAllTickets';
import PrintReceipt from '../PrintTickets/PrintReceipt';
import ModalDialog from '../../ModalDialog/ModalDialog';
import errMsgConfig from '../../../utils/errorMsgConfig/errorMsgConfig';
import walletActions from '../../../utils/walletAPI/wallet';
import Theme from '../../Theme/index';
import PDFPrint from "../PDFPrint/PDFPrint";


class GenerateTickets extends React.Component {
	constructor (props) {
		super(props);
		const params = QueryString.parse(props.location.search);
		if (!_.isNil(params.order_id)) {
			this.props.fetchTicketsWithBarcode(params.order_id);
		}
	}

	state = {
		currentTicket: [],
		currentPage: 1,
		endPage: 1,
		paginate: null,
		totalPages: null,
		checked: false,
		checkedAll: false,
		showSpinner: false,
		printAll: true,
		open: false,
	};

	componentDidUpdate (prevProps) {
		if (prevProps.ticketError !== this.props.ticketError) {
			this.toggleModal();
		}
	}

	componentWillUnmount() {
		this.props.clear()
	}

	toggleModal = () => this.setState((state) => ({ open: !state.open }));

	onBtnOk = () => {
		this.toggleModal();
		this.props.history.push('/expresscart');
	};

	shouldComponentUpdate (nextProps, nextState) {
		if (_.isEmpty(nextProps.Items)) {
			return true;
		} else {
			return false;
		}
	}

	onChangePage = (currentTicket, currentPage, endPage) => {
		this.setState({ currentTicket, currentPage, endPage });
	};

	togglePrintCheckbox = (event) => {
		this.setState({ printAll: false });
	};

	togglePrintAllCheckbox = (event) => {
		this.setState({ printAll: true });
	};

	handleChange = (e) => {};

	newSearch = () => {
		this.props.history.push('./home');
	};

	render () {
		let allTickets = [];
		const { currentTicket } = this.state;
		allTickets = _.get(this.props.viewTicketData, 'Items', []);
		const params = QueryString.parse(this.props.location.search);
		if (params.product_id) {
			allTickets = _.filter(allTickets, (ticket) => ticket.product_id === params.product_id);
		}
		const totalTickets = allTickets ? allTickets.length : 0;

		if (totalTickets === 0)
			return (
				<ModalDialog
					show={!_.isEmpty(this.props.ticketError)}
					name="apiError"
					btnText="OK"
					body={errMsgConfig.failedTicket.body}
					onBtnOk={this.onBtnOk}
				/>
			);

    let order_id = '';
    if (!_.isNil(params.order_id)) {
      order_id = params.order_id;
    }
	const order_id_printed = order_id + '_printed';

  let allTicketsPDF = _.get(
    this.props,
    "orderDetails.agentOrderTicketReceipt",
    ""
  );

		return (
			
			<Theme className="ticket-generate-content postion-relative">
				{this.props.isLoading ? <Loader /> :
						<Fragment>
				{ localStorage.getItem(order_id_printed) ? <div className="center no-print">
							<Button className="btn w260" onClick={this.newSearch}>
								NEW SEARCH
							</Button>
			</div>: null}



    {!_.isEmpty(allTicketsPDF) ? (
            <>
              {!localStorage.getItem(order_id_printed) ? (
                <>
                  <div className="mb-3 text-center no-print">
                    <Button className="btn w260" onClick={this.newSearch}>
                      NEW SEARCH
                    </Button>
                  </div>
                  <PDFPrint file={allTicketsPDF} orderId={order_id} />
                </>
              ) : null}
            </>
          ) : (
          <div className="view-ticket">
            {this.props.isLoading && <Loader />}
            <div style={{position: 'relative'}}>
            
            </div>
            <div className="print-all-tickets">
              {this.state.printAll ? (
                <PrintAllTickets tickets={allTickets} location={this.props.location} />
              ) : (
                <div>
                  {typeof currentTicket[0] !== 'undefined' ? (
                    <div>
                      <div className="main-ticket-area">
                        <TicketContainer key={0} data={currentTicket[0]} printValue='true'/>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="print-all-tickets" style={{marginLeft: '20px'}}>
              {allTickets.length > 0 ? (
                <PrintReceipt tickets={allTickets} location={this.props.location}  />
              ) : null}
            </div>
          </div>
        )}
				</Fragment>
							}
			</Theme>
		);
	}
}

const mapStateToProps = (state) => ({
	viewTicketData: state.generateTickets.viewTicketData,
	isLoading: state.generateTickets.isLoading,
  orderDetails: state.generateTickets.orderDetails,
	validateReponse: state.generateTickets.validateReponse,
	isAuthenticated: state.authReducer.isAuthenticated,
	ticketError: state.generateTickets.ticketError
});

const mapDispatchToProps = (dispatch) => {
	return {
		fetchTicketsWithBarcode: (order) => dispatch(generateTicketsActions.fetchTicketsWithBarcode(order)),
		clear: () => dispatch(generateTicketsActions.clearData()),
		addToAppleWallet: (ticketId) => dispatch(walletActions.addToAppleWallet(ticketId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateTickets);
