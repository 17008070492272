import React from "react";
import { Row, Col } from "react-bootstrap";

const DesktopView = ({ sodObj }) => {
  const originName = `${sodObj.originCity}, ${sodObj.originState}`;
  const destinationName = `${sodObj.destinationCity}, ${sodObj.destinationState}`;
  return (
    <div>
      <Row>
        <Col md={5} xs={5} className="pr-0">
          <h4>Origin</h4>
        </Col>
        <Col md={7} xs={7} className="pr-0">
          <h4>Destination</h4>
        </Col>
      </Row>
      <Row>
        <Col md={5} xs={5} className="pr-0">
          <span className="origin-name text-truncate" title={originName}>
            {originName}
          </span>
        </Col>
        <Col md={7} xs={7} className="pr-0">
          <span
            className="destination-name text-truncate"
            title={destinationName}
          >
            {destinationName}
          </span>
        </Col>
      </Row>
      <div className="ticketdetails">
        <div className="quanity">QTY</div>
        <div className="ticket">Ticket</div>
        <div className="passenger">Passenger</div>
        <div>Amount</div>
      </div>
    </div>
  );
};

export default DesktopView;
