import React from "react";
import { Spinner } from "react-bootstrap";

function Loader(props) {
  return (
    <div className="loader">
     
    <div className="spinner">
    <Spinner animation="border" variant="primary" />
    </div>
  </div>
  );
}

export default Loader;
