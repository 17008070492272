import _ from "lodash";
import apiNew from "../../utils/api/apiNew";
import appConstants from "../../constants/appConstants";

const {
  REQUEST_STOP,
  REQUEST_START,
  PAYMENT_TOKEN_ACCEPTED,
  PAYMENT_TOKEN_ACCEPTED_FAILED,
} = appConstants;

const checkoutActions = {
  orderRequest: (orderRequestData) => dispatch => {
    
    dispatch({
      type: REQUEST_START,
      payload: true
    });

    return apiNew
      .post(
        `${process.env.REACT_APP_ORDER_REQUEST_API_ENDPOINT}`,
        orderRequestData
      )
      .then(
        response => {
          if(response.data.orbitResponse.statusCode !== 500){
          if (typeof response !== "undefined") {
            if (typeof response.data !== "undefined") {
              if (typeof response.data.orbitResponse !== "undefined") {
                if (typeof response.data.orbitResponse.body !== "undefined") {
                  dispatch({
                    type: PAYMENT_TOKEN_ACCEPTED,
                    payload: response
                  });
                  dispatch({
                    type: "REQUEST_STOP",
                    payload: false
                  });
                }
              }
            }
          }
          else {
            dispatch({
              type: PAYMENT_TOKEN_ACCEPTED_FAILED,
              payload: { error: response.data.orbitResponse.body, isUserValidated: false }
            });
            dispatch({
              type: "REQUEST_STOP",
              payload: false
            });
          }
        }
        else{
          dispatch({
            type: PAYMENT_TOKEN_ACCEPTED_FAILED,
            payload: { error: response.data.orbitResponse.body, isUserValidated: false }
          });
          dispatch({
            type: "REQUEST_STOP",
            payload: false
          });
        }

          
          dispatch({
            type: REQUEST_STOP,
            payload: false
          });
        },
        error => {
          let errorResponse = {};
          if (!_.isEmpty(error) && error.request.status < 500) {
            errorResponse = error;
          } else {
            errorResponse = "payment failed";
          }
          dispatch({
            type: PAYMENT_TOKEN_ACCEPTED_FAILED,
            payload: { error: errorResponse, isUserValidated: false}
          });
          dispatch({
            type: "REQUEST_STOP",
            payload: false
          });
        }
      );
  },

};

export default checkoutActions;
