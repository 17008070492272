import apiNew from "../../utils/api/apiNew";
import _,{get} from 'lodash';

const landingActions = {
   
  fetchByLocation: () => async dispatch => {
    try {
    const resApi = await apiNew.fetch(
      `${process.env.REACT_APP_AGENT_LOCATION_ENDPOINT}`
    );
    let emptyData = get(resApi, "data.orbitResponse.body.emptyData");
    if(!emptyData){
      const locations = get(resApi, "data.orbitResponse.body");
      dispatch({
      type: "FETCH_LOCATION_START",
      payload: locations
    });
    return locations;
    } 
    else{
      const locations = get(resApi, "data.orbitResponse.body");
      dispatch({
      type: "FETCH_LOCATION_START",
      payload: locations
    });
    return locations;
    }
}catch(err){
    dispatch({
        type: "FETCH_LOCATION_FAILED",
        payload: err
      });
}
  },
  
//   fetchLocationData: (data) => async dispatch => {
//     try {
//    console.log('datataaaa',data)
//       dispatch({
//       type: "FETCH_SELECTED_DATA",
//       payload: data
//     });

// }catch(err){
//     dispatch({
//         type: "FETCH_LOCATION_FAILED",
//         payload: err
//       });
// }
//   },
};

export default landingActions;