import React, { Fragment } from 'react';
import _,{groupBy, isEmpty, sum,map} from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { Card, Row, Col, Button, Modal, ModalBody } from 'react-bootstrap';
import checkoutActions from '../../actions/checkout/checkout';
import Loader from '../Loader/Loader';
import ModalDialog from '../ModalDialog/ModalDialog';
import cardInfo from '../../utils/cardConfig/cardConfig';
import DesktopView from '../BusFares/DesktopView';
import appConstants from '../../constants/appConstants';
import Theme from '../Theme/index';
import CheckoutUI from './CheckoutUI';
import CrossImg from '../../../../assets/images/cross.svg';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { getMonthlyTicketType, validity} from "../../utils/commonUtils/commonUtils";
import CongnitoTokenContext from '../../../../CognitoTokenContext';
import { format } from 'date-fns'

const { APP_PROXY_FOLDER } = appConstants;

class Checkout extends React.Component {
    
    cartId = '';
    timezoneName = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    allTickets = {};
    currency = '$';
    translationKey = {};
    cardTypeValue = {};
    cardOptions = cardInfo.cardOptions;
    totalFares = {};
    ticketsInCart = [];
    originState = '';
    originCity = '';
    destinationCity = '';
    destinationState = '';
    origin = '';
    destination = '';
    error = '';
    errMsg = {};
    constructor (props) {
        super(props);
        this.state = {
            value: '',
            apiError: '',
            show: true,
            open: false,
            splitPayment: true,
            errorTenderedAmountSec: '',
            cardTenderedAmount: 0,
            cardTenderedAmountSec: 0,
            errorTenderedAmount: '',
            windowSize: window.innerWidth,
            amountEditing: false,
            checkForFocus: false,
            paymentMode1: null,
            paymentMode2: null,
            paymentDetail: [],
            validationErrors: {
                first: {},
                second: {}
            }
        };
    }

    componentDidMount () {
        window.addEventListener('resize', this.windowResize);
        this.adjustPopupPosition();
        const body = document.getElementsByTagName('body');
        body[0].classList.add('bgWhite');
        window.scrollTo(0, 0);
    }

    windowResize = () => {
        this.setState({
            windowSize: window.innerWidth
        });
        this.adjustPopupPosition();
    };

    componentWillUnmount () {
        window.removeEventListener('resize', this.windowResize);
        const body = document.getElementsByTagName('body');
        body[0].classList.remove('bgWhite');
    }

    adjustPopupPosition () {
        const popupDialogElm = document.querySelector('.custom-modal-popup.checkout-faild-popup .modal-dialog');
        if (popupDialogElm) {
            popupDialogElm.style.top = document.querySelector('header.main-header').clientHeight - 30 + 'px';
        }
    }

    getSnapshotBeforeUpdate () {
        const popupDialogElm = document.querySelector('.custom-modal-popup.checkout-faild-popup .modal-dialog');
        if (popupDialogElm) {
            return 'dialog_present';
        }
        return null;
    }

    componentDidUpdate (prevProps, prevState, snap) {
        if (snap === 'dialog_present') {
            this.adjustPopupPosition();
        }
        if (prevProps.tokenAccepted !== this.props.tokenAccepted) {
            this.props.history.push('./orderstatus');
            localStorage.removeItem('ticketInfo');
        }

        if (prevProps.transactionError !== this.props.transactionError) {
              this.toggleModal()
            }

            // if(prevProps.ticketInfo !== this.props.ticketInfo){
            //  this.setState({
            //      paymentDetail: [{amount : parseFloat(Math.round(_.get(this.props, 'ticketInfo.totalFare', 0) * 100) / 100).toFixed(
            //          2
            //      )}]
            //  })
            // }

    }

    handleViewCart = () => {
        this.props.history.push('./shoppingcart');
    };

    toggleModal = () => this.setState((state) => ({ open: !state.open }));

    onBtnOk = () => {
        this.toggleModal();
    };

    gotoHome = () => {
        this.props.history.push('./home');
    };

    toggleFocusCheck = () => {
        this.setState({ checkForFocus: !this.state.checkForFocus });
    };

    handleBack = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    };

    splitPayment = () => {
        this.setState({
            splitPayment: false,
            paymentDetail: [_.merge(this.state.paymentDetail[0] ? this.state.paymentDetail[0] : {}, this.state.paymentDetail[1] ? this.state.paymentDetail[1] : {})]
        });
    };

    cancelSplitPayment = () => {
        this.setState({
            splitPayment: true,
            paymentDetail: !_.isEmpty(this.state.paymentDetail && this.state.paymentDetail[0])
                ? [ { ...this.state.paymentDetail[0], amount: this.formattedAmount(this.totalFares),paymentMethod : this.state.paymentMode1 } ]
                : [],
            paymentMode2: '',
            validationErrors: !_.isEmpty(this.state.paymentDetail && this.state.paymentDetail[0])
                ? {
                        first: {
                            ...this.state.validationErrors.first,
                            amount: ''
                        },
                        second: {}
                    }
                : {
                        first: {},
                        second: {}
                    }
        });
    };

    formattedAmount = (number) => {
        const formattedValue = parseFloat(number).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
        return number && formattedValue ? formattedValue : '';
    };

    onClose = () => {
        this.toggleModal();
    };

    handlePaymentMode1Change = (mode) => {
        
        this.setState({
            paymentMode1: mode,
            validationErrors: {
                first: {},
                second: { ...this.state.validationErrors.second }
            },
            paymentDetail: [ {amount : this.state.splitPayment ? this.formattedAmount(this.totalFares) : this.state.paymentDetail[1] && this.state.paymentDetail[1].amount ? this.formattedAmount(parseFloat(this.totalFares)-  parseFloat(_.replace(_.replace(this.state.paymentDetail[1].amount, '$', ''), /,/g, ''))) : '', paymentMethod : mode}, _.merge(this.state.paymentDetail[1] ? this.state.paymentDetail[1] : {}) ]
        });
    
        
    };
    handlePaymentMode2Change = (mode) => {
        
        this.setState({
            paymentMode2: mode,
            validationErrors: {
                first: {
                    ...this.state.validationErrors.first,
                    amount:''
                },
                second: {}
            },
            paymentDetail: [ _.merge(this.state.paymentDetail[0] ? {...this.state.paymentDetail[0], amount: this.state.paymentDetail[0].amount ? _.isEqual(this.formattedAmount(this.totalFares), this.state.paymentDetail[0].amount) ? '' : _.includes(this.state.paymentDetail[0].amount, '$') ? this.state.paymentDetail[0].amount : this.formattedAmount(this.state.paymentDetail[0].amount) : ''} : {}), {amount : this.state.paymentDetail[0] && this.state.paymentDetail[0].amount ? this.formattedAmount(parseFloat(this.totalFares)-  parseFloat(_.replace(_.replace(this.state.paymentDetail[0].amount, '$', ''), /,/g, ''))) : '', paymentMethod: mode} ]
        });
    };

    setValidationError = (name, msg, method) => {
        this.setState((state) => ({
            validationErrors: {
                ...state.validationErrors,
                [method]: {
                    ...state.validationErrors[method],
                    [name]: msg
                }
            }
        }));
    };
    handlePaymentMethodChanges = ({ target: { value, name } }, mode, method) => {
        const { paymentDetail, splitPayment } = this.state;
        if (method === 'first') {
            if (mode === 'cc' || mode === 'cash' || mode === 'check' || mode === 'voucher') {
                const amount = value ? parseFloat(value) : 0;
                const totalFares = parseFloat(this.totalFares);
                if (name === 'amount' && value) {
                    if (_.isNaN(amount) || amount > totalFares) {
                        this.setValidationError(name, 'Please Enter valid amount', method);
                    } else if (splitPayment && amount !== totalFares) {
                        this.setValidationError(name, 'Please Enter valid amount', method);
                    } else {
                        this.setValidationError(name, '', method);
                        this.setValidationError(name, '', 'second');
                        this.setState(
                            {
                                cardTenderedAmountSec: totalFares - amount,
                                cardTenderedAmount: amount
                            },
                            () => {
                                if (!this.state.splitPayment && this.state.paymentMode2) {
                                    paymentDetail[1] = _.merge(paymentDetail[1] ? paymentDetail[1] : {}, {
                                        paymentMethod: this.state.paymentMode2,
                                        amount: this.formattedAmount(this.state.cardTenderedAmountSec)
                                    });
                                }
                            }
                        );
                    }
                } else {
                    if (name === 'amount') {
                        this.setValidationError(name, '', method);
                        this.setValidationError(name, '', 'second');
                        this.setState(
                            {
                                cardTenderedAmountSec: totalFares - amount,
                                cardTenderedAmount: amount
                            },
                            () => {
                                if (!this.state.splitPayment && this.state.paymentMode2) {
                                    paymentDetail[1] = _.merge(paymentDetail[1] ? paymentDetail[1] : {}, {
                                        paymentMethod: this.state.paymentMode2,
                                        amount: this.formattedAmount(this.state.cardTenderedAmountSec)
                                    });
                                }
                            }
                        );
                    }
                }
                if (name === 'ccFourdigit') {
                    if (_.isNaN(parseInt(value)) || _.replace(value, /[^0-9 ]/g, '').length < 4) {
                        this.setValidationError(name, 'Please Enter valid card details', method);
                    } else {
                        this.setValidationError(name, '', method);
                    }
                }
                if (name === 'chequeNumber') {
                    if (_.isNaN(parseInt(value))) {
                        this.setValidationError(name, 'Please Enter valid check number', method);
                    } else {
                        this.setValidationError(name, '', method);
                    }
                }
                if (name === 'voucherNumber') {
                    if (_.isNaN(parseInt(value))) {
                        this.setValidationError(name, 'Please Enter valid voucher number', method);
                    } else {
                        this.setValidationError(name, '', method);
                    }
                }
            }
        } else {
            if (mode === 'cc' || mode === 'cash' || mode === 'check' || mode === 'voucher') {
                const amount = value ? parseFloat(value) : 0;
                const totalFares = parseFloat(this.totalFares);
                if (name === 'amount' && value) {
                    if (_.isNaN(amount) || amount > totalFares) {
                        this.setValidationError(name, 'Please Enter valid amount', method);
                    } else {
                        this.setValidationError(name, '', method);
                        this.setValidationError(name, '', 'first');
                        this.setState(
                            {
                                cardTenderedAmount: parseFloat(totalFares) - amount,
                                cardTenderedAmountSec: amount
                            },
                            () => {
                                if (!this.state.splitPayment && this.state.paymentMode1) {
                                    paymentDetail[0] = _.merge(paymentDetail[0] ? paymentDetail[0] : {}, {
                                        paymentMethod: this.state.paymentMode1,
                                        amount: this.formattedAmount(this.state.cardTenderedAmount)
                                    });
                                }
                            }
                        );
                    }
                } else {
                    if (name === 'amount') {
                        this.setValidationError(name, '', method);
                        this.setValidationError(name, '', 'first');
                        this.setState(
                            {
                                cardTenderedAmount: parseFloat(totalFares) - amount,
                                cardTenderedAmountSec: amount
                            },
                            () => {
                                if (!this.state.splitPayment && this.state.paymentMode1) {
                                    paymentDetail[0] = _.merge(paymentDetail[0] ? paymentDetail[0] : {}, {
                                        paymentMethod: this.state.paymentMode1,
                                        amount: this.formattedAmount(this.state.cardTenderedAmount)
                                    });
                                }
                            }
                        );
                    }
                }
                if (name === 'ccFourdigit') {
                    if (_.isNaN(parseInt(value)) || _.replace(value, /[^0-9 ]/g, '').length < 4) {
                        this.setValidationError(name, 'Please Enter valid card details', method);
                    } else {
                        this.setValidationError(name, '', method);
                    }
                }
                if (name === 'chequeNumber') {
                    if (_.isNaN(parseInt(value))) {
                        this.setValidationError(name, 'Please Enter valid check number', method);
                    } else {
                        this.setValidationError(name, '', method);
                    }
                }
                if (name === 'voucherNumber') {
                    if (_.isNaN(parseInt(value))) {
                        this.setValidationError(name, 'Please Enter valid voucher number', method);
                    } else {
                        this.setValidationError(name, '', method);
                    }
                }
            }
        }
        if (paymentDetail.length) {
            if (method === 'first') {
                if (paymentDetail[0]) {
                    paymentDetail[0] = {
                        ...paymentDetail[0],
                        paymentMethod: mode,
                        [name]:
                            name === 'ccFourdigit' && value.length > 4
                                ? _.replace(value.substr(0, 4), /[^0-9 ]/g, '')
                                : _.replace(
                                        value,
                                        name === 'ccFourdigit' || name === 'chequeNumber' || name === 'voucherNumber'
                                            ? /[^0-9 ]/g
                                            : name === 'refApprovalCode' || name === 'issuingAgent'
                                                ? /[^a-z0-9 ]/gi
                                                : name === 'amount' ? /[^0-9,. ]/g : /[^a-z0-9 ]/gi,
                                        ''
                                    )
                    };
                } else {
                    paymentDetail[0] = {
                        paymentMethod: mode,
                        [name]:
                            name === 'ccFourdigit' && value.length > 4
                                ? _.replace(value.substr(0, 4), /[^0-9 ]/g, '')
                                : _.replace(
                                        value,
                                        name === 'ccFourdigit' || name === 'chequeNumber' || name === 'voucherNumber'
                                            ? /[^0-9 ]/g
                                            : name === 'refApprovalCode' || name === 'issuingAgent'
                                                ? /[^a-z0-9 ]/gi
                                                : name === 'amount' ? /[^0-9,. ]/g : /[^a-z0-9 ]/gi,
                                        ''
                                    )
                    };
                }
            } else {
                if (paymentDetail[1]) {
                    paymentDetail[1] = {
                        ...paymentDetail[1],
                        paymentMethod: mode,
                        [name]:
                            name === 'ccFourdigit' && value.length > 4
                                ? _.replace(value.substr(0, 4), /[^0-9 ]/g, '')
                                : _.replace(
                                        value,
                                        name === 'ccFourdigit' || name === 'chequeNumber' || name === 'voucherNumber'
                                            ? /[^0-9 ]/g
                                            : name === 'refApprovalCode' || name === 'issuingAgent'
                                                ? /[^a-z0-9 ]/gi
                                                : name === 'amount' ? /[^0-9,. ]/g : /[^a-z0-9 ]/gi,
                                        ''
                                    )
                    };
                } else {
                    paymentDetail[1] = {
                        paymentMethod: mode,
                        [name]:
                            name === 'ccFourdigit' && value.length > 4
                                ? _.replace(value.substr(0, 4), /[^0-9 ]/g, '')
                                : _.replace(
                                        value,
                                        name === 'ccFourdigit' || name === 'chequeNumber' || name === 'voucherNumber'
                                            ? /[^0-9 ]/g
                                            : name === 'refApprovalCode' || name === 'issuingAgent'
                                                ? /[^a-z0-9 ]/gi
                                                : name === 'amount' ? /[^0-9,. ]/g : /[^a-z0-9 ]/gi,
                                        ''
                                    )
                    };
                }
            }
            this.setState({ paymentDetail });
        } else {
            this.setState({
                paymentDetail: [
                    ...paymentDetail,
                    {
                        paymentMethod: mode,
                        [name]:
                            name === 'ccFourdigit' && value.length > 4
                                ? _.replace(value.substr(0, 4), /[^0-9 ]/g, '')
                                : _.replace(
                                        value,
                                        name === 'ccFourdigit' || name === 'chequeNumber' || name === 'voucherNumber'
                                            ? /[^0-9 ]/g
                                            : name === 'refApprovalCode' || name === 'issuingAgent'
                                                ? /[^a-z0-9 ]/gi
                                                : name === 'amount' ? /[^0-9,. ]/g : /[^a-z0-9 ]/gi,
                                        ''
                                    )
                    }
                ]
            });
        }
    };

    handleBlur = (method) => {
        const { paymentDetail, validationErrors } = this.state;
        if (
            method === 'first' &&
            paymentDetail[0] &&
            paymentDetail[0].amount &&
            _.isEmpty(validationErrors.first.amount)
        ) {
            paymentDetail[0].amount = this.formattedAmount(paymentDetail[0].amount);
        }
        if (
            method === 'second' &&
            paymentDetail[1] &&
            paymentDetail[1].amount &&
            _.isEmpty(validationErrors.second.amount)
        ) {
            paymentDetail[1].amount = this.formattedAmount(paymentDetail[1].amount);
        }
        this.setState({ paymentDetail });
    };

    handleFocus = (method) => {
        const { paymentDetail, validationErrors } = this.state;
        if (
            method === 'first' &&
            paymentDetail[0] &&
            paymentDetail[0].amount &&
            _.isEmpty(validationErrors.first.amount)
        ) {
            paymentDetail[0].amount = _.replace(_.replace(paymentDetail[0].amount, '$', ''), /,/g, '');
        }
        if (
            method === 'second' &&
            paymentDetail[1] &&
            paymentDetail[1].amount &&
            _.isEmpty(validationErrors.second.amount)
        ) {
            paymentDetail[1].amount = _.replace(_.replace(paymentDetail[1].amount, '$', ''), /,/g, '');
        }
        this.setState({ paymentDetail });
    };

    validateEmail (email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    handleSubmit = () => {
        const userDetails = JSON.parse(localStorage.getItem('cognitoUser'));
        let orderAmount;
        let cartId;
        if (!_.isEmpty(this.props.ticketInfo)) {
            orderAmount = parseFloat(Math.round(_.get(this.props, 'ticketInfo.totalFare', 0) * 100) / 100).toFixed(2);
            cartId = _.get(this.props, 'ticketInfo.cartId');
        }

        const orderRequestData = {
            cartId: cartId,
            agentId: userDetails.userEmail,
            email: userDetails.emailID,
            agentLocationId: localStorage.getItem("agent_Location"),
            orderAmount: orderAmount,
            isCustomerAgent: !_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? true : false,
            cognitoRoleName: !_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? "customer_agent" : "agent",
            additionalData: {
              additionalDeliveryEmail: !_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 
              localStorage.getItem("ticketEmail") : null,
              agentLocationDate : (format(new Date(), 'MMM d, yyyy K:mm a ') + this.timezoneName).toUpperCase()
            },
            paymentDetail: !this.state.splitPayment
                ? _.map(this.state.paymentDetail, (pd) => {
                        return {
                            ...pd,
                            amount: _.replace(_.replace(pd.amount, '$', ''), /,/g, '')
                        };
                    })
                : [
                        {
                            ...this.state.paymentDetail[0],
                            amount: _.replace(_.replace(this.state.paymentDetail[0].amount, '$', ''), /,/g, '')
                        }
                    ],
            source: 'agent_portal',
            generateBarcode: true,
            sendReceiptToEmail: false
        };
        this.props.orderRequest(orderRequestData);
    };

    validateBuy = () => {
        let validationError = false;
        let notEmpty = false;
        let response = _.isEmpty(this.state.paymentMode1) || _.isEmpty(this.state.paymentDetail);
        _.mapValues(this.state.validationErrors, (vE) => {
            _.mapValues(vE, (v) => {
                if (!_.isEmpty(v)) validationError = true;
            });
        });
        const total = _.reduce(
            _.map(this.state.paymentDetail, 'amount'),
            (prev, curr) => {
                return (
                    prev +
                    (_.isNaN(parseFloat(_.replace(_.replace(curr, '$', ''), /,/g, '')))
                        ? 0
                        : parseFloat(_.replace(_.replace(curr, '$', ''), /,/g, '')))
                );
            },
            0
        );
        _.map(this.state.paymentDetail, (pd) => {
            if (pd.paymentMethod === 'cc') {
                if (_.isUndefined(pd.ccFourdigit) || _.isEmpty(pd.ccFourdigit)) notEmpty = true;
                if (_.isUndefined(pd.refApprovalCode) || _.isEmpty(pd.refApprovalCode)) notEmpty = true;
            }
            if (pd.paymentMethod === 'check') {
                if (_.isUndefined(pd.chequeNumber) || _.isEmpty(pd.chequeNumber)) notEmpty = true;
            }
            if (pd.paymentMethod === 'voucher') {
                if (_.isUndefined(pd.voucherNumber) || _.isEmpty(pd.voucherNumber)) notEmpty = true;
                if (_.isUndefined(pd.issuingAgent) || _.isEmpty(pd.issuingAgent)) notEmpty = true;
            }
            if (parseFloat(_.replace(_.replace(pd.amount, '$', ''), /,/g, '')) <= 0) notEmpty = true;
        });
        response = !_.isEqual(parseFloat(total.toFixed(2)), parseFloat(this.totalFares));
        const payment1Blank = !this.state.splitPayment
            ? _.isEmpty(this.state.paymentDetail[0] && this.state.paymentDetail[0].amount)
            : false;
        const payment2Blank = !this.state.splitPayment
            ? _.isEmpty(this.state.paymentDetail[1] && this.state.paymentDetail[1].amount)
            : false;
        return response || validationError || notEmpty || payment1Blank || payment2Blank;
    };

    toggleModal = () => this.setState(state => ({ open: !state.open }));

    ticketTypeName = (row) => {
		let ticketType = row.ticketType
		  ticketType = _.includes(ticketType, 'MONTHLY') ? getMonthlyTicketType(ticketType) : ticketType;
	   return ticketType;
	  };

    render () { 

        if (!_.isEmpty(this.props.ticketInfo)) {
            this.totalFares = parseFloat(Math.round(_.get(this.props, 'ticketInfo.totalFare', 0) * 100) / 100).toFixed(
                2
            );
            this.ticketsInCart = _.get(this.props, 'ticketInfo.ticketitems', []);
            this.origin = `${this.originCity}, ${this.originState}`;
            this.destination = `${this.destinationCity}, ${this.destinationState}`;
        } else {
            return <Redirect to="/home" />;
        }
        let sortedData = {};
        let sodList = [];
        let totalServiceFeeList = []
        const cartItems = _.get(this.props, 'ticketInfo.ticketitems', []);
        const subTotal = sum(cartItems.map(ci => (parseFloat(ci.fare) * parseInt(ci.quantity)) ));
        if (cartItems.length) {
            cartItems.forEach((ticket) => {
              let sod = ticket.sod;
              if (!sortedData[sod]) {
                sortedData[sod] = [];
                sodList.push(ticket);
              }
              if (!isEmpty(ticket.serviceFeeList)) {
                totalServiceFeeList = totalServiceFeeList.concat(ticket.serviceFeeList.map(fee => {
                  fee.quantity = ticket.quantity;
                  return fee
                }))
              }
              sortedData[sod].push(ticket);
            });
          }

          totalServiceFeeList = groupBy(totalServiceFeeList,  'displayName');

        const months = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        const date = new Date();
        return (
            <Theme>
                <div className="checkout-page">
                    {this.props.isLoading && (
                        <div>
                            <Loader />
                        </div>
                    )}
                    {_.isEmpty(this.ticketsInCart) ? (
                        this.props.history.push('./home')
                    ) : (
                        <div className="container">
                            <Col md={12} xs={12}>
                                <div className={_.isEmpty(this.state.apiError) ? 'no-error' : 'error-msg'}>
                                    {this.state.apiError}
                                </div>
                            </Col>
                            <Row className="mb-3">
                                <Col md={12}><Link onClick={this.handleBack} style={{textDecoration: 'underline'}}>Go Back</Link></Col>
                            </Row>
                            <Row className="checkout-page-row">
                                <Col md={12} lg={8} xs={12}>
                                    <Row className="mb-2">
                                        <Col md={6} xs={5}>
                                            <h2 className="checkout-text mt-2">Checkout</h2>
                                        </Col>
                                        <Col md={6} xs={7} style={{ textAlign: 'right' }}>
                                            {this.state.splitPayment ? (
                                                <Button
                                                    type="submit"
                                                    className="btn btn-secondary"
                                                    onClick={this.splitPayment}
                                                    style={{ width: '186px' }}>
                                                    Split My Payment
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    className="btn btn-secondary"
                                                    onClick={this.cancelSplitPayment}
                                                    style={{ width: '186px' }}>
                                                    Cancel Split Payment
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <div>
                                                <CheckoutUI
                                                    splitPayment={this.state.splitPayment}
                                                    paymentMode1={this.state.paymentMode1}
                                                    handlePaymentMode1Change={this.handlePaymentMode1Change}
                                                    paymentMode2={this.state.paymentMode2}
                                                    handlePaymentMode2Change={this.handlePaymentMode2Change}
                                                    handlePaymentMethodChanges={this.handlePaymentMethodChanges}
                                                    handleSubmit={this.handleSubmit}
                                                    paymentDetail={this.state.paymentDetail}
                                                    validationErrors={this.state.validationErrors}
                                                    handleBlur={this.handleBlur}
                                                    handleFocus={this.handleFocus}
                                                    validateBuy={this.validateBuy}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} lg={4} xs={12} className="order-summary-mobile">
                                    <Card className="order-card order-summary-box orbit-neg-28-mar-rt">
                                        <div>
                                            <div className="order-summary d-flex justify-content-center justify-content-between">
                                                <h4>Order Summary</h4>
                                                <Link className="view-cart-icon" to={`${APP_PROXY_FOLDER}shoppingcart`}>
                                                    View&nbsp;Cart&nbsp;<span>&gt;</span>
                                                </Link>
                                            </div>
                                        </div>
                                        {sodList.map((sodObj, index) => {
                                            return (
                                                <div key={`details-${index}`}>
                                                    {index > 0 ? <div className="breaker mb-3" /> : null}
                                                    <div className="details">
                                                        <div>
                                                            <div className="desktop-view-sod">
                                                                <DesktopView sodObj={sodObj} />
                                                            </div>
                                                        </div>
                                                        {_.map(sortedData[sodObj.sod], (cart, i) => {
                                                            return (
                                                                <div
																		style={{
																			display: 'flex',
																			justifyContent: 'flex-end'
																		}}
																		key={`order-listing-${i}`}>
                                                                <ul
                                                                    className="row-box order-listing shopping-cart-admin-free"
                                                                    key={`shopping-cart-${i}`}>
                                                                    <li>
                                                                        <span className="num-of-ticket">
                                                                            {' '}
                                                                            {cart.quantity}{' '}
                                                                        </span>
                                                                            {!isEmpty(cart.serviceFeeList) &&
                                                                                map(cart.serviceFeeList, (fee) => {
                                                                                    return <span className="d-block text-center" id={fee.displayName}>
                                                                                        {cart.quantity}
                                                                                    </span>
                                                                                })
                                                                            }
                                                                    </li>
                                                                    <li>
                                                                    <span className="one_way">
                                                                    {this.ticketTypeName(cart)} 
                                                                    </span>
                                                                            {!isEmpty(cart.serviceFeeList) &&
                                                                                map(cart.serviceFeeList, (fee) => {
                                                                                    return (<span className="d-block" id={fee.displayName}>
                                                                                        {_.get(fee, "displayName")}(s)
                                                                                    </span>)
                                                                                })}
                                                                    </li>
                                                                    <li>
                                                                        <span className="pasngr-type d-block">
                                                                            {' '}
                                                                            {cart.passengerType}{' '}
                                                                        </span>
                                                                            {!isEmpty(cart.serviceFeeList) &&
                                                                                map(cart.serviceFeeList, (fee) => {
                                                                                    return <span className="d-block pasngr-type pl-3" id={fee.displayName}>
                                                                                        -
                                             </span>
                                                                                })}
                                                                    </li>
                                                                    <li className="text-right">
                                                                        <span className="price">
                                                                            {' '}
                                                                            $
                                                                            {Number(
                                                                                parseFloat(
                                                                                    cart.fare * cart.quantity
                                                                                ).toFixed(2)
                                                                            ).toLocaleString(navigator.language, {
                                                                                minimumFractionDigits: 2
                                                                            })}{' '}
                                                                        </span>
                                                                            {!isEmpty(cart.serviceFeeList) &&
                                                                                <>
                                                                                {map(cart.serviceFeeList, (fee) => {
                                                                                    return <span className="d-block admin_fee_price">
                                                                                        $
                                                    {parseFloat(
                                                                                        _.get(fee, "amount") * cart.quantity
                                                                                    ).toFixed(2)}{" "}
                                                                                    </span>
                                                                                })}
                                                                                </>
                                                                            }
                                                                    </li>
                                                                </ul>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <Card.Footer className="amount">
                                        {Object.keys(totalServiceFeeList).length && subTotal ?  
                  <Row>
                    <Col md={6} xs={6}>
                      <span className="final-admin-fee-label">
                        Subtotal
                      </span>
                    </Col>
                    <Col md={6} xs={6} className="text-right">
                      <span className="final-admin-fee-price">${subTotal.toFixed(2)}</span>
                    </Col>
                  </Row> : null}
                {Object.keys(totalServiceFeeList).map((feeName) => {
                  return (
                    <Row key={totalServiceFeeList[feeName][0].serviceFeeId}>
                      <Col md={8} xs={8}>
                        <span className="final-admin-fee-label">
                          {feeName}(s)
                        </span>
                      </Col>
                      <Col md={4} xs={4} className="text-right">
                        <span className="final-admin-fee-price">
                        ${ sum(totalServiceFeeList[feeName].map(f => parseFloat(f.amount) * parseInt(f.quantity))).toFixed(2) } 
                        </span>
                      </Col>
                    </Row>
                  )
                })}
                                            <Row>
                                                <Col md={6} xs={6}>
                                                    Amount Due
                                                </Col>
                                                <Col md={6} xs={6} className="text-right">
                                                    <span>
                                                        {this.currency}
                                                        {Number(this.totalFares).toLocaleString(navigator.language, {
                                                            minimumFractionDigits: 2
                                                        })}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Card.Footer>
                                        {!this.state.splitPayment ? (
                                            <Fragment>
                                                <Card.Footer className="amount fontweight">
                                                    <Row>
                                                        <Col md={6} xs={6}>
                                                            Amount Paid
                                                        </Col>
                                                        <Col md={6} xs={6} className="text-right">
                                                            <span>
                                                                {this.currency}
                                                                {Number(
                                                                    parseFloat(
                                                                        this.state.paymentDetail[0] &&
                                                                        this.state.paymentDetail[0].amount &&
                                                                        _.includes(
                                                                            this.state.paymentDetail[0].amount,
                                                                            '$'
                                                                        )
                                                                            ? _.replace(
                                                                                    _.replace(
                                                                                        this.state.paymentDetail[0]
                                                                                            .amount,
                                                                                        /,/g,
                                                                                        ''
                                                                                    ),
                                                                                    '$',
                                                                                    ''
                                                                                )
                                                                            : 0
                                                                    ) +
                                                                        parseFloat(
                                                                            this.state.paymentDetail[1] &&
                                                                            this.state.paymentDetail[1].amount &&
                                                                            _.includes(
                                                                                this.state.paymentDetail[1].amount,
                                                                                '$'
                                                                            )
                                                                                ? _.replace(
                                                                                        _.replace(
                                                                                            this.state.paymentDetail[1]
                                                                                                .amount,
                                                                                            /,/g,
                                                                                            ''
                                                                                        ),
                                                                                        '$',
                                                                                        ''
                                                                                    )
                                                                                : 0
                                                                        )
                                                                ).toLocaleString(navigator.language, {
                                                                    minimumFractionDigits: 2
                                                                })}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Card.Footer>
                                                <Card.Footer className="amount fontweight">
                                                    <Row>
                                                        <Col md={7} xs={7}>
                                                            Current Balance
                                                        </Col>
                                                        <Col md={5} xs={5} className="text-right">
                                                            <span>
                                                                {this.currency}
                                                                {Number(Math.abs(
                                                                    parseFloat(this.totalFares) -
                                                                        (parseFloat(
                                                                            this.state.paymentDetail[0] &&
                                                                            this.state.paymentDetail[0].amount &&
                                                                            _.includes(
                                                                                this.state.paymentDetail[0].amount,
                                                                                '$'
                                                                            )
                                                                                ? _.replace(
                                                                                        _.replace(
                                                                                            this.state.paymentDetail[0]
                                                                                                .amount,
                                                                                            /,/g,
                                                                                            ''
                                                                                        ),
                                                                                        '$',
                                                                                        ''
                                                                                    )
                                                                                : 0
                                                                        ) +
                                                                            parseFloat(
                                                                                this.state.paymentDetail[1] &&
                                                                                this.state.paymentDetail[1].amount &&
                                                                                _.includes(
                                                                                    this.state.paymentDetail[1].amount,
                                                                                    '$'
                                                                                )
                                                                                    ? _.replace(
                                                                                            _.replace(
                                                                                                this.state
                                                                                                    .paymentDetail[1]
                                                                                                    .amount,
                                                                                                /,/g,
                                                                                                ''
                                                                                            ),
                                                                                            '$',
                                                                                            ''
                                                                                        )
                                                                                    : 0
                                                                            ))
                                                                )).toLocaleString(navigator.language, {
                                                                    minimumFractionDigits: 2
                                                                })}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </Card.Footer>
                                            </Fragment>
                                        ) : null}
                                        <div style={{ marginTop: 15 }}>
                                            <div className="hide-xs text-center">
                                                <Col md={12} className="pb-5">
                                                    <Button
                                                        type="submit"
                                                        className="btn-secondary"
                                                        onClick={this.gotoHome}>
                                                        KEEP SHOPPING
                                                    </Button>
                                                </Col>
                                            </div>
                                        </div>
                                        
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>
                <Modal className="journal-modal" show={this.state.open} onHide={this.toggleModal}>
                                <ModalHeader closeButton />
                                <ModalBody>
                                    <img src={CrossImg} alt="GroupImg" style={{ marginBottom: '40px' }} />
                                    <p>Something went wrong</p>
                                    <p>contact customer support</p>
                                    <div className="text-center" style={{ marginTop: '120px' }}>
                                        <button onClick={this.toggleModal}>Ok</button>
                                    </div>
                                </ModalBody>
                            </Modal>
            </Theme>
        );
    }
}
Checkout.contextType = CongnitoTokenContext;
const mapStateToProps = (state) => ({
    isLoading: state.checkout.isLoading,
    ticketInfo: state.busFares.ticketInfo,
    tokenAccepted: state.checkout.tokenAccepted,
    isAuthenticated: state.authReducer.isAuthenticated,
    viewCardsData: state.profileReducer.viewCardsData,
    viewUserResult: state.profileReducer.viewUserResult,
    cardFormData: state.checkout.cardFormData,
    billingData: state.checkout.billingData,
    transactionError: state.checkout.transactionError
});

const mapDispatchToProps = (dispatch) => {
    return {
        orderRequest: (orderRequestData) => dispatch(checkoutActions.orderRequest(orderRequestData))
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Checkout);