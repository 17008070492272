import React from 'react';
import { Row, Col, FormLabel, Form, FormControl } from 'react-bootstrap';
import _ from 'lodash';

const CheckoutFields = ({
	type,
	method,
	handlePaymentMethodChanges,
	paymentDetail,
	validationErrors,
	handleBlur,
	handleFocus,
	cognitoTokenValue
}) => {
	if (method === 'first') {
		switch (type) {
			case 'cc':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Credit Card Information</span>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formLastFourDigits">
								<div className="float-label-control fs-exclude">
									<Form.Control
										type="text"
										name="ccFourdigit"
										placeholder="Last Four Digits*"
										value={_.get(paymentDetail[0], 'ccFourdigit', '')}
										autoComplete="off"
										isInvalid={!_.isEmpty(validationErrors.first.ccFourdigit)}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
									/>
									<FormLabel className="form-control-placeholder text-left">
										Last Four Digits*
									</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'ccFourdigit', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formRefApprovalCode">
								<div className="float-label-control fs-exclude">
									<Form.Control
										type="text"
										name="refApprovalCode"
										placeholder="Ref Approval Code*"
										value={_.get(paymentDetail[0], 'refApprovalCode', '')}
										autoComplete="off"
										isInvalid={!_.isEmpty(validationErrors.first.refApprovalCode)}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
									/>
									<FormLabel className="form-control-placeholder text-left">
										Ref Approval Code*
									</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'refApprovalCode', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formAmountCheck">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										value={_.get(paymentDetail[0], 'amount', '')}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										isInvalid={!_.isEmpty(validationErrors.first.amount)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);
			case 'check':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Check Information</span>
						</Col>
						<Col md={6}>
							<Form.Group controlId="formCheckNumber">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Check Number*"
										name="chequeNumber"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[0], 'chequeNumber', '')}
										isInvalid={!_.isEmpty(validationErrors.first.chequeNumber)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Check Number*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'chequeNumber', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group controlId="formAmountCheck">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[0], 'amount', '')}
										isInvalid={!_.isEmpty(validationErrors.first.amount)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);
			case 'cash':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Cash</span>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formAmountCash">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										value={_.get(paymentDetail[0], 'amount', '')}
										isInvalid={!_.isEmpty(validationErrors.first.amount)}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);
			case 'voucher':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Voucher Information</span>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formVoucherNumber">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder={!_.isEmpty(cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'Voucher/Comp Number*' : 'Voucher*'}
										name="voucherNumber"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[0], 'voucherNumber', '')}
										isInvalid={!_.isEmpty(validationErrors.first.voucherNumber)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">
									{!_.isEmpty(cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'Voucher/Comp Number*' : 'Voucher*'}
									</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'voucherNumber', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formIssuingAgent">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Issuing Agent*"
										name="issuingAgent"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[0], 'issuingAgent', '')}
										isInvalid={!_.isEmpty(validationErrors.first.issuingAgent)}
									/>
									<FormLabel className="form-control-placeholder text-left">Issuing Agent*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'issuingAgent', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formAmount">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[0], 'amount', '')}
										isInvalid={!_.isEmpty(validationErrors.first.amount)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.first, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);

			default:
				return null;
		}
	} else {
		switch (type) {
			case 'cc':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Credit Card Information</span>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formLastFourDigits">
								<div className="float-label-control fs-exclude">
									<Form.Control
										type="text"
										name="ccFourdigit"
										placeholder="Last Four Digits*"
										value={_.get(paymentDetail[1], 'ccFourdigit', '')}
										isInvalid={!_.isEmpty(validationErrors.second.ccFourdigit)}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">
										Last Four Digits*
									</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'ccFourdigit', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formRefApprovalCode">
								<div className="float-label-control fs-exclude">
									<Form.Control
										type="text"
										name="refApprovalCode"
										placeholder="Ref Approval Code*"
										value={_.get(paymentDetail[1], 'refApprovalCode', '')}
										isInvalid={!_.isEmpty(validationErrors.second.refApprovalCode)}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">
										Ref Approval Code*
									</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'refApprovalCode', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formAmountCheck">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										value={_.get(paymentDetail[1], 'amount', '')}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										isInvalid={!_.isEmpty(validationErrors.second.amount)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);
			case 'check':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Check Information</span>
						</Col>
						<Col md={6}>
							<Form.Group controlId="formCheckNumber">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Check Number*"
										name="chequeNumber"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[1], 'chequeNumber', '')}
										isInvalid={!_.isEmpty(validationErrors.second.chequeNumber)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Check Number*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'chequeNumber', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group controlId="formAmountCheck">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[1], 'amount', '')}
										isInvalid={!_.isEmpty(validationErrors.second.amount)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);
			case 'cash':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Cash</span>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formAmountCash">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										value={_.get(paymentDetail[1], 'amount', '')}
										isInvalid={!_.isEmpty(validationErrors.second.amount)}
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);
			case 'voucher':
				return (
					<Row className="checkout mt-4">
						<Col sm={12} className="mb-2">
							<span>Voucher Information</span>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formVoucherNumber">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder={!_.isEmpty(cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'Voucher/Comp Number*' : 'Voucher*'}
										name="voucherNumber"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[1], 'voucherNumber', '')}
										isInvalid={!_.isEmpty(validationErrors.second.voucherNumber)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">
									{!_.isEmpty(cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP') ? 'Voucher/Comp Number*' : 'Voucher*'}
									</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'voucherNumber', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formIssuingAgent">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Issuing Agent*"
										name="issuingAgent"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[1], 'issuingAgent', '')}
										isInvalid={!_.isEmpty(validationErrors.second.issuingAgent)}
									/>
									<FormLabel className="form-control-placeholder text-left">Issuing Agent*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'issuingAgent', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
						<Col md={4}>
							<Form.Group controlId="formAmount">
								<div className="float-label-control">
									<Form.Control
										type="text"
										placeholder="Amount Due*"
										name="amount"
										onChange={(e) => handlePaymentMethodChanges(e, type, method)}
										value={_.get(paymentDetail[1], 'amount', '')}
										isInvalid={!_.isEmpty(validationErrors.second.amount)}
										onFocus={() => handleFocus(method)}
										onBlur={() => handleBlur(method)}
										autoComplete="off"
									/>
									<FormLabel className="form-control-placeholder text-left">Amount Due*</FormLabel>
									<FormControl.Feedback type="invalid">
										{_.get(validationErrors.second, 'amount', '')}
									</FormControl.Feedback>
								</div>
							</Form.Group>
						</Col>
					</Row>
				);

			default:
				return null;
		}
	}
};

export default CheckoutFields;
