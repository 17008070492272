import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes/Routes";
import { fetchFareRules } from "./pages/agentportal/actions/ticketFareRules/ticketFareRules";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { datadogRum } from "@datadog/browser-rum";
import { CongnitoTokenProvider } from "./CognitoTokenContext";

class App extends React.Component {
  state = {
    cognitoTokenValue: "",
  };
  static propTypes = {
    children: PropTypes.any,
  };

  componentDidMount() {
    this.runDataDog();
    this.props.fetchFareRules();
    // SCHEDULES BUTTON FIX 2020-09-29
    const docURL = document.location.toString();
    if (docURL.indexOf("ss.listing") >= 0) {
      document.location =
        "https://web.coachusa.com/" +
        docURL.substr(docURL.indexOf("ss.listing"), docURL.length);
    }
    this.updateCognitoToken();
  }

  runDataDog = () => {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATA_DOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "agent-portal",
      version: "1.0.0",
      env: process.env.REACT_APP_ENVIRONMENT,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
    datadogRum.startSessionReplayRecording();
  };

  updateCognitoToken = () => {
    const userDetails = JSON.parse(localStorage.getItem("cognitoUser"));
    if (!_.isEmpty(userDetails)) {
      const decodeToken = userDetails.signInUserSession.accessToken.jwtToken;
      const finalDecodeToken = jwt_decode(decodeToken);
      this.setState({
        cognitoTokenValue: _.get(finalDecodeToken, "cognito:groups", {}),
      });
    }
  };

  render() {
    return (
      <div className="App">
        <CongnitoTokenProvider
          value={{
            cognitoTokenValue: this.state.cognitoTokenValue,
            updateCognitoToken: this.updateCognitoToken,
          }}>
          <Router>
            <Routes />
          </Router>
        </CongnitoTokenProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  backdrop: state.headerReducer.backdrop,
  currentAuthSession: state.authReducer.currentAuthSession,
  showLoginToggle: state.headerReducer.showLoginToggle,
  showSignUpToggle: state.headerReducer.showSignUpToggle,
  loggedInUser: state.authReducer.loggedInUser,
  showConfirmModal: state.profileReducer.showConfirmModal,
  authUser: state.authReducer.authUser,
  isAppLoading: state.appReducer.isAppLoading,
  viewUserResult: state.profileReducer.viewUserResult,
  viewCardsData: state.profileReducer.viewCardsData,
  viewCartItems: state.appReducer.viewCartItems,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchFareRules: (data) => dispatch(fetchFareRules(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
