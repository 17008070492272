const initialState = {
  isAppLoading: false,
  error: "",
  viewUserResult: {},
  viewCardsData: {},
  translationItems: {},
  viewCartItems:{},
};
export default (state = initialState, action) => {
  switch (action.type) {

    case "TRANSLATION_ITEMS":
      return { ...state, translationItems: action.payload };
    case "TRANSLATION_ITEMS_FAILED":
      return { ...state, error: action.payload };
    case "VIEW_CART_ITEMS":
      return { ...state, viewCartItems: action.payload };
    case "VIEW_CART_ITEMS_FAILED":
      return { ...state, error: action.payload };
    case "REQUEST_START":
      return {
        ...state,
        isAppLoading: action.payload
      };
    case "REQUEST_STOP":
      return {
        ...state,
        isAppLoading: action.payload
      };
    default:
      return state;
  }
};
