import {useContext} from 'react';
import {TicketContext} from "./TicketContext";

const InitializeTickets = ({tickets, setInitDone}) => {
  const { setBarcodeStatus, setInitDoneContext, initDone } = useContext(TicketContext);
  if (!initDone) {
    for (let bc = 0; bc < tickets.length; bc++) {
      setBarcodeStatus(tickets[bc].id, false, null);
    }
    setInitDoneContext(true);
    setInitDone(true);
  }
  return false;
}

export default InitializeTickets;
