import React, { Fragment } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import { split, map, range } from "lodash";
import momenttimezone from 'moment-timezone';

class OtherPrintReceipt extends React.Component {
  render() {
    const userDetails = JSON.parse(localStorage.getItem("cognitoUser"));
    const sone =  momenttimezone.tz.guess();
    const timezone = momenttimezone.tz(sone).zoneAbbr()
    return (
      <Fragment>
        {map(range(3), (page, key) => (
          <div
            className="print-cont"
            style={{ pageBreakAfter: "always" }}
            key={`otherPrintReceipt-${key}`}
          >
            <Table className="ticket_header">
              <tbody>
                <tr>
                  {/* <td>
                    Logo
                  </td> */}
                  <td>
                    <p className="agentreceiptpaid">RECEIPT</p>
                    <p className="receiptpaidIn">THIS IS NOT A TICKET</p>
                  </td>
                </tr>
              </tbody>
            </Table>

            <div className="pr_receipt_block">
              <div>
                <b>DATE OF LOG:</b>{" "}
                {moment(new Date(this.props.state.date)).format("MM/DD/YYYY")}
              </div>
              <div>
                <b>SUBMITTED BY:</b> {this.props.state.source}
              </div>
              {/* <tr>
              <td><b>ISSUING OPR:</b> </td>
            </tr> */}
              <div>
                <b>REASON FOR PAID IN:</b> {this.props.state.reason}
              </div>
            </div>

            <Table className="pr_receipt_total">
              <thead>
                <tr>
                  <td></td>
                  <td>QTY</td>
                  <td>AMOUNT</td>
                </tr>
              </thead>
              <tbody>
                <tr className="bold">
                  <td>Total Amount</td>
                  <td align="center">1</td>
                  <td>{this.props.state.amount}</td>
                </tr>
              </tbody>
            </Table>

            <div className="pr_receipt_detail">
              <div>
                <b>Transaction No:</b>{" "}
                {split(this.props.state.transactionID, "-")[0]}
              </div>
              <div>
                <b>Transaction Date:</b>{" "}
                {moment(new Date()).format("MM/DD/YYYY")}
              </div>
              <div>
                <b>Transaction Time:</b> {moment(new Date()).format("hh:mm A")} {timezone}
              </div>
            </div>
            <Table className="pr_receipt_footer">
              <tbody>
                <tr>
                  <td>AGENT ID {userDetails.userEmail}</td>
                  <td>Page {page + 1}/3</td>
                </tr>
              </tbody>
            </Table>
          </div>
        ))}
      </Fragment>
    );
  }
}

export default OtherPrintReceipt;
