import api from "../../utils/api/api";
import appConstants from "../../constants/appConstants";

const {
  CHECK_ORDER_STATUS_START,
  CHECK_ORDER_STATUS_COMPLETE,
  CHECK_ORDER_STATUS_STOP,
  CHECK_ORDER_STATUS_FAILED
} = appConstants;

const orderStatusActions = {
  checkOrderStatus: order => async dispatch => {
    const userDetails = JSON.parse(localStorage.getItem("cognitoUser"));
  
    try {

      return await api.fetch(
        `${process.env.REACT_APP_ORDER_STATUS_API_ENDPOINT}?transactionId=${order.transactionId}&email=${userDetails.emailID}`
        )
        .then(
          response => {
            dispatch({
              type: CHECK_ORDER_STATUS_START,
              payload: true
            });
        
            //console.log("order success action-->",response.data)
            if(
              response.data.orbitResponse.action === "err-order-requested" ||
              response.data.orbitResponse.action === "err-payment-captured" ||
              response.data.orbitResponse.action === "err_order_created" ||
              response.data.orbitResponse.action === "err-barcode-generated"||
              response.data.orbitResponse.action === "error_no_transaction_found"
              ){
                dispatch({
                  type: CHECK_ORDER_STATUS_COMPLETE,
                  payload: response.data
                });
              dispatch({
                type: "UNHIDE_CART"
              });
            }
            else{
              dispatch({
                type: CHECK_ORDER_STATUS_COMPLETE,
                payload: response.data
              });
              dispatch({
                type: "EMPTY_CART"
              });
            }
           
          },
          error => {
            dispatch({
              type: CHECK_ORDER_STATUS_FAILED,
              payload: {
                error:error,
                action:"order-failed"
              }
            });
            dispatch({
              type: "UNHIDE_CART"
            });
            dispatch({
              type: CHECK_ORDER_STATUS_STOP,
              payload: false
            });
          }
        );
     
    } catch (error) {
      dispatch({
        type: CHECK_ORDER_STATUS_FAILED,
        payload: error
      });

      dispatch({
        type: "UNHIDE_CART"
      });

      return dispatch({
        type: CHECK_ORDER_STATUS_STOP,
        payload: false
      });
    }
  }
};

export default orderStatusActions;
