import { Auth } from 'aws-amplify';
import Axios from 'axios';

const axios = Axios.create();
axios.defaults.headers.common['x-api-key'] =
  process.env.REACT_APP_ORBIT_API_KEY;

axios.defaults.headers.common['partner-code'] = 'CUSA';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

if (
  process.env.REACT_APP_ENVIRONMENT.toLowerCase() !== 'prod' &&
  process.env.REACT_APP_ENVIRONMENT.toLowerCase() !== 'sandbox'
) {
  const message =
    process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'uat'
      ? 'let-me-in-to-uat'
      : 'let-me-in';
  axios.defaults.headers.common[
    `${process.env.REACT_APP_ENVIRONMENT.toLowerCase()}-access`
  ] = message;
}

const Api = {
  // Logged-in Users will get a valid Cognito Token, which is pass in Authorization header
  // For non-logged in users, leave the Authorization header empty
  // This will continue to allow access to public APIs, while correctly securing access to secure APIs

  fetch: (path, configurations) => {
    axios.interceptors.request.use(async function (config) {
      try {
        const currentAuthSession = await Auth.currentSession();
        const token = currentAuthSession.getIdToken().getJwtToken();
        config.headers.Authorization = token;
        return config;
      } catch (error) {
        config.headers.Authorization = '';
        return config;
      }
    });
    if (!path.startsWith('http')) {
      path = process.env.REACT_APP_API_ENDPOINT + path;
    }
    return axios.get(path, configurations);
  },
  post: (path, data) => {
    axios.interceptors.request.use(async function (config) {
      try {
        const currentAuthSession = await Auth.currentSession();
        const token = currentAuthSession.getIdToken().getJwtToken();
        config.headers.Authorization = token;
        return config;
      } catch (error) {
        config.headers.Authorization = '';
        return config;
      }
    });
    if (!path.startsWith('http')) {
      path = process.env.REACT_APP_API_ENDPOINT + path;
    }
    return axios.post(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  put: (path, data) => {
    axios.interceptors.request.use(async function (config) {
      try {
        const currentAuthSession = await Auth.currentSession();
        const token = currentAuthSession.getIdToken().getJwtToken();
        config.headers.Authorization = token;
        return config;
      } catch (error) {
        config.headers.Authorization = '';
        return config;
      }
    });
    if (!path.startsWith('http')) {
      path = process.env.REACT_APP_API_ENDPOINT + path;
    }
    return axios.put(path, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
};

export default Api;
