const initialState = {
  data: {},
  isLoading: false,
  tokenAccepted: {},
  email: "validated-user@gmail.com",
  isPaymentSuccess: false,
  transactionError:{},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PAYMENT_TOKEN_ACCEPTED":
      return {
        ...state,
        tokenAccepted: action.payload.data.orbitResponse.body.orderId,
        transactionId: action.payload.data.orbitResponse.body.transactionId,
      };
    case "PAYMENT_TOKEN_ACCEPTED_FAILED":
    //  console.log("PAYMENT_TOKEN_ACCEPTED_FAILED",action.payload.error.response);
      return {
        ...state,
        transactionError: action.payload.error.response ? action.payload.error.response : action.payload.error,
        email: action.payload.email,
        isPaymentSuccess: false
      };
    case "GET_PAYMENT_TOKEN_FAILED":
      return {
        ...state,
        errorPaymentAuthToken: action.payload
      };
    case "REQUEST_START":
      return {
        ...state,
        isLoading: action.payload
      };
    case "REQUEST_STOP":
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
};
