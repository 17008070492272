import React, { useState } from 'react';
import _ from 'lodash';
import { Col, FormControl, Button, Row, FormGroup } from 'react-bootstrap';
import EyeIcon from '../../../../assets/images/noun_eye';

const Login = (props) => {
	const [ showHelpMessage, setShowHelpMessage ] = useState(false);
	return (
		<div className="flex" style={{ height: 'calc(100vh -180px)' }}>
			<div className="login-wrapper">
				<div className="Customer-support-box">
					<form>
						<Row>
							<Col md={12} xs={12}>
								<h4>Log In</h4>
								<div style={{ textAlign: 'center', color: 'red' }}>
									{props.error ? <p>{props.error}</p> : null}
								</div>
								<FormGroup>
									<FormControl
										type="text"
										name="email"
										placeholder="Agent ID*"
										value={props.email}
										isInvalid={!!props.emailError}
										onChange={props.handleChange}
									/>
									<div className={_.isEmpty(props.emailError) ? 'no-error' : 'input-error'}>
										{props.emailError}
									</div>
								</FormGroup>
								<FormGroup className="postion-relative">
									<FormControl
										type={props.hidden ? 'password' : 'text'}
										name="password"
										placeholder="Password*"
										value={props.password}
										isInvalid={!!props.passError}
										onChange={props.handleChange}
										className='passwordType'
									/>
									<span className="eye-login" onClick={props.toggleShow}>
										{' '}
										<EyeIcon />
									</span>

									<div className={_.isEmpty(props.passError) ? 'no-error' : 'input-error'}>
										{props.passError}
									</div>
								</FormGroup>
								<div
									className="login-help"
									onClick={() => setShowHelpMessage(!showHelpMessage)}
									style={{ textDecoration: showHelpMessage ? 'underline' : 'none' }}>
									Log In Help
								</div>
								{showHelpMessage ? (
									<div className="help-error">For Log In Help Please Contact Your Supervisor</div>
								) : null}
								<div className="text-center">
									<Button
										type="submit"
										onClick={props.handleSubmit}
										disabled={props.validation}
										className="login-button"
										style={{ fontSize: '20px' }}>
										Log In
									</Button>
								</div>
							</Col>
						</Row>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Login;
