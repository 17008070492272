import React, {useState, useEffect, useContext} from 'react';
import TicketContainer from '../TicketContainer/TicketContainer';
import PrintCoupon from '../PrintTickets/PrintCoupon';
import {TicketContext, TicketContextProvider} from '../TicketContext/TicketContext';
import InitializeTickets from '../TicketContext/InitializeTickets';

const PrintAllTickets = ({tickets, location, printTickets}) => {
	const { allBarcodesLoaded } = useContext(TicketContext);
	const [initDone, setInitDone] = useState(false);

	useEffect(() => {
		document.title = 'Coach USA Agent Portal Tickets';
		if (allBarcodesLoaded) printTickets();
	});

	return (
		<div>
			<TicketContextProvider>
				{(!initDone) ?
						<InitializeTickets tickets={tickets} setInitDone={() => setInitDone(true)} />
						:
					  <div>
	  					{tickets.map((data, index) => (
		  					<div key={`ticket-${index}`}>
			  					<div className="main-ticket-area" key={`ticket-head-${index}`}>
										{(index > 0) ?
											<div style={{width: '100%', height: '35px'}} />
											:
											null
										}
										<TicketContainer key={`ticket-container-${index}`} data={data} printValue="true"/>
									</div>
									<div className="page-break clearfix" />
										{(tickets[index].sod === 'NYCP|WDBC') ?
											<PrintCoupon tickets={tickets} location={location} />
										:
										  null
										}
								</div>
							))}
					  </div>
				}
			</TicketContextProvider>
		</div>
	);
}

export default PrintAllTickets;
