import { Auth } from "aws-amplify";
import appConstants from "../../constants/appConstants";

const {
  COGNITO_AUTH_SUCCESS,
  COGNITO_LOGOUT_SUCCESS,
  COGNITO_LOGOUT_FAILURE,
  COGNITO_AUTH_SESSION_SUCCESS,
  COGNITO_AUTH_SESSION_FAILURE,
  COGNITO_AUTHENTICATED_USER,
  COGNITO_AUTHENTICATED_USER_ERROR,
  REQUEST_START,
  REQUEST_STOP,
  COGNITO_AUTHENTICATED_USER_END,
  COGNITO_SIGNUP_SUCCESS,
  COGNITO_SIGNUP_FAILED,
  COGNITO_CONFIRM_SIGNUP_SUCCESS,
  COGNITO_CONFIRM_SIGNUP_FAILED,
  COGNITO_FORGOTPWD_SUCCESS,
  COGNITO_FORGOTPWD_FAILED,
  COGNITO_CONFIRM_FORGOTPWD_SUCCESS,
  COGNITO_CONFIRM_FORGOTPWD_FAILED,
} = appConstants;

const authActions = {
  loginCognito: (email, password) => async (dispatch) => {
    email = email.toLowerCase();
    try {
      dispatch({
        type: REQUEST_START,
        payload: true,
      });
      const loggedInuser = await Auth.signIn(email, password);
      if (loggedInuser.signInUserSession !== "") {
        dispatch({
          type: REQUEST_STOP,
          payload: false,
        });
        return dispatch({
          type: COGNITO_AUTH_SUCCESS,
          payload: loggedInuser,
        });
      }
    } catch (error) {
      dispatch({
        type: COGNITO_AUTH_SESSION_FAILURE,
      });
      dispatch({
        type: REQUEST_STOP,
        payload: false,
      });
    }
  },

  logOutCognito: () => async (dispatch) => {
    try {
      dispatch({
        type: COGNITO_AUTHENTICATED_USER_END,
        payload: {},
      });
      await Auth.signOut().then(
        (response) => {
          dispatch({
            type: COGNITO_LOGOUT_SUCCESS,
            payload: response,
          });
          return dispatch({
            type: "EMPTY_CART",
          });
        },
        (error) => {
          return dispatch({
            type: COGNITO_LOGOUT_FAILURE,
            payload: error,
          });
        }
      );
      return dispatch({
        type: REQUEST_STOP,
        payload: false,
      });
    } catch (error) {
      return dispatch({
        type: COGNITO_LOGOUT_FAILURE,
      });
    }
  },

  checkAuthSession: () => async (dispatch) => {
    try {
      const currentAuthSession = await Auth.currentSession();
      return dispatch({
        type: COGNITO_AUTH_SESSION_SUCCESS,
        payload: currentAuthSession,
      });
    } catch (error) {
      return dispatch({
        type: COGNITO_AUTH_SESSION_FAILURE,
      });
    }
  },

  currentAuthenticatedUser: () => async (dispatch) => {
    try {
      const response = await Auth.currentAuthenticatedUser();

      dispatch({
        type: COGNITO_AUTHENTICATED_USER,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: COGNITO_AUTHENTICATED_USER_ERROR,
        payload: err.response,
      });
    }
  },

  signUpCognito: (username, password) => async (dispatch) => {
    try {
      dispatch({
        type: REQUEST_START,
        payload: true,
      });
      await Auth.signUp(username, password).then(
        (response) => {
          dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
          dispatch({
            type: COGNITO_SIGNUP_SUCCESS,
            payload: response,
          });
        },
        (error) => {
          dispatch({
            type: COGNITO_SIGNUP_FAILED,
            payload: error,
          });
          return dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
        }
      );
    } catch (error) {
      return dispatch({
        type: COGNITO_SIGNUP_FAILED,
        payload: error,
      });
    }
  },

  confirmCognitoSignUp: (email, confirmationCode) => async (dispatch) => {
    email = email.toLowerCase();
    try {
      dispatch({
        type: REQUEST_START,
        payload: true,
      });
      await Auth.confirmSignUp(email, confirmationCode).then(
        (confirmUser) => {
          dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
          dispatch({
            type: COGNITO_CONFIRM_SIGNUP_SUCCESS,
            payload: confirmUser,
          });
        },
        (error) => {
          dispatch({
            type: COGNITO_CONFIRM_SIGNUP_FAILED,
            payload: error,
          });
          return dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
        }
      );
    } catch (error) {
      return dispatch({
        type: COGNITO_CONFIRM_SIGNUP_FAILED,
        payload: error,
      });
    }
  },

  forgotPasswordCognito: (username) => async (dispatch) => {
    try {
      dispatch({
        type: REQUEST_START,
        payload: true,
      });
      await Auth.forgotPassword(username).then(
        (response) => {
          dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
          dispatch({
            type: COGNITO_FORGOTPWD_SUCCESS,
            payload: response,
          });
        },
        (error) => {
          dispatch({
            type: COGNITO_FORGOTPWD_FAILED,
            payload: error,
          });
          return dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
        }
      );
    } catch (error) {
      return dispatch({
        type: COGNITO_FORGOTPWD_FAILED,
        payload: error,
      });
    }
  },

  confirmForgotPassword: (username, code, new_password) => async (dispatch) => {
    try {
      dispatch({
        type: REQUEST_START,
        payload: true,
      });
      await Auth.forgotPasswordSubmit(username, code, new_password).then(
        (data) => {
          dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
          dispatch({
            type: COGNITO_CONFIRM_FORGOTPWD_SUCCESS,
            payload: true,
          });
        },
        (error) => {
          dispatch({
            type: COGNITO_CONFIRM_FORGOTPWD_FAILED,
            payload: error,
          });
          return dispatch({
            type: REQUEST_STOP,
            payload: false,
          });
        }
      );
    } catch (error) {
      return dispatch({
        type: COGNITO_CONFIRM_FORGOTPWD_FAILED,
        payload: error,
      });
    }
  },
};

export default authActions;
