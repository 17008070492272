const appConstants = {
  APP_ASSETS_FOLDER: (document.location.toString().search(process.env.REACT_APP_PROXY_ENDPOINT) > 0) ? `${process.env.REACT_APP_CDN}assets/images/` : "../../../../assets/images/",
  APP_PROXY_FOLDER: (document.location.toString().search(process.env.REACT_APP_PROXY_ENDPOINT) > 0) ? process.env.REACT_APP_PROXY_ENDPOINT : "/",

  ADD_TO_APPLE_WALLET_START: "ADD_TO_APPLE_WALLET_START",
  ADD_TO_APPLE_WALLET_STOP: "ADD_TO_APPLE_WALLET_STOP",
  ADD_TO_APPLE_WALLET_COMPLETE: "ADD_TO_APPLE_WALLET_COMPLETE",

  COGNITO_AUTH_SUCCESS: "COGNITO_AUTH_SUCCESS",
  COGNITO_AUTH_FAILURE: "COGNITO_AUTH_FAILURE",
  COGNITO_LOGOUT_SUCCESS: "COGNITO_LOGOUT_SUCCESS",
  COGNITO_LOGOUT_FAILURE: "COGNITO_LOGOUT_FAILURE",
  COGNITO_AUTH_SESSION_SUCCESS: "COGNITO_AUTH_SESSION_SUCCESS",
  COGNITO_AUTH_SESSION_FAILURE: "COGNITO_AUTH_SESSION_FAILURE",
  GET_PAYMENT_TOKEN_SUCCESS: "GET_PAYMENT_TOKEN_SUCCESS",
  GET_PAYMENT_TOKEN_FAILED: "GET_PAYMENT_TOKEN_FAILED",
  REQUEST_START: "REQUEST_START",
  PAYMENT_TOKEN_ACCEPTED: "PAYMENT_TOKEN_ACCEPTED",
  PAYMENT_TOKEN_ACCEPTED_FAILED: "PAYMENT_TOKEN_ACCEPTED_FAILED",
  REQUEST_STOP: "REQUEST_STOP",
  CHECK_ORDER_STATUS_START: "CHECK_ORDER_STATUS_START",
  CHECK_ORDER_STATUS_COMPLETE: "CHECK_ORDER_STATUS_COMPLETE",
  CHECK_ORDER_STATUS_FAILED: "CHECK_ORDER_STATUS_FAILED",
  COGNITO_AUTHENTICATED_USER:"COGNITO_AUTHENTICATED_USER",
  COGNITO_AUTHENTICATED_USER_ERROR:"COGNITO_AUTHENTICATED_USER_ERROR",
  COGNITO_AUTHENTICATED_USER_END:"COGNITO_AUTHENTICATED_USER_END",
  COGNITO_SIGNUP_SUCCESS:"COGNITO_SIGNUP_SUCCESS",
  COGNITO_SIGNUP_FAILED:"COGNITO_SIGNUP_FAILED",
  COGNITO_CONFIRM_SIGNUP_SUCCESS:"COGNITO_CONFIRM_SIGNUP_SUCCESS",
  COGNITO_CONFIRM_SIGNUP_FAILED:"COGNITO_CONFIRM_SIGNUP_FAILED",

  CHECK_ORDER_STATUS_STOP: "CHECK_ORDER_STATUS_STOP",
  FETCH_TICKETS_START: "FETCH_TICKETS_START",
  FETCH_TICKETS_COMPLETE: "FETCH_TICKETS_COMPLETE",
  FETCH_TICKETS_FAILED: "FETCH_TICKETS_FAILED",
  CLEAR_TICKET_DATA: "CLEAR_TICKET_DATA",
  FETCH_TICKETS_STOP: "FETCH_TICKETS_STOP",
  EMAIL_VALIDATE_START: "EMAIL_VALIDATE_START",
  EMAIL_VALIDATED: "EMAIL_VALIDATED",
  EMAIL_VALIDATE_STOP: "EMAIL_VALIDATE_STOP",
  EMAIL_VALIDATE_ERROR: "EMAIL_VALIDATE_ERROR",
  SWITCH_LOGIN_MODAL_ON_OFF:"SWITCH_LOGIN_MODAL_ON_OFF",
  SWITCH_LOGIN_MODAL_ON_OFF_ERROR:"SWITCH_LOGIN_MODAL_ON_OFF_ERROR",
  SWITCH_SIGNUP_MODAL_ON_OFF : "SWITCH_SIGNUP_MODAL_ON_OFF",
  SWITCH_SIGNUP_MODAL_ON_OFF_ERROR : "SWITCH_SIGNUP_MODAL_ON_OFF_ERROR",

  COGNITO_FORGOTPWD_SUCCESS: "COGNITO_FORGOTPWD_SUCCESS",
  COGNITO_FORGOTPWD_FAILED: "COGNITO_FORGOTPWD_FAILED",

  COGNITO_CONFIRM_FORGOTPWD_SUCCESS: "COGNITO_CONFIRM_FORGOTPWD_SUCCESS",
  COGNITO_CONFIRM_FORGOTPWD_FAILED :"COGNITO_CONFIRM_FORGOTPWD_FAILED",

  UPDATE_EMAIL_SUCCESS: "UPDATE_EMAIL_SUCCESS",
  UPDATE_EMAIL_FAILED :"UPDATE_EMAIL_FAILED",

  VIEW_USER_ACCOUNTS:"VIEW_USER_ACCOUNTS",
  VIEW_USER_ACCOUNTS_FAILED:"VIEW_USER_ACCOUNTS_FAILED",

  UPDATE_USER_ACCOUNTS:"UPDATE_USER_ACCOUNTS",
  UPDATE_USER_ACCOUNTS_FAILED:"UPDATE_USER_ACCOUNTS_FAILED",

  SWITCH_PROFILE_MODAL_ON_OFF : "SWITCH_PROFILE_MODAL_ON_OFF",
  SWITCH_PROFILE_MODAL_ON_OFF_ERROR :"SWITCH_PROFILE_MODAL_ON_OFF_ERROR",

  VIEW_USER_CREDIT_CARDS :"VIEW_USER_CREDIT_CARDS",
  VIEW_USER_CREDIT_CARDS_FAILED :"VIEW_USER_CREDIT_CARDS_FAILED",

  SWITCH_TO_ADD_CARD : "SWITCH_TO_ADD_CARD",
  SWITCH_TO_ADD_CARD_ERROR : "SWITCH_TO_ADD_CARD_ERROR",

  SAVE_CREDIT_CARDS:"SAVE_CREDIT_CARDS",
  SAVE_CREDIT_CARDS_FAILED:"SAVE_CREDIT_CARDS_FAILED",
  FETCH_ALL_BOOKED_TICKETS_SUCCESS: "FETCH_ALL_BOOKED_TICKETS_SUCCESS",
  FETCH_ALL_BOOKED_TICKETS_FAILED:"FETCH_ALL_BOOKED_TICKETS_FAILED",
  DELETE_CREDIT_CARDS : "DELETE_CREDIT_CARDS",
  DELETE_CREDIT_CARDS_FAILED : "DELETE_CREDIT_CARDS_FAILED",

  GET_CYBERSOURCE_JWK : "GET_CYBERSOURCE_JWK",
  GET_CYBERSOURCE_JWK_FAILED : "GET_CYBERSOURCE_JWK_FAILED",

  GET_ONE_TIME_TOKEN : "GET_ONE_TIME_TOKEN",
  GET_ONE_TIME_TOKEN_FAILED : "GET_ONE_TIME_TOKEN_FAILED",

  SECOND_VALUE : "SECOND_VALUE",
  SECOND_VALUE_FAILED : "SECOND_VALUE_FAILED",

  GET_RECURRING_TOKEN : "GET_RECURRING_TOKEN",
  GET_RECURRING_TOKEN_FAILED : "GET_RECURRING_TOKEN_FAILED",

  VIEW_CART_ITEMS: "VIEW_CART_ITEMS",
  VIEW_CART_ITEMS_FAILED: "VIEW_CART_ITEMS_FAILED",

  GET_ACCOUNTS_DATA_FOR_MODAL : "GET_ACCOUNTS_DATA_FOR_MODAL",
  GET_ACCOUNTS_DATA_FOR_MODAL_ERROR : "GET_ACCOUNTS_DATA_FOR_MODAL_ERROR",

  TRANSLATION_ITEMS: "TRANSLATION_ITEMS",
  TRANSLATION_ITEMS_FAILED: "TRANSLATION_ITEMS_FAILED",

  SET_AIRPORT_ORIGIN: 'SET_AIRPORT_ORIGIN',
	SET_AIRPORT_DESTINATION: 'SET_AIRPORT_DESTINATION',
	SET_AIRPORT_DATE: 'SET_AIRPORT_DATE',
	SET_AIRPORT_TIME: 'SET_AIRPORT_TIME',
	UPDATE_TICKET_FARE_RULE_DATA: 'UPDATE_TICKET_FARE_RULE_DATA',
  TICKET_FARE_RULE_FETCH_STATUS:'TICKET_FARE_RULE_FETCH_STATUS',
  FETCH_LOCATION_START: 'FETCH_LOCATION_START',
  FETCH_SELECTED_DATA: 'FETCH_SELECTED_DATA',

};

export default appConstants;
