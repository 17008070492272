import React from 'react';
import { Card, Button} from 'react-bootstrap';
import Theme from '../Theme/index';
import CrossImg from '../../../../assets/images/cross.svg';
import {  withRouter } from 'react-router-dom';
import { compose } from 'redux';


const TransactionFailed=({history})=> {

    const handleOkSearch =()=>{
        history.push('./home');
    }

return (
	<Theme className="ticket-generate-content postion-relative">
		<Card className="checkout_Final">
			<Card.Body>
				<img src={CrossImg} alt="CrossImg" />
				<h3 className="mt-4">Your transaction number is. Please</h3>
                <div className="message mt-2 mb-4">confirm order in Tableau report.</div>
				<Button className="btn" onClick={handleOkSearch}>OK</Button>
			</Card.Body>
		</Card>		
	</Theme>
		);
}


export default compose(withRouter)(TransactionFailed);;
