const initialState = {
  orderData: {},
  isLoading: false,
  showSpinner: false,
  orderError:{}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_ORDER_STATUS_START":
      return {
        ...state,
        isLoading: action.payload,
        
      };
    case "CHECK_ORDER_STATUS_STOP":
      return {
        ...state,
        isLoading: action.payload
      };
    case "CHECK_ORDER_STATUS_COMPLETE":
      return {
        ...state,
        orderData: action.payload
        
      };
    case "CHECK_ORDER_STATUS_FAILED":
      return {
        ...state,
        orderError: action.payload
      }
    default:
      return state;
  }
};
