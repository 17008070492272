import * as FullStory from '@fullstory/browser';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import React from 'react';
import { Accordion, Card, Navbar } from 'react-bootstrap';
import { FaAngleDown, FaAngleRight, FaBars } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import Logo from '../../../../assets/images/COACHUSA_logo.svg';
import Close from '../../../../assets/images/Close.svg';
import MyAccount from '../../../../assets/images/MyAccount.svg';
import BasketImg from '../../../../assets/images/basket.svg';
import { signOut } from "../../utils/auth";
import Loader from '../Loader/Loader';

class Header extends React.Component {
	constructor (props) {
		super(props);
		//this.userData = {};
		this.state = {
			isMobile: false,
			isLoading: false,
			sidebarAccordionToggle: false,
			userData: JSON.parse(localStorage.getItem('cognitoUser'))
		};
	}

	componentDidMount () {
		if (window.innerWidth < 922) {
			this.setState({ isMobile: true });
		} else {
			this.setState({ isMobile: false });
		}
		const userData = _.get(this.state.userData,"attributes",{});
		const userEmailFirst = _.split(_.get(this.state.userData, "emailID", ""), '@');
		const name = _.startCase(userEmailFirst[0]);
		const email = _.get(this.state.userData, "emailID", "")
		FullStory.identify(userData.sub, {
			displayName:
				!_.isEmpty(userData)
					? `${userData.given_name} ${userData.family_name}`
					: name,
		email
		});
	}

	handleLogout = () => {
		this.setState({ isLoading: true });
		Auth.signOut()
			.then((response) => {
				localStorage.removeItem('cognitoUser');
				localStorage.removeItem('orderviewdata');
				window.location.href = '/';
				this.setState({ isLoading: false });
			})
			.catch((err) => {
				localStorage.removeItem('cognitoUser');
				window.location.href = '/';
				this.setState({ isLoading: false });
			});
	};

	handleSidebarAccordionToggle = () => {
		this.setState((state) => ({
			sidebarAccordionToggle: !state.sidebarAccordionToggle
		}));
	};

	handleCart = (cartTotal) => {
		if (cartTotal > 0) {
			this.props.history.push('./shoppingcart');
		}
	};

	handleCartClear = (e, cartTotal) => {
		e.preventDefault();
		if (cartTotal > 0) this.props.handleEmptyCart();
	};

	render () {
		//const userName = this.state.userData.attributes.email;
		const cartTotal = _.get(this.props, 'ticketInfo.cart_total_tickets', 0);
		this.cartid = _.get(this.props.ticketInfo, 'cartId');

		return (
			<div>
				<img src={Close} alt="Close" className="d-none" />
				{this.state.isLoading && <Loader />}
				<Navbar className="bg-white">
					<a href="/home" className="navbar-brand">
						<img src={Logo} width="100%" height="30" alt="Logo" />
					</a>
					<Navbar className="ml-auto">
		<Navbar.Text className="agent-name">{this.state.userData.attributes.given_name} {this.state.userData.attributes.family_name}</Navbar.Text>
						<Navbar.Text className="agent-icon">
							<img src={MyAccount} alt="MyAccount" />
						</Navbar.Text>
						{parseInt(cartTotal) > 0 ? this.cartid !== null ? (
							<button type="button" className="basket" onClick={() => this.handleCart(cartTotal)}>
								<img src={BasketImg} alt="cart" onClick={() => this.handleCart(cartTotal)} />
								<span className="nav-link-count"> {parseInt(cartTotal) > 0 ? cartTotal : ''} </span>
							</button>
						) : null : null}
						<Navbar.Text className="sidebar-icon" onClick={this.props.handleSidebar}>
							{this.props.sidebar ? <img src={Close} alt="Close" style={{height: '1em', width: '1em'}} /> : <FaBars />}
						</Navbar.Text>
					</Navbar>
				</Navbar>
				<div className="sidenav d-print-none" style={{ width: `${this.props.sidebar}%`, position: this.props.sidebar===0?'relative':null }}>
					<div className="sidenav-menu">
						<ul className="sidenav-menu-links">
							<li>
								<Link to="/home">Ticket Sales</Link>
							</li>
							<li>
								<a href="#/" onClick={(e) => this.handleCartClear(e, cartTotal)}>
									Clear Cart
								</a>
							</li>
							<li>
								<Accordion>
									<Accordion.Toggle
										as="a"
										variant="link"
										eventKey="0"
										onClick={this.handleSidebarAccordionToggle}>
										<span>
											{this.state.sidebarAccordionToggle ? <FaAngleDown /> : <FaAngleRight />}
										</span>
										<span>Tech Support</span>
									</Accordion.Toggle>
									<Accordion.Collapse eventKey="0">
										<Card>
											<Card.Body>
												If you are experiencing technical issues please contact Coach USA
												support team at IT.Support@coachusa.com
											</Card.Body>
										</Card>
									</Accordion.Collapse>
								</Accordion>
							</li>
							<li>
								<Link to="/journal">Journal</Link>
							</li>
							<li>
								<Link to="/paidin">Paid In</Link>
							</li>
							<li>
								<Link to="/cashout">End Shift</Link>
							</li>
							<li>
								<Link onClick={() => signOut()} to="#">Log Out</Link>
							</li>
						</ul>
					</div>
				</div>
				<div className="header-layer no-print" />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authReducer.isAuthenticated,
	oneWayOrder: state.busFares.oneWayOrder,
	roundTripOrder: state.busFares.roundTripOrder,
	ticketInfo: state.busFares.ticketInfo,
	display: state.busFares.display,
	data: state.busFares.data,
	authUser: state.authReducer.authUser,
	showLoginToggle: state.headerReducer.showLoginToggle,
	showSignUpToggle: state.headerReducer.showSignUpToggle,
	logOutRespose: state.authReducer.logOutRespose,
	showConfirmModal: state.profileReducer.showConfirmModal,
	userAccountsData: state.profileReducer.userAccountsData,
	updateUserResultError: state.profileReducer.updateUserResultError
});

const mapDispatchToProps = (dispatch) => ({
	handleEmptyCart: () => dispatch({ type: 'EMPTY_CART' })
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Header);
