import React, { Fragment } from "react";
import { map, range, replace, split, thru } from "lodash";
import moment from "moment";
// import { Table } from "react-bootstrap";
import _ from "lodash";
import momenttimezone from "moment-timezone";
class DriverPrintReceipt extends React.Component {
  render() {
    const userDetails = JSON.parse(localStorage.getItem("cognitoUser"));
    const sone = momenttimezone.tz.guess();
    const timezone = momenttimezone.tz(sone).zoneAbbr();
    const renderTableHeader = () => {
      return <tr className="bold">
        <td>Issuing OPR</td>
        <td>Run NO</td>
        <td align="center">QTY</td>
        <td>AMOUNT</td>
      </tr>

    }
    return (
      <Fragment>
        {map(range(3), (page, key) => (
          <div
            className="driver-print-cont print-cont"
            style={{ pageBreakAfter: "always" }}
            key={`driverPrintReceipt-${key}`}
          >
            <table className="ticket_header">
              <tbody>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <p className="agentreceiptpaid">RECEIPT</p>
                    <p className="receiptpaidIn">THIS IS NOT A TICKET</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="pr_receipt_block">
              <div>
                <b>DATE OF LOG:</b>{" "}
                {moment(new Date(this.props.state.date)).format("MM/DD/YYYY")}
              </div>
              <div>
                <b>SUBMITTED BY:</b> DRIVER ID {this.props.state.driverId}
              </div>

              <div>
                <b>No. of Envelopes:</b>{" "}
                {parseInt(this.props.state.envelopesNo?.value)}
              </div>
            </div>

            <table className={parseInt(this.props.state.envelopesNo?.value) > 7 ? "paid_receipt_total tr-8":"paid_receipt_total"}>
              {/* <thead>
                <tr>
                 <td>Issuing OPR</td>
                  <td>Run NO</td>
                  <td>QTY</td>
                  <td>AMOUNT</td>                  
                </tr>                    
              </thead> */}
              <tbody>
                {renderTableHeader()}
                {map(
                  range(1, this.props.state.envelopesNo?.value + 1),
                  (i) => {
                    return (
                      <>
                        {(i == 8) &&
                          <>
                          <tr className={(i == 8) && "tr-8header"}>
                              <td colspan="4" className="border-b-0">
                                <table className="ticket_header">
                                  <tbody>
                                    <tr>
                                      <td>&nbsp;</td>
                                      <td>
                                        <p className="agentreceiptpaid">RECEIPT</p>
                                        <p className="receiptpaidIn">THIS IS NOT A TICKET</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            {renderTableHeader()}
                          </>
                        }
                        <tr className="bold" key={i}>

                          <td>
                            {!_.isEmpty(this.props.issuingOPR)
                              ? _.toUpper(
                                _.get(this.props.issuingOPR[i - 1], "code")
                              )
                              : null}
                          </td>
                          <td>{this.props.runTripNumber[i - 1]}</td>
                          <td align="center">1</td>
                          <td>
                            $
                          {parseFloat(
                            replace(
                              replace(this.props.totalAmounts[i - 1], "$", ""),
                              /,/g,
                              ""
                            )
                          ).toFixed(2)}
                          </td>
                        </tr>
                      </>
                    );
                  }
                )}

                <tr className="bold">
                  <td>Total Amount</td>
                  <td></td>
                  <td align="center">
                    {parseInt(this.props.state.envelopesNo?.value)}
                  </td>
                  <td>
                    $
                    {parseFloat(
                    replace(
                      replace(this.props.state.amount, "$", ""),
                      /,/g,
                      ""
                    )
                  ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={(parseInt(this.props.state.envelopesNo?.value) >= 5 && parseInt(this.props.state.envelopesNo?.value) <= 7) ? "paid_receipt_bottom" : "paid_receipt_total"}>
              <div>
                <b>Transaction No:</b>{" "}
                {split(this.props.state.transactionID, "-")[0]}
              </div>
              <div>
                <b>Transaction Date:</b>{" "}
                {moment(new Date()).format("MM/DD/YYYY")}
              </div>
              <div>
                <b>Transaction Time:</b> {moment(new Date()).format("hh:mm A")}{" "}
                {timezone}
              </div>
            </div>
            <table className={parseInt(this.props.state.envelopesNo?.value) == 4 ? "paidIn_receipt_footer" : "pr_receipt_footer"}>
              <tbody>
                <tr>
                  <td>AGENT ID {userDetails.userEmail}</td>
                  <td>Page {page + 1}/3</td>
                </tr>
              </tbody>
            </table>
          </div>

        ))}
      </Fragment>
    );
  }
}

export default DriverPrintReceipt;
