const cardInfo = {
  cardHolderRegExp : /^[-a-zA-Z' ]{2,26}$/,
  cardOptions: [
    { value: "001", label: "Visa" },
    { value: "002", label: "Mastercard" },
    { value: "003", label: "American Express" },
    { value: "004", label: "Discover" },
    { value: "005", label: "Diners Club" },
    { value: "006", label: "Carte Blanche" },
    { value: "007", label: "JCB" },
    { value: "014", label: "EnRoute" },
    { value: "021", label: "JAL" },
    { value: "024", label: "Maestro (UK Domestic)" },
    { value: "031", label: "Delta" },
    { value: "033", label: "Visa Electron" },
    { value: "034", label: "Dankort" },
    { value: "036", label: "Cartes Bancaires" },
    { value: "037", label: "Carta Si" },
    { value: "042", label: "Maestro (International)" },
    { value: "043", label: "GE Money UK card" }
  ],

  countryOptions: [
    { value: "US", label: "United States" }
    // { value: 'CA', label: 'Canada' },
  ],

  statesOptions: [
    { value: "AL", label: "Alabama", country: "US" },
    { value: "AK", label: "Alaska", country: "US" },
    { value: "AS", label: "American Samoa", country: "US" },
    { value: "AZ", label: "Arizona", country: "US" },
    { value: "AR", label: "Arkansas", country: "US" },
    { value: "CA", label: "California", country: "US" },
    { value: "CO", label: "Colorado", country: "US" },
    { value: "CT", label: "Connecticut", country: "US" },
    { value: "DE", label: "Delaware", country: "US" },
    { value: "DC", label: "District of Columbia", country: "US" },
    { value: "FM", label: "Federated States of Micronesia", country: "US" },
    { value: "FL", label: "Florida", country: "US" },
    { value: "GA", label: "Georgia", country: "US" },
    { value: "GU", label: "Guam", country: "US" },
    { value: "HI", label: "Hawaii", country: "US" },
    { value: "ID", label: "Idaho", country: "US" },
    { value: "IL", label: "Illinois", country: "US" },
    { value: "IN", label: "Indiana", country: "US" },
    { value: "MT", label: "Montana", country: "US" },
    { value: "NE", label: "Nebraska", country: "US" },
    { value: "NV", label: "Nevada", country: "US" },
    { value: "NH", label: "New Hampshire", country: "US" },
    { value: "NJ", label: "New Jersey", country: "US" },
    { value: "NM", label: "New Mexico", country: "US" },
    { value: "NY", label: "New York", country: "US" },
    { value: "NC", label: "North Carolina", country: "US" },
    { value: "ND", label: "North Dakota", country: "US" },
    { value: "MP", label: "Northern Mariana Islands", country: "US" },
    { value: " OH", label: "Ohio", country: "US" },
    { value: "OK", label: "Oklahoma", country: "US" },
    { value: "OR", label: "Oregon", country: "US" },
    { value: "PW", label: "Palau", country: "US" },
    { value: "PA", label: "Pennsylvania", country: "US" },
    { value: "PR", label: "Puerto Rico", country: "US" },
    { value: "RI", label: "Rhode Island", country: "US" },
    { value: "SC", label: "South Carolina", country: "US" },
    { value: "IA", label: "Iowa", country: "US" },
    { value: "KS", label: "Kansas", country: "US" },
    { value: "KY", label: "Kentucky", country: "US" },
    { value: "LA", label: "Louisiana", country: "US" },
    { value: "ME", label: "Maine", country: "US" },
    { value: "MH", label: "Marshall Islands", country: "US" },
    { value: "MD", label: "Maryland", country: "US" },
    { value: "MA", label: "Massachusetts", country: "US" },
    { value: "MI", label: "Michigan", country: "US" },
    { value: "MN", label: "Minnesota", country: "US" },
    { value: "MS", label: "Mississippi", country: "US" },
    { value: "MO", label: "Missouri", country: "US" },
    { value: "SD", label: "South Dakota", country: "US" },
    { value: "TN", label: "Tennessee", country: "US" },
    { value: "TX", label: "Texas", country: "US" },
    { value: "UT", label: "Utah", country: "US" },
    { value: "VT", label: "Vermont", country: "US" },
    { value: "VI", label: "Virgin Islands", country: "US" },
    { value: "VA", label: "Virginia", country: "US" },
    { value: "WA", label: "Washington", country: "US" },
    { value: "WV", label: "West Virginia", country: "US" },
    { value: "WI", label: "Wisconsin", country: "US" },
    { value: "WY", label: "Wyoming", country: "US" },

    { value: "AB", label: "Alberta", country: "CA" },
    { value: "BC", label: "British Columbia", country: "CA" },
    { value: "MB", label: "Manitoba", country: "CA" },
    { value: "NB", label: "New Brunswick", country: "CA" },
    { value: "NL", label: "Newfoundland and Labrador", country: "CA" },
    { value: "NT", label: "Northwest Territories", country: "CA" },
    { value: "NS", label: "Nova Scotia", country: "CA" },
    { value: "NU", label: "Nunavut", country: "CA" },
    { value: "ON", label: "Ontario", country: "CA" },
    { value: "PE", label: "Prince Edward Island", country: "CA" },
    { value: "QC", label: "Quebec", country: "CA" },
    { value: "SK", label: "Saskatchewan", country: "CA" },
    { value: "YT", label: "Yukon", country: "CA" }
  ],

  cityOptions: [
    { label: "Alexander City", label2: "Alexander City", value: "AL" },
    { label: "Andalusia", label2: "Andalusia", value: "AL" },
    { label: "Anniston", label2: "Anniston", value: "AL" },
    { label: "Anchorage", label2: "Anchorage", value: "AK" },
    { label: "Cordova", label2: "Cordova", value: "AK" },
    { label: "Fairbanks", label2: "Fairbanks", value: "AK" },
    { label: "Banff", label2: "Banff", value: "AB" },
    { label: "Brooks", label2: "Brooks", value: "AB" },
    { label: "Calgary", label2: "Calgary", value: "AB" }
  ],

  getCardExpiry(exp) {
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonth = d.getMonth() + 1;
    const currentDate = d.getDate();
    // get parts of the expiration date
    var parts = exp.split("/");
    var year = parseInt(parts[1], 10) + 2000;
    //var month = parseInt(parts[0], 10);
    var month = parseInt(parts[0]);
    let expiryError = null;
    if (year < currentYear) {
      expiryError = "Payment Type has Expired - Please Confirm";
    } else {
      if (year > currentYear || year === currentYear) {
        let date2 = new Date();
        if (
          month === 1 ||
          month === 3 ||
          month === 5 ||
          month === 7 ||
          month === 8 ||
          month === 10 ||
          month === 12
        ) {
          date2 = new Date('"' + year + "-" + month + "-" + 31 + '"');
        } else if (month === 4 || month === 6 || month === 9 || month === 11) {
          date2 = new Date('"' + year + "-" + month + "-" + 30 + '"');
        } else if (month === 2) {
          let isLeap = new Date(year, 1, 29).getDate() === 29;

          if (isLeap) {
            date2 = new Date('"' + year + "-" + month + "-" + 29 + '"');
          } else {
            date2 = new Date('"' + year + "-" + month + "-" + 28 + '"');
          }
        }

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const date1 = new Date(currentYear, currentMonth, currentDate);

        const diffDays = Math.round(Math.abs((date1 - date2) / oneDay));

        if (diffDays <= 60) {
          expiryError = "To manage a card, please delete and add it again";
        } else {
          expiryError = null;
        }
      }
    }

    return expiryError;
  }

 
  
};

export default cardInfo;