import React from "react";

const errMsgConfig = {
    getErrorMsg(msg, type) {
        let failedError = {};
        switch (type) {
            case "Jwk":

                failedError = {
                    body: <div className="card-declined-popup">
                        <h3 style={{color: '#263238'}}>Unable to Save</h3>
                        {/* <p> {msg} </p> */}
                        <p style={{fontSize: '20px'}}>We were unable to complete your request.<br/> 
                            Please try again.</p>
                    </div>
                };
                break;
            case "Recurring":
                failedError = {
                    body: <div className="card-declined-popup">
                        <h3 style={{color: '#263238'}}>Unable to Save</h3>
                        {/* <p> {msg} </p> */}
                        <p style={{fontSize: '20px'}}>We were unable to complete your request.<br/> 
                            Please try again.</p>
                    </div>
                };
                break;

            case "Flex":
                failedError = {
                    body: <div className="card-declined-popup">
                        <h3 style={{color: '#263238'}}>Unable to Save</h3>
                        {/* <p> {msg} </p> */}
                        <p style={{fontSize: '20px'}}>We were unable to complete your request.<br/> 
                            Please try again.</p>
                    </div>
                };
                break;

            default:

                break;
        }
        return failedError.body;
    },

    failedCard: {
        body: <div className="card-declined-popup">
            <h3> Failed Authentication</h3>
            <p>Card declined. Please contact your payment card company for more details. To continue your transaction, please use another card.</p>

        </div>
    },
    failedPayment: {
        body: <div className="card-declined-popup">
            <h3> Transaction Failed</h3>
            <p style={{ fontSize: '20px' }}>
                Please contact Customer Service Team at <br />
                866-912-6224 or email us at <br />
                <a href="mailto:questions@coachusa.com">questions@coachusa.com</a>
                {/* Please email Customer Support team at <a href="mailto:shortlinecustomerservice@coachusa.com">shortlinecustomerservice@coachusa.com</a>. <br/>Please note: While your card(s) have not be charged, a temporary authorization may remain the card(s) for 24-72 hours. Please contact your bank for more information. */}
            </p>
        </div>

    },
    failedFunds: {
        body: <div className="card-declined-popup">
            <h3> Balance Insufficient </h3>
            <p>Card declined for insufficient funds. Please contact your payment card company for more details. To continue your transaction, please use another card.</p>
        </div>
    },

    failedFlex: {
        body(apiMsg) {
            return (<div className="card-declined-popup">
                <h3> Failed Authentication</h3>
                Card declined could be due to below reasons:<br />
                <table>{apiMsg}</table><br />
                To continue your transaction, please try again or enter a different credit card.
            </div>)
        }
    },
    // failedOrder:{
    //     body:<div className="card-declined-popup">
    //     <h3> Payment Order Failed</h3>
    //     <p>
    //         Payment not completed. Please call customer service team at xxxxxxxxx.
    //     </p>
    // </div>
    // },
    failedTicket: {
        body: <div className="card-declined-popup">
            <h3> Ticket Generation Failed</h3>
            <p>Ticket is not generated but you can view your ticket from your email or your accounts.
            </p>
        </div>
    },



};

export default errMsgConfig;
