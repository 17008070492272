import React, { useState } from 'react';
import * as QueryString from 'query-string';
import _ from "lodash";

export const TicketContext = React.createContext({
  allBarcodesLoaded: false,
  barcodeLoadStatus: [],
  initDone: false,
  setAllBarcodesLoaded: () => {},
  setBarcodeStatus: () => {},
  setInitDoneContext: () => {}
});

export const TicketContextProvider = (props) => {

  const setInitDoneContext = () => {
    setState({...state, initDone: true});
  }

  const setBarcodeStatus = (barcodeId, barcodeStatus, callback) => {
    barcodeLoadStatus[barcodeId] = barcodeStatus;
    if (barcodeStatus) {
      let allBarCodesLoadedCheck = true;
      for (const [key, value] of Object.entries(barcodeLoadStatus)) {
        if (!value) allBarCodesLoadedCheck = false;
      }
      if (allBarCodesLoadedCheck) setAllBarcodesLoaded();
    }
  }

  const setAllBarcodesLoaded = () => {
    setState({...state, setAllBarcodesLoaded: true});
    const params = QueryString.parse(window.location.search);
    let order_id = '';
    if (!_.isNil(params.order_id)) {
      order_id = params.order_id;
    }
    const order_id_printed = order_id + '_printed';
    const alreadyPrinted = localStorage.getItem(order_id_printed);
    if (alreadyPrinted === null) {
      localStorage.setItem(order_id_printed, 'true');
      window.setTimeout(() => window.print(), 750);
      window.onafterprint = function(){
        window.location.reload();
     }
    }
  }

  const barcodeLoadStatus = [];

  const initState = {
    allBarcodesLoaded: false,
    initDone: false,
    setAllBarcodesLoaded: setAllBarcodesLoaded,
    setBarcodeStatus:setBarcodeStatus,
    setInitDoneContext: setInitDoneContext,
  }

  const [state, setState] = useState(initState);

  return (
    <TicketContext.Provider value={state}>
      {props.children}
    </TicketContext.Provider>
  );
}
