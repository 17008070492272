import React from 'react'

const DownArrow = require("../style/Dashboard/img/new-dropdown-arrow-blue.svg");

export const selectStyle = {
  control: (styles) => ({ ...styles, minHeight: "60px" }),
  indicatorSeparator: () => ({ display: "none", border: "1px solid #A8A8A8" }),
  placeholder: (styles) => ({ ...styles, color: "#279CF7", 'textTransform': 'uppercase', 'marginLeft': '8px'  }),
  singleValue: (styles) => ({ ...styles, color: "#279CF7", 'textTransform': 'uppercase', 'marginLeft': '8px' }),
};

export const DropdownIndicator = () => {
  return (
    <img
      src={DownArrow}
      alt=""
      width={16}
      height={9}
      style={{ marginRight: "10px" }}
    />
  );
};
