import ApiNew from "./apiNew";

const sendCashoutReportRequest = (emailId, mode) => {
  const options = {
    headers: { "x-api-key": process.env.REACT_APP_ORBIT_API_KEY },
  };

  const postData = {
    emailId,
    mode,
  };

  return ApiNew.post(
    process.env.REACT_APP_CASHOUT_REQUEST_API_ENDPOINT,
    postData,
    options
  );
};

export const previewCashoutReport = (emailId) =>
  sendCashoutReportRequest(emailId, "preview");

export const commitCashoutReport = async (emailId) => {
  try {
    const res = await sendCashoutReportRequest(emailId, "commit");
    return { statusCode: res.data.orbitResponse.statusCode };
  } catch (error) {
    return { error };
  }
};
