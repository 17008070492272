import api from "../../utils/api/api";

const busFaresActions = {
  fetchByTicket: productId => async dispatch => {
    dispatch({
      type: "FETCH_PRODUCT_FARE_START",
      payload: true
    });
    const resApi = await api.fetch(
      `${process.env.REACT_APP_FARE_API_ENDPOINT}${productId}`
    );
    if (resApi.data.orbitResponse !== "") {
      dispatch({
        type: "FETCH_PRODUCT_FARE_STOP",
        payload: false
      });
      return dispatch({
        type: "FETCH_PRODUCT_FARE_TICKET",
        payload: resApi.data.orbitResponse[0]
      });
    } else {
     
      return dispatch({
        type: "FETCH_PRODUCT_FARE_STOP",
        payload: false
      });
    }
  },
  fetchBusFares: (od) => async dispatch => {
    dispatch({
      type: "FETCH_PRODUCT_FARE_START",
      payload: true
    });

    const resApi = await api.fetch(
      `${process.env.REACT_APP_FARE_BYFZ_API_ENDPOINT}?${od}`
    );

         
   
    if (resApi.data.orbitResponse !== "") { 
      dispatch({
        type: "FETCH_PRODUCT_FARE",
       
        payload: resApi.data.orbitResponse
      });
      dispatch({
        type: "PAYMENT_INIT"
      });
    }
    return dispatch({
      type: "FETCH_PRODUCT_FARE_STOP",
      payload: false
    });
  },
  

  sendCartInfo: cart => async dispatch => {
    dispatch({
      type: "POST_CART_INFO_START",
      payload: true
    });
  try{
    const resApi = await api.post(
      `${process.env.REACT_APP_CART_SAVE_API_ENDPOINT}`,
      cart
    );
    dispatch({
      type: "POST_CART_INFO_START",
      payload: false
    });

    if (resApi.data.orbitResponse !== "") {
      localStorage.setItem('ticketInfo', JSON.stringify(resApi.data.orbitResponse));
      return dispatch({
        type: "POST_CART_INFO",
        payload: resApi.data.orbitResponse
      });
    }
  }
  catch(e){
    dispatch({
      type: "POST_CART_INFO_FAILED",
      payload: e.message
    });
    return dispatch({
      type: "POST_CART_INFO_START",
      payload: false
    });
  }
  },

  setOneWayOrder: oneWayticket => async dispatch => {
    return dispatch({
      type: "SET_ONEWAY_ORDER",
      payload: oneWayticket
    });
  },

  setRoundTripOrder: roundTripTicket => async dispatch => {
    return dispatch({
      type: "SET_ROUNDTRIP_ORDER",
      payload: roundTripTicket
    });
  },
  addMultiRoundTicket: () => async dispatch => {
    return dispatch({
      type: "ADD_ONE"
    });
  },
  subtractMultiRoundTicket: () => async dispatch => {
    return dispatch({
      type: "SUBTRACT_ONE"
    });
  },
  updateOneWayCount: (oneWayCountObj) => async dispatch => {
    dispatch({
      type:"UPDATE_ONEWAY_COUNT",
      payload: oneWayCountObj
    })
  },
  updateRoundWayCount: (roundWayCountObj) => async dispatch => {
    dispatch({
      type:"UPDATE_ROUNDWAY_COUNT",
      payload: roundWayCountObj
    })
  },
  updateMultiWayCount: (multiWayCountObj) => async dispatch => {
    dispatch({
      type:"UPDATE_MULTIWAY_COUNT",
      payload: multiWayCountObj
    })
  }
};

export default busFaresActions;
