import React, { useReducer } from "react";

const Context = React.createContext({});

const dashboardActionsType = {
  _1: "SET_FORM_DATA",
};

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    formData: {
      originState: null,
      originCity: null,
      destinationState: null,
      destinationCity: null,
      operatorCode: null,
      originFareZone: null,
      destinationFareZone: null,
    },
  });
  const boundActions = {};
  for (let key in actions) {
    boundActions[key] = actions[key](dispatch, state);
  }

  return (
    <Context.Provider value={{ actions: boundActions, state }}>
      {children}
    </Context.Provider>
  );
};

const reducer = (state, action) => {
  if (action.type === dashboardActionsType._1) {
    state.formData = { ...state.formData, ...action.payload.data };
  }
  return {...state};
};

const actions = {
  setFormData: (dispatch) => (data) => {
    return dispatch({
      type: dashboardActionsType._1,
      payload: { data },
    });
  },
};

const DashboardContext = {
  Provider,
  Consumer: Context.Consumer,
  Context,
};

export { DashboardContext };
