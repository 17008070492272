import axios from "axios";
import _,{ get, groupBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loader from '../Loader/Loader';
import Select from "react-select";
import { connect } from "react-redux";
import Api from "../../utils/api/api";
import ApiNew from "../../utils/api/apiNew";
import { DropdownIndicator, selectStyle } from "../../utils/selectStyles";
import Theme from "../Theme";
import { DashboardContext } from "./DashboardContext";
import landingActions from "../../actions/landing/landing";

import BusIcon from "../../style/Dashboard/img/icon-bus-blue.svg";

class Dashboard extends React.Component {

  constructor (props) {
    super(props)
    this.state ={
      locationData:{},
      emptyData:false
    }
  }
  componentDidMount = async () => {
    let cognitoUser = localStorage.getItem("cognitoUser");
    if (!cognitoUser) {
      this.props.history.push("/");
      return;
    }
  let response = await this.props.fetchByLocation();

   if(!response.emptyData){
      this.setState({
        locationData: response.locationList,
        emptyData: response.emptyData
      })
   }
   else{
    this.setState({
      locationData: response.defaultLocation,
      emptyData: response.emptyData
    })
   }

  };

  render() {
    return (
      <Theme className="dashboard" shouldSpace={false}>
        <RouteBtnComponent />
        <BusRouteForm allLocation = {this.state.locationData} emptyData = {this.state.emptyData}/>
      </Theme>
    );
  }
}

const RouteBtnComponent = () => {

  const [routeList, setrouteList] = useState([]);
  const [ loading, setLoading ] = useState(false);

    async function fetchAgentLocation() {
        try {
      setLoading(true);
          const response = await ApiNew.post(`agent/location/header`, {code: localStorage.getItem("agent_Location")});
      const locations = get(response, "data.orbitResponse.body");
      setLoading(false);
          return locations.map((location) => {
            return { title: location.title, url: location.url, destinationName: location.destinationName, originName:location.originName };
          });
        } catch (e) {
          return [];
        }
      }

      const _getAgentLocation = async () => {
        const agentLocations = await fetchAgentLocation();
        setrouteList(agentLocations);
      };

      useEffect(() => {
        _getAgentLocation();
      }, []);
  const history = useHistory();
  return (
    <Container fluid className="route-wrapper">
      <Container className="route-container">
      {loading && <Loader />}
      <div className="quick-seletion">
      QUICK SELECTION BUTTONS FOR TRAVEL BETWEEN:
      </div>
        <Row>
          <Col>
            <div className="routebtn-container">
              {routeList.map((route) => (
                <button
                  type="button"
                  disabled={!route.url}
                  className="routebtn"
                  onClick={() => {
                    if (route.url) {
                      history.push(route.url);
                    }
                    return;
                  }}
                >
                  <div className="route-display"> <span className="green-dot"></span>{_.truncate(route.originName , {length: '28'})}</div>
                <div className="route-display">  <span className="red-dot"></span>{_.truncate(route.destinationName, {length: '28'})}</div>
                </button>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

const BusRouteForm = ({allLocation, emptyData}) => {
  const {
    actions: { setFormData },
    state: { formData },
  } = useContext(DashboardContext.Context);
  const history = useHistory();
  const [originState, setOriginState] = useState([]);
  const [fareZoneTranslations, setFareZoneTranslations] = useState({});
  const [originCity, setOriginCity] = useState([]);
  const [operators, setOperators] = useState([]);
  const [errors, setErrors] = useState({});

  const [isOriginStateLoading, setOriginStateLoading] = useState(false);
  const [isOriginCityLoading, setOriginCityLoading] = useState(false);
  const [isDestinationStateLoading, setDestinationStateLoading] = useState(
    false
  );
  const [isDestinationCityLoading, setDestinationCityLoading] = useState(false);

  const [destinationState, setDestinationState] = useState([]);
  const [destinationCity, setDestinationCity] = useState([]);

  useEffect(() => {
    _getOriginState();
    Api.fetch("order/translation/fare-zone").then((response) => {
      let translations = {};
      get(response, "data.orbitResponse", []).forEach((t) => {
        translations[t.translation_key] = t.description;
      });
      setFareZoneTranslations(translations);
    });
  }, []);

  /**
   * To fetch the Origin State
   */
  const _getOriginState = async () => {
    setOriginStateLoading(true);
    const originStates = await fetchOriginState();
    setOriginStateLoading(false);
    setOriginState(originStates);
  };

  /**
   * To Fetch the origin city based on given state code.
   *
   * @param {String} state Origin State code
   */
  const _getOriginCity = async (state) => {
    if (state) {
      setOriginCityLoading(true);
      const originCities = await fetchOriginCity(state);
      setOriginCity(originCities);
      setOriginCityLoading(false);
    } else {
      setOriginCity([]);
    }
  };

  /**
   * To Fetch the Destination state based on given Origin State and City code.
   *
   * @param {String} originStateCode Origin State code
   * @param {String} originCityCode Origin City Code
   */
  const _getDestinationState = async (originStateCode, originCityCode) => {
    if (originStateCode && originCityCode) {
      setDestinationStateLoading(true);
      const destinationStates = await fetchDestinationState(
        originStateCode,
        originCityCode
      );
      setDestinationStateLoading(false);
      setDestinationState(destinationStates);
    } else {
      setDestinationState([]);
    }
  };

  /**
   * To Fetch the Destination state based on given Origin State and City code.
   *
   * @param {String} originStateCode Origin State code
   * @param {String} originCityCode Origin City Code
   * @param {String} destinationStateCode Destination State Code
   */
  const _getDestinationCity = async (
    originStateCode,
    originCityCode,
    destinationStateCode
  ) => {
    if (originStateCode && originCityCode && destinationStateCode) {
      setDestinationCityLoading(true);
      const destinationCities = await fetchDestinationCity(
        originStateCode,
        originCityCode,
        destinationStateCode
      );
      setDestinationCityLoading(false);
      setDestinationCity(destinationCities);
    } else {
      setDestinationCity([]);
    }
  };

  /**
   * When Change in Origin State
   */
  useEffect(() => {
    _getOriginCity(get(formData, "originState.value"));
    // eslint-disable-next-line
  }, [formData.originState]);

  /**
   * When Change in Origin City
   */
  useEffect(() => {
    _getDestinationState(
      get(formData, "originState.value"),
      get(formData, "originCity.value")
    );
    // eslint-disable-next-line
  }, [formData.originCity]);

  /**
   * When Change in destination state
   */
  useEffect(() => {
    _getDestinationCity(
      get(formData, "originState.value"),
      get(formData, "originCity.value", ""),
      get(formData, "destinationState.value")
    );
    // eslint-disable-next-line
  }, [formData.destinationState]);

  useEffect(() => {
    const handleDestinationCityChange = async () => {
      let dc = get(formData, "destinationCity.value", "");
      dc = dc.replace("Newark Liberty Airport","Newark Liberty International Airport");
      let oc = get(formData, "originCity.value", "")
      oc = oc.replace("Newark Liberty Airport","Newark Liberty International Airport");

      if (dc) {
        const response = await Api.fetch("fare/byOd", {
          params: {
            originState: apiDataFormat(get(formData, "originState.value", "")),
            originCity: apiDataFormat(oc),
            destinationState: apiDataFormat(
              get(formData, "destinationState.value", "")
            ),
            destinationCity: apiDataFormat(dc),
          },
        });
        const fareZones = get(response, "data.orbitResponse", []);
        const providers = fareZones.map((fareZone) => ({
          code: fareZone.operatorCode,
          name: fareZone.operatorName,
          originFareZone: fareZone.originFareZone,
          destinationFareZone: fareZone.destinationFareZone,
        }));
        // group by operatorName
        const operators = Object.values(groupBy(providers, "code")).slice();
        //group by origin and destination FareZone

        let prePopulateValue = {};
        const operatorsZones = operators.map((operator) => {
          const originFareZoneGroup = groupBy(operator, "originFareZone");
          const originFareZones = Object.keys(originFareZoneGroup).map(
            (originFareZoneKey) => {
              return originFareZoneGroup[originFareZoneKey][0];
            }
          );
          const destinationFareZoneGroup = groupBy(
            operator,
            "destinationFareZone"
          );
          const destinationFareZones = Object.keys(
            destinationFareZoneGroup
          ).map((destinationFareZoneKey) => {
            return destinationFareZoneGroup[destinationFareZoneKey][0];
          });

          if (destinationFareZones.length === 1) {
            prePopulateValue["destinationFareZone"] =
              destinationFareZones[0].destinationFareZone;
          }
          if (originFareZones.length === 1) {
            prePopulateValue["originFareZone"] =
              originFareZones[0].originFareZone;
          }

          return {
            code: operator[0].code,
            name: operator[0].name,
            originFareZones,
            destinationFareZones,
          };
        });

        if (operatorsZones.length === 1) {
          prePopulateValue["operatorCode"] = operatorsZones[0].code;
          setFormData({
            ...formData,
            ...prePopulateValue,
          });
        }
        setOperators(operatorsZones);
      } else {
        setOperators([]);
      }
    };

    handleDestinationCityChange();

    // eslint-disable-next-line
  }, [formData.destinationCity]);

  const handleBuyNow = () => {
    setErrors({});
    if (
      formData.operatorCode &&
      formData.originFareZone &&
      formData.destinationFareZone
    ) {
      history.push(
        `/expresscart?origin_fare_zone=${formData.originFareZone}&destination_fare_zone=${formData.destinationFareZone}&operator_code=${formData.operatorCode}`
      );
    }
    return;
  };

  const fareZonesByCode = React.useMemo(() => {
    const operator = operators.filter(
      (o) => o.code === formData.operatorCode
    )[0];

    if (!formData.operatorCode || !operator) {
      return {
        origins: [],
        destinations: [],
      };
    }

    return {
      origins: operator.originFareZones,
      destinations: operator.destinationFareZones,
    };
  }, [formData.operatorCode, operators]);

useEffect(()=>{
  let locationArray;
  let cityCode;
  let cityName;
  let stateCode;
  let stateName;

  if(!emptyData){
     locationArray =  !_.isEmpty(allLocation) ?  allLocation.map((location) => {
      if(location.code === localStorage.getItem("agent_Location")){
        return { defaultLocation: location.defaultLocation, id: location.id, objectName: location.objectName};
      }
      
    }) : [] ;

    const finalArray = locationArray.filter((item)=> item);

     cityCode = get(finalArray[0], "defaultLocation.cityCode", "") ;
     cityName = get(finalArray[0], "defaultLocation.cityName", "");
     stateCode = get(finalArray[0], "defaultLocation.stateCode", "");
     stateName = get(finalArray[0], "defaultLocation.stateName", "")
  }
  else{
     locationArray =  !_.isEmpty(allLocation) ?  allLocation : [] ;

      cityCode = get(locationArray ,"cityCode", "") ;
      cityName = get(locationArray ,"cityName", "");
      stateCode = get(locationArray,"stateCode", "");
      stateName = get(locationArray ,"stateName", "")
  }
  

  let defaultObjValue = {};
  
  if(cityCode){
    defaultObjValue.originCity = {value: cityCode, label: cityName}
  }
  if(stateCode){
    defaultObjValue.originState = {value: stateCode, label: stateName}
  }

  if(!_.isEmpty(defaultObjValue)){
    setFormData(defaultObjValue)
  }
},[allLocation, emptyData])

  return (
    <Container fluid className="bus-form-wrapper ">
      <Container className="bus-form-container">
        {/** Heading */}
        {/* {JSON.stringify(formData)} */}
        <Row className="bus-form-row">
          <Col className="left" lg={6} md={6} sm={12}>
            <div className="pt-2 pb-2">
              <img
                src={BusIcon}
                className="bus-icon"
                width={25}
                height={24}
                alt="Bus"
              />

              <label className="bus-label">BUS ROUTES</label>
            </div>
          </Col>
          <Col>
            <div className="header-bg"></div>
          </Col>
        </Row>
        {/** End Heading */}

        {/** Origin Row */}
        <Row className="bus-form-row" style={{display : emptyData ?'none' : ''}}>
          <Col className="left" lg={6} md={6} sm={12}>
            <div className="d-flex  justify-content-sm-start justify-content-lq-end justify-content-md-end">
              <label className="bus-label grey">ORIGIN</label>
            </div>
          </Col>
          <Col>
            <Row>
              <Col lg={6} md={6} sm={6}>
                <Select
                  components={{ DropdownIndicator }}
                  styles={selectStyle}
                  isLoading={isOriginStateLoading}
                  placeholder="State / Province"
                  noOptionsMessage={() => "State / Province"}
                  options={originState}
                  value={formData.originState}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      originState: e,
                      originCity: null,
                      destinationState: null,
                      destinationCity: null,
                      operatorCode: null,
                      originFareZone: null,
                      destinationFareZone: null,
                    });
                  }}
                />
              </Col>
              <Col lg={6} md={6} sm={6}>
                <Select
                  components={{ DropdownIndicator }}
                  styles={selectStyle}
                  isLoading={isOriginCityLoading}
                  isDisabled={formData.originState ? false : true}
                  options={originCity}
                  value={formData.originCity}
                  placeholder="City / Town"
                  noOptionsMessage={() => "City / Town"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      originCity: e,
                      destinationState: null,
                      destinationCity: null,
                      operatorCode: null,
                      originFareZone: null,
                      destinationFareZone: null,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/** End Origin Row */}

        {/** Destination Row */}
        <Row className="bus-form-row">
          <Col className="left" lg={6} md={6} sm={12}>
            <div className="d-flex  justify-content-sm-start justify-content-lq-end justify-content-md-end">
              <label className="bus-label grey">DESTINATION</label>
            </div>
          </Col>
          <Col>
            <Row>
              <Col lg={6} md={6} sm={6}>
                <Select
                  components={{ DropdownIndicator }}
                  isLoading={isDestinationStateLoading}
                  styles={selectStyle}
                  isDisabled={
                    formData.originState && formData.originCity ? false : true
                  }
                  placeholder="State / Province"
                  noOptionsMessage={() => "State / Province"}
                  options={destinationState}
                  value={formData.destinationState}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      destinationState: e,
                      destinationCity: null,
                      operatorCode: null,
                      originFareZone: null,
                      destinationFareZone: null,
                    });
                  }}
                />
                <label
                  className="text-danger"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      "https://www.coachusa.com/service-advisories",
                      "_blank"
                    );
                  }}
                >
                  Service Advisory
                </label>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <Select
                  components={{ DropdownIndicator }}
                  isLoading={isDestinationCityLoading}
                  isDisabled={
                    formData.originState &&
                    formData.originCity &&
                    formData.destinationState
                      ? false
                      : true
                  }
                  styles={selectStyle}
                  options={destinationCity}
                  value={formData.destinationCity}
                  placeholder="City / Town"
                  noOptionsMessage={() => "City / Town"}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      destinationCity: e,
                      operatorCode: null,
                      originFareZone: null,
                      destinationFareZone: null,
                    });
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/** End Destination Row */}

        {/** Fare Zone Row */}
        {(operators.length && operators.length > 1) ||
        (formData.operatorCode && fareZonesByCode.origins.length > 1) ||
        (formData.operatorCode && fareZonesByCode.destinations.length > 1) ? (
          <Row className="bus-form-row">
            <Col className="left" lg={6} md={6} sm={12}></Col>
            <Col>
              <div className="fare-zone-wrapper">
                {operators.length && operators.length > 1 ? (
                  <>
                    <h3>CHOOSE YOUR OPERATOR AND ROUTE</h3>
                    <Row>
                      {operators.map((operator) => {
                        return (
                          <Col lg={6} md={6} sm={6} key={operator.code}>
                            <label className="radio-label">
                              <span className="text-uppercase">
                                {operator.name}
                              </span>
                              <input
                                type="radio"
                                name="operatorCode"
                                id={operator.code}
                                value={operator.code}
                                checked={
                                  formData.operatorCode === operator.code
                                }
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    operatorCode: operator.code,
                                    destinationFareZone:
                                      operator.destinationFareZones.length === 1
                                        ? operator.destinationFareZones[0]
                                            .destinationFareZone
                                        : "",
                                    originFareZone:
                                      operator.originFareZones.length === 1
                                        ? operator.originFareZones[0]
                                            .originFareZone
                                        : "",
                                  });
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                            {errors.operatorCode ? (
                              <label className="text-danger d-flex">
                                {errors.operatorCode}
                              </label>
                            ) : null}
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                ) : null}

                {formData.operatorCode && fareZonesByCode.origins.length > 1 && (
                  <>
                    <h3>CHOOSE YOUR ORIGIN FARE ZONE</h3>
                    <Row>
                      {fareZonesByCode.origins.map((originFareZone) => {
                        return (
                          <Col
                            lg={6}
                            md={6}
                            sm={6}
                            key={`${formData.operatorCode}_${originFareZone.originFareZone}`}
                          >
                            <label className="radio-label">
                              <span className="text-uppercase">
                                {get(
                                  fareZoneTranslations,
                                  [originFareZone.originFareZone],
                                  originFareZone.originFareZone
                                )}
                              </span>
                              <input
                                type="radio"
                                name={`${formData.operatorCode}_originFareZone`}
                                id={originFareZone.originFareZone}
                                value={originFareZone.originFareZone}
                                checked={
                                  formData.originFareZone ===
                                  originFareZone.originFareZone
                                }
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    originFareZone:
                                      originFareZone.originFareZone,
                                  });
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                            {errors.originFareZone ? (
                              <label className="text-danger d-flex">
                                {errors.originFareZone}
                              </label>
                            ) : null}
                          </Col>
                        );
                      })}
                    </Row>
                  </>
                )}

                {formData.operatorCode &&
                  fareZonesByCode.destinations.length > 1 && (
                    <>
                      <h3>CHOOSE YOUR DESTINATION FARE ZONE</h3>
                      <Row>
                        {fareZonesByCode.destinations.map(
                          (destinationFareZone) => {
                            return (
                              <Col
                                lg={6}
                                md={6}
                                sm={6}
                                key={`${formData.operatorCode}_${destinationFareZone.destinationFareZone}`}
                              >
                                <label className="radio-label">
                                  <span className="text-uppercase">
                                    {get(
                                      fareZoneTranslations,
                                      [destinationFareZone.destinationFareZone],
                                      destinationFareZone.destinationFareZone
                                    )}
                                  </span>
                                  <input
                                    type="radio"
                                    name={`${formData.operatorCode}_destinationFareZone`}
                                    id={destinationFareZone.destinationFareZone}
                                    value={
                                      destinationFareZone.destinationFareZone
                                    }
                                    checked={
                                      formData.destinationFareZone ===
                                      destinationFareZone.destinationFareZone
                                    }
                                    onChange={(e) => {
                                      setFormData({
                                        ...formData,
                                        destinationFareZone:
                                          destinationFareZone.destinationFareZone,
                                      });
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                {errors.destinationFareZone ? (
                                  <label className="text-danger d-flex">
                                    {errors.destinationFareZone}
                                  </label>
                                ) : null}
                              </Col>
                            );
                          }
                        )}
                      </Row>
                    </>
                  )}
              </div>
            </Col>
          </Row>
        ) : null}

        {/** End Fare Zone Row */}

        {/** Action Row */}
        <Row className="bus-form-row">
          <Col className="left" lg={6} md={6} sm={12}></Col>
          <Col>
            <Row>
              <Col>
                {formData.destinationCity && formData.operatorCode ? (
                  <Button
                    disabled={!formData.destinationCity}
                    className="m-initial schedule-btn"
                    variant="secondary"
                    className="w260"
                    onClick={() => {
                      window.open(
                        `https://web.coachusa.com/coachSS/ss.listing.asp?action=Lookup&c1=${formData.originCity.value}&s1=${formData.originState.value}&c2=${formData.destinationCity.value}&s2=${formData.destinationState.value}`,
                        "_blank"
                      );
                    }}
                  >
                    SCHEDULES
                  </Button>
                ) : null}
              </Col>
              <Col>
                <div className="d-flex justify-content-end">
                  <Button
                    disabled={
                      !formData.destinationCity ||
                      !formData.operatorCode ||
                      !formData.originFareZone ||
                      !formData.destinationFareZone
                    }
                    onClick={handleBuyNow}
                    className="m-initial w260"
                  >
                    {formData.destinationCity &&
                    formData.operatorCode &&
                    formData.originFareZone &&
                    formData.destinationFareZone
                      ? "BUY NOW"
                      : "SEARCH"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/** End Action Row */}
      </Container>
    </Container>
  );
};

async function fetchOriginState() {
  try {
    const response = await axios(
      "https://web.coachusa.com/coachss-v2/index.asp?action=SSAPI&task=GetSetOriginState"
    );
    const states = get(response, "data.originStates");
    return states.map((state) => {
      const stateData = Object.entries(state)[0];
      return { label: stateData[1], value: stateData[0] };
    });
  } catch (e) {
    return [];
  }
}

async function fetchOriginCity(state) {
  try {
    const response = await axios(
      "https://web.coachusa.com/coachss-v2/index.asp?action=SSAPI&task=GetSetOriginState&originState=" +
        state
    );
    const cities = get(response, "data.originCities");
    return cities.map((city) => {
      const cityData = Object.entries(city)[0];
      return { label: cityData[1], value: cityData[0] };
    });
  } catch (e) {
    return [];
  }
}

async function fetchDestinationState(originStateCode, originCityCode) {
  //
  try {
    const response = await axios(
      `https://web.coachusa.com/coachss-v2/index.asp?action=SSAPI&task=GetSetOriginCity&originState=${originStateCode}&originCity=${originCityCode}`
    );
    const states = get(response, "data.destinationStates");
    return states.map((state) => {
      const stateData = Object.entries(state)[0];
      return { label: stateData[1], value: stateData[0] };
    });
  } catch (e) {
    return [];
  }
}

async function fetchDestinationCity(
  originStateCode,
  originCityCode,
  destinationStateCode
) {
  //
  try {
    const response = await axios(
      `https://web.coachusa.com/coachss-v2/index.asp?action=SSAPI&task=GetSetDestinationState&originState=${originStateCode}&originCity=${originCityCode}&destinationState=${destinationStateCode}`
    );
    const cities = get(response, "data.destinationCities");
    return cities.map((city) => {
      const cityData = Object.entries(city)[0];
      return { label: cityData[1], value: cityData[0] };
    });
  } catch (e) {
    return [];
  }
}

function apiDataFormat(str) {
  return str ? str.toString().split(" ").join("").toUpperCase() : "";
}

const mapStateToProps = (state) => ({
  locations: state.landingReducer.locations,
  selectedLocationData: state.landingReducer.selectedLocationData
  });

  const mapDispatchToProps = (dispatch) => {
    return {
      fetchByLocation: () =>
      dispatch(landingActions.fetchByLocation())      
    };
    };

export default connect(mapStateToProps, mapDispatchToProps)
  (Dashboard);

