import api from "../../utils/api/api";
import _ from "lodash";
import appConstants from "../../constants/appConstants";

const {
  FETCH_TICKETS_START,
  FETCH_TICKETS_COMPLETE,
  FETCH_TICKETS_FAILED,
  FETCH_TICKETS_STOP,
  CLEAR_TICKET_DATA
} = appConstants;

const generateTicketsActions = {
  fetchTicketsWithBarcode: order_id => async dispatch => {
    dispatch({
      type: FETCH_TICKETS_START,
      payload: false
    });
    api.fetch(
      `${process.env.REACT_APP_JOURNAL_ORDER_TICKETS_API_ENDPOINT}${order_id}`
    ).then((resApi) => {
      const tickets = _.get(resApi, 'data.orbitResponse.body.ticketList.Items', []);
      const ticketsupdated = _.get(resApi, 'data.orbitResponse.body.Items', []);
// this denotes that if your tickets is empty or no repsonse from order_id then show error
      if (_.isEmpty(tickets) && _.isEmpty(ticketsupdated)) {
        dispatch({
          type: FETCH_TICKETS_FAILED,
          payload: 'No Tickets found'
        });
        return dispatch({
          type: FETCH_TICKETS_STOP,
          payload: false
        });
      }

      if (resApi.data !== "") {
        dispatch({
          type: FETCH_TICKETS_COMPLETE,
          payload: resApi.data
        });

        return dispatch({
          type: FETCH_TICKETS_STOP,
          payload: false
        });
      }
    }).catch((error) => {
      dispatch({
        type: FETCH_TICKETS_FAILED,
        payload: error
      });
      return dispatch({
        type: FETCH_TICKETS_STOP,
        payload: false
      });
    });
  },
  clearData: ()=>{
    return {
      type: CLEAR_TICKET_DATA,
    };
  }
};

export default generateTicketsActions;
