import React, {useState} from 'react';
import * as QueryString from 'query-string';
import COACHUSALOGO from "../../../../../assets/images/coach_logo_black_full.svg";
import Api from "../../../utils/api/api";
import _, {get} from "lodash";

class PrintReceipt extends React.Component {

  state = {
    payments: [],
    agentID: '',
    order_id: ''
  };

  componentDidMount = async () => {
    const params = QueryString.parse(this.props.location.search);
    const order_id = params.order_id;
    const ticketResponse = await Api.fetch(
      `${process.env.REACT_APP_JOURNAL_ORDER_TICKETS_API_ENDPOINT}${order_id}`
    );
    const payments = get(
      ticketResponse,
      "data.orbitResponse.body.orderDetail.paymentDetail",
      []
    );
    let cognitoUser = localStorage.getItem('cognitoUser');
    let agentID = '';
    if (cognitoUser) {
      const agentData = JSON.parse(cognitoUser);
      if (typeof agentData.username !== 'undefined') {
        agentID = agentData.username;
      }
    }
    this.setState({payments, agentID, order_id});
  };

  render () {
    const order_id = this.state.order_id;

    const months = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    const date = new Date();
    const time_zone = date.toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    const date_of_sale = date.getDate() + ' ' + months[parseInt(date.getMonth())] + ' ' + date.getFullYear();
    const trans_date = date.getUTCFullYear() + '-' +
      ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
      ('00' + date.getUTCDate()).slice(-2);
    const trans_time = ('00' + date.getHours()).slice(-2) + ':' +
      ('00' + date.getMinutes()).slice(-2) + ' ' + time_zone;

    return (
      <div>
        <div className="printed-text" style={{height: 'auto'}}>
          <div className="page-break clearfix" />
          <div>
            <div style={{width: '100%', height: '36px'}} />
            <div className="printed-text ticket-header d-flex">
              <div className="printed-text content" style={{width: '100%'}}>
                <h2 className="printed-text" style={{fontSize: '14px', textAlign: 'right', fontWeight: 'bold', color: '#000', margin: '4px 0'}}>
                  COUPON
                </h2>
                <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'right', color: '#000', margin: '4px 0'}}>
                  THIS IS NOT A TICKET
                </h2>
                <hr/>
                <div className="printed-text" style={{display: 'block', width: '100%', height: '20px', color: '#000', textTransform: 'uppercase', fontSize: '12px', textAlign: 'center'}}>
                  <h2 className="printed-text" style={{textTransform: 'uppercase', fontSize: '12px', fontWeight: 'bold', color: '#000', margin: '4px 0 0 0'}}>Woodbury Common Coupon Book</h2>
                </div>
                <hr/>
                <div className="printed-text content" style={{height: '25px', width: '100%'}}>
                  <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'left', color: '#000', margin: '1px 0'}}>
                    FROM: NEW YORK, NY
                  </h2>
                  <h2 className="printed-text" style={{fontSize: '12px', textAlign: 'left', color: '#000', margin: '1px 0'}}>
                    TO: WOODBURY COMMON
                  </h2>
                </div>
                <div className="printed-text" style={{color: '#000', width: '100%', height: '25px', display: 'block'}}>
                  <h2 className="printed-text" style={{fontSize: '12px', marginTop: '2px', color: '#000'}}>
                    Issuing OPR:{" "}
                    <span>SHORTLINE</span>
                  </h2>
                </div>
                <hr/>
                <div className="printed-text" style={{fontSize: '9px', padding: '12px', textAlign: 'center', textTransform: 'uppercase', height: 'auto', width: '100%'}}>
                  <h2 className="printed-text" style={{textTransform: 'uppercase', fontSize: '12px', marginTop: '2px', color: '#000'}}>Download Woodbury Common<br/>
                    Coupon Book at<br/><br/>
                    https://www.premiumoutlets.com/vip</h2>
                </div>
                <hr/>
                <h2 className="printed-text" style={{width: '100%', height: '20px', fontSize: '12px', fontWeight: 'bold', display: 'block'}}>
                  Date: {date_of_sale}
                </h2>
                <h2 className="printed-text" style={{width: '100%', height: '40px', fontSize: '12px', fontWeight: 'bold', display: 'block'}}>
                  Order No:<br/><span style={{fontSize: '10px'}}>{order_id}</span>
                </h2>
                <h2 className="printed-text" style={{marginTop: '13px', width: '100%', height: '20px', fontSize: '12px', fontWeight: 'bold', display: 'block'}}>
                  Agent ID: {this.state.agentID}
                </h2>
              </div>
            </div>
          </div>
          <div className="page-break clearfix" />
        </div>
      </div>
    );
  }
}

export default PrintReceipt;
