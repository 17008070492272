import React from "react";
import orderStatusActions from "../../actions/orderStatus/orderStatus";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import CoachLoader from "../Loader/CoachLoader";
import ModalDialog from "../ModalDialog/ModalDialog";
import errMsgConfig from "../../utils/errorMsgConfig/errorMsgConfig";
import Theme from '../Theme/index';
import CongnitoTokenContext from "../../../../CognitoTokenContext";
import * as FullStory from '@fullstory/browser';
import { format } from 'date-fns';
class OrderStatus extends React.Component {
  orderAction="";
  constructor(props) {
    super(props);
    this.state = {
      pollCount: 1,
      pollCount2: 1,
      pdfCreatingWaitPoll: 1,
      open: false,
      maxCount: 60,
      maxPDFAttempts: 20,
      message: "Generating Tickets",
      ticketItems:[],
      barcodeGenerated : false
    };
    window.scrollTo(0,0);
  }

  componentDidMount() {
    const body = document.getElementsByTagName('body');
		body[0].classList.add('bgWhite');
    window.scrollTo(0, 0);
    let order = {
      transactionId: this.props.transactionId,
      email: _.get(this.props, "email", "")
    };
    if (
      typeof this.props.transactionId !== "undefined" ||
      typeof this.props.email !== "undefined"
    ) {
      const ticketItems = _.get(this.props, "ticketInfo", []);
      this.setState({ ticketItems})
      this.handlePoll(order);
    } else {
      this.props.history.push("/checkout");
    }
  }
 
  componentDidUpdate(prevProps, prevState, snap) {
    if (prevProps.orderError !== this.props.orderError) {
      this.errMsg = errMsgConfig.failedPayment.body;
      this.toggleModal();
    }
    if(prevProps.orderData !== this.props.orderData){
      if (typeof this.props.orderData.orbitResponse !== "undefined") {
        if (typeof this.props.orderData.orbitResponse.action !== "undefined") {
          this.orderAction = this.props.orderData.orbitResponse.action;
        }
      } 
    }
    window.scrollTo(0,0);
  }

  componentWillUnmount () {
		const body = document.getElementsByTagName('body');
		body[0].classList.remove('bgWhite');
	}


  toggleModal = () => this.setState(state => ({ open: !state.open }));

  onBtnOk = () => {
    this.toggleModal();
    this.props.history.push("/checkout");
  };

  handlePoll = order => {
    let { pollCount } = this.state;
    let { pollCount2 } = this.state;
    let { pdfCreatingWaitPoll } = this.state;
    try {
      if (typeof order.transactionId !== "undefined") {
        _.delay(() => {
          this.props.checkOrderStatus(order);
              if (!_.isEmpty(this.props.orderData) && this.orderAction === "agent-order-requested") {
                pollCount++;
                if (pollCount < this.state.maxCount) {
                  this.setState({ pollCount: pollCount });
                  this.handlePoll(order);
                } 
              }else if (!_.isEmpty(this.props.orderData) && this.orderAction === "order-created") {
                pollCount2++;
                if (pollCount2 < this.state.maxCount) {
                  this.setState({ pollCount2: pollCount2 });
                  this.handlePoll(order);
                } 
              }
              else if (this.orderAction === "barcode-generated") {
                if (!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')) {
                  this.setState({ pollCount2: this.state.maxCount });
                  this.props.history.push('./emailsuccess');
                }
                else {
                  pdfCreatingWaitPoll++;
                  if (pdfCreatingWaitPoll < this.state.maxPDFAttempts) {
                    this.setState({ pdfCreatingWaitPoll: pdfCreatingWaitPoll, barcodeGenerated : true });
                    this.handlePoll(order);
                  } else {
                    //Fullstory
                    FullStory.event('Order Completed', {
                      transactionId_str: this.props.tokenAccepted,
                      partnerCode_str: 'CUSA',
                      totalTickets_int: _.get(this.state.ticketItems, 'cart_total_tickets', 0),
                      totalFare_real: _.get(this.state.ticketItems, "totalFare", 0),
                      ticketTypes_str: _.join(
                        _.map(
                          _.get(this.state.ticketItems, "ticketitems", []),
                          (ticketItem) =>
                            `${ticketItem.quantity}-${ticketItem.passengerType}(${ticketItem.ticketType})`
                        ),
                        ', '
                      ),
                      purchase_date: format(new Date(), 'MM-dd-yyyy h:mm a')
                    });
                    this.props.history.push(
                      './tickets?order_id='+this.props.tokenAccepted
                     );                        
                  }
                }
              }
              else if (this.orderAction === "pdf_generated" || this.orderAction === "err_pdf_generated") {
                if (this.orderAction === "err_pdf_generated" && !this.state.barcodeGenerated) {
                  if(!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')){
                    this.props.history.push(
                      './transactionfailed'
                     )
                   }
                   else{
                  this.error = "payment failed";
                  this.errMsg = errMsgConfig.failedPayment.body;
                  this.toggleModal();
                   }
                }
                //Fullstory
                FullStory.event('Order Completed', {
                  transactionId_str: this.props.tokenAccepted,
                  partnerCode_str: 'CUSA',
                  totalTickets_int: _.get(this.state.ticketItems, 'cart_total_tickets', 0),
                  totalFare_real: _.get(this.state.ticketItems, "totalFare", 0),
                  ticketTypes_str: _.join(
                    _.map(
                      _.get(this.state.ticketItems, "ticketitems", []),
                      (ticketItem) =>
                        `${ticketItem.quantity}-${ticketItem.passengerType}(${ticketItem.ticketType})`
                    ),
                    ', '
                  ),
                  purchase_date: format(new Date(), 'MM-dd-yyyy h:mm a')
                });
                this.props.history.push(
                  './tickets?order_id='+this.props.tokenAccepted
                 );
              } 
               else if (this.orderAction === "err-order-requested") {
                 if(!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')){
                  this.props.history.push(
                    './transactionfailed'
                   )
                 }
                 else{
                  this.error = "payment failed";
                  this.errMsg = errMsgConfig.failedPayment.body;
                  this.toggleModal();
                 }
 
             } 
              
              else if (this.orderAction === "err_order_created") {
                if(!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')){
                  this.props.history.push(
                    './transactionfailed'
                   )
                 }
                 else{
                this.error = "payment failed";
                this.errMsg = errMsgConfig.failedPayment.body;
                this.toggleModal();
                 }
              }else if (this.orderAction === "err-barcode-generated") {
                if(!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')){
                  this.props.history.push(
                    './transactionfailed'
                   )
                 }
                 else{
                this.error = "payment failed";
                this.errMsg = errMsgConfig.failedPayment.body;
                this.toggleModal();
                 }
              }else if(this.orderAction === "error_no_transaction_found"){
                if(!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')){
                  this.props.history.push(
                    './transactionfailed'
                   )
                 }
                 else{
                this.error = "payment failed";
                this.errMsg = errMsgConfig.failedPayment.body;
                this.toggleModal();
                 }
              }
              else {
                  this.handlePoll(order);
   
              }
        }, 1000);
      } else {
        this.props.history.push("/checkout");
      }
    } catch (err) {
      console.log("Api respond with:", err);
    }
  };

  render() {
    const orderStatus = _.get(this.props, "data.order_status", "");
    if (orderStatus === "pdf_generated") {
      this.props.history.push(
        './tickets?order_id='+this.props.tokenAccepted
       );
    } else if (orderStatus === "barcode-generated") {
      if (!_.isEmpty(this.context.cognitoTokenValue) && _.isEqual(localStorage.getItem("agent_Location"), 'CUST_AP')) {
         this.props.history.push('./emailsuccess') 
      }
    }

    return (
      <Theme>
      <div className="order-status">
        {orderStatus !== "pdf_generated" ? (
          <CoachLoader message={this.state.message} />
        ) : null}
        <ModalDialog
          show={this.state.open}
          name="apiError"
          btnText="OK"
          body={this.errMsg}
          onBtnOk={this.onBtnOk}
        />
      </div>
      </Theme>
    );
  }
}

OrderStatus.contextType = CongnitoTokenContext;

const mapStateToProps = state => ({
  orderData: state.orderStatus.orderData,
  isLoading: state.orderStatus.isLoading,
  tokenAccepted: state.checkout.tokenAccepted,
  transactionId: state.checkout.transactionId,
  email: state.checkout.email,
  isUserValidated: state.checkout.isUserValidated,
  orderError: state.orderStatus.orderError,
  ticketInfo: state.busFares.ticketInfo,
});

const mapDispatchToProps = dispatch => {
  return {
    checkOrderStatus: order =>
      dispatch(orderStatusActions.checkOrderStatus(order))
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(OrderStatus);
