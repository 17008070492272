import appConstants from '../../constants/appConstants';
import Api from '../../utils/api/api';
import { get } from 'lodash';

const { UPDATE_TICKET_FARE_RULE_DATA, TICKET_FARE_RULE_FETCH_STATUS } = appConstants;

function updateTicketFareRuleData(data, defaultData) {
	return {
		payload: {data, defaultData},
		type: UPDATE_TICKET_FARE_RULE_DATA
	};
}


export function fetchFareRules() {
	return (dispatch) => {
		dispatch({
			type: TICKET_FARE_RULE_FETCH_STATUS,
			payload: true
		})
		Api.fetch( `${process.env.REACT_APP_FARE_RULES_ENDPOINT}?from=ap`)
			.then((resposne) => {
				const data = {...get(resposne, 'data.orbitResponse.body', {})}
				delete data.defaultData
				const defaultData = get(resposne, 'data.orbitResponse.body.defaultData', {})
				dispatch(updateTicketFareRuleData(data, defaultData))
			}).catch(() => {
				dispatch({
					type: TICKET_FARE_RULE_FETCH_STATUS,
					payload: false
				})
			})
	}
}