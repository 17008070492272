import appConstants from '../../constants/appConstants';

const { FETCH_LOCATION_START, FETCH_SELECTED_DATA} = appConstants;

const initialState = {
    locations: {},
    selectedLocationData: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_LOCATION_START:
			return {
				...state,
				locations: action.payload,
            };
            
        // case FETCH_SELECTED_DATA:
		// 	return {
		// 		...state,
		// 		selectedLocationData: action.payload,
		// 	};
		default:
			return state;
	}
};