import _ from 'lodash';
const initialState = {
	data: {},
	oneWayOrder: [],
	roundTripOrder: [],
	oneWayTicket: {},
	isLoading: false,
	isPaymentDone: false,
	isCartFill: false,
	cartMultiRideCount: [],
	display: 'd-block',
	ticketInfo: localStorage.getItem('ticketInfo') ? JSON.parse(localStorage.getItem('ticketInfo')) : [],
	oneWayCountObj: localStorage.getItem('oneWayCountObj') ? JSON.parse(localStorage.getItem('oneWayCountObj')) : [],
	roundWayCountObj: localStorage.getItem('roundWayCountObj')
		? JSON.parse(localStorage.getItem('roundWayCountObj'))
		: [],
	multiWayCountObj: localStorage.getItem('multiWayCountObj')
		? JSON.parse(localStorage.getItem('multiWayCountObj'))
		: [],
	saveTicketError: {}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_PRODUCT_FARE':
			return {
				...state,
				data: _.remove(action.payload, (data) => {
					return data.ticketTypeName === 'ONE-WAY' || data.ticketTypeName === 'ROUND-TRIP';
				}),
				multiRide: _.reject(action.payload, (data) => {
					return data.ticketTypeName === 'ONE-WAY' || data.ticketTypeName === 'ROUND-TRIP';
				})
			};
		case 'FETCH_PRODUCT_FARE_START':
			return {
				...state,
				isLoading: action.payload
			};

		case 'FETCH_PRODUCT_FARE_STOP':
			return {
				...state,
				isLoading: action.payload
			};

		case 'POST_CART_INFO':
			return {
				...state,
				ticketInfo: action.payload,
				isLoading: false,
				ticketFare: {}
			};
		case 'POST_CART_INFO_FAILED':
			return {
				...state,
				saveTicketError: action.payload,
				isLoading: false,
				ticketFare: {}
			};
		case 'EMPTY_CART':
			localStorage.removeItem('ticketInfo');
			return {
				...state,
				ticketInfo: [],
				isLoading: false,
				ticketFare: {},
				display: 'd-block'
			};
		case 'HIDE_CART':
			return {
				...state,
				display: 'd-none'
			};
		case 'UNHIDE_CART':
			return {
				...state,
				display: 'd-block'
			};

		case 'POST_CART_INFO_START':
			return {
				...state,
				isCartFill: action.payload
			};

		case 'SET_ONEWAY_ORDER':
			return {
				...state,
				oneWayOrder: _.reject(action.payload, { quantity: 0 })
			};

		case 'SET_ROUNDTRIP_ORDER':
			return {
				...state,
				roundTripOrder: _.reject(action.payload, { quantity: 0 })
			};
		case 'ADD_ONE':
			return {
				...state,
				add: 1
			};
		case 'SUBTRACT_ONE':
			return {
				...state,
				add: -1
			};
		case 'FETCH_PRODUCT_FARE_TICKET':
			return {
				...state,
				ticketFare: action.payload
			};
		case 'UPDATE_ONEWAY_COUNT':
			localStorage.setItem('oneWayCountObj', JSON.stringify(action.payload));
			return {
				...state,
				oneWayCountObj: action.payload
			};
		case 'UPDATE_ROUNDWAY_COUNT':
			localStorage.setItem('roundWayCountObj', JSON.stringify(action.payload));
			return {
				...state,
				roundWayCountObj: action.payload
			};
		case 'UPDATE_MULTIWAY_COUNT':
			localStorage.setItem('multiWayCountObj', JSON.stringify(action.payload));
			return {
				...state,
				multiWayCountObj: action.payload
			};

		default:
			return state;
	}
};
