import React, { useState, useEffect, useMemo } from "react";
import logo from "../../../../assets/images/Logo-stacked.svg";
import logopowered from "../../../../assets/images/ORBIT-LOGO-KOWHITE.svg";
import Select from "react-select";
import { Button } from "react-bootstrap";
import _, { get } from "lodash";
import Loader from "../Loader/Loader";
import landingActions from "../../actions/landing/landing";
import { connect } from "react-redux";

const LaunchScreen = ({
  history: { push },
  fetchByLocation,
  locations,
}) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const [agentLocation, setAgentLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  // useMemo(
  // 	() =>
  // 		setTimeout(() => {
  // 			 push('/login');
  // 		}, 5000),
  // 	[ push ]

  // );

  const styles = {
    menu: (style) => ({...style, marginTop: '-14px', width: '80%'}),
    indicatorSeparator: () => ({ display: "none", border: "1px solid #A8A8A8" }),
    placeholder: (styles) => ({ ...styles, 'textTransform': 'inherit', 'marginLeft': '6px' , 'font': '400 16px/16px Lato' }),
    singleValue: (styles) => ({ ...styles,  'textTransform': 'uppercase', 'marginLeft': '6px' }),
    dropdownIndicator: (style, state) => ({
      ...style,
      transform: state.selectProps.menuIsOpen && 'rotate(180deg)'
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor : isSelected ? '#dedddd' : isFocused ? '#deddddad' : null,
      ':active': {
        ...styles[':active'],
        backgroundColor: isSelected ? '#dedddd' : null,
      },
      color: '#000000'
    })
  };

  const handleSubmit = () => {
    //	fetchLocationData(selectedOption);
    localStorage.setItem("agent_Location", selectedOption.value);
    push("/login");
  };

  async function fetchAgentLocation() {
    try {
      setLoading(true);
      const response = await fetchByLocation();
      if (response.emptyData) {
        localStorage.setItem("agent_Location", response.defaultLocation.code);
        setLoading(false);
        setTimeout(() => {
          			 push('/login');
           		}, 2000)
      }
      else{
        setLoading(false);
      }
      return response;
    } catch (e) {
      setLoading(false);
    }
    
  }

  const _getAgentLocation = async () => {
    const agentLocations = await fetchAgentLocation();
    setAgentLocation(agentLocations.emptyData);
  };

  useEffect(() => {
    _getAgentLocation();
    const cognitoUser = localStorage.getItem("cognitoUser");
    const agentLocation = localStorage.getItem("agent_Location");
    if (_.isEmpty(agentLocation)) {
      push("./");
      return;
    } else {
      if (cognitoUser) {
        push("./home");
        return;
      }
    }
  }, []);

  const locationArray = !_.isEmpty(locations)
    ? locations.locationList.map((location) => {
        return {
          label: location.name,
          value: location.code,
          activeFlag: location.activeFlag,
          defaultLocation: location.defaultLocation,
          id: location.id,
          objectName: location.objectName,
        };
      })
    : [];

  return (
    <div className="App-header">
      {loading && <Loader />}
      <div className="App-Center">
      <img src={logo} className="App-logo" alt="logo" />
      <p className="main-text">Agent Portal</p>
      {!agentLocation ? <div className="launch-select">
        <label className="select-text">Select your location</label>
        <Select
          placeholder="Select location"
          className="select-dropdown"
          value={selectedOption}
          onChange={(e) => {
            setSelectedOption(e);
          }}
          options={locationArray}
          styles={styles}
          noOptionsMessage={() => "Select location"}
        />
        <div className="d-flex justify-content-center">
          <Button disabled={_.isEmpty(selectedOption)} onClick={handleSubmit} className="w260">
            SUBMIT
          </Button>
        </div>
      </div> : null}
      </div>
      <div className="App-Center">
      <img src={logopowered} className="footer-powered-text" alt="logo" />
      <p className="footer-version-text">Version 1.0</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  locations: state.landingReducer.locations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchByLocation: () => dispatch(landingActions.fetchByLocation()),
    // fetchLocationData: (location) =>
    // dispatch(landingActions.fetchLocationData(location)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchScreen);
