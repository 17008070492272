import api from "../../utils/api/api";
import appConstants from "../../constants/appConstants";

const {
  ADD_TO_APPLE_WALLET_START,
  ADD_TO_APPLE_WALLET_STOP
} = appConstants;


const walletActions = {
  addToAppleWallet: ticketId => async dispatch => {
    dispatch({
      type: ADD_TO_APPLE_WALLET_START,
      payload: true
    });

    const resApi = await api.fetch(
      `${process.env.REACT_APP_APPLE_WALLET_API_ENDPOINT}order/tickets/applewallet/${ticketId}`
    );

    if (resApi.data.orbitResponse !== "") {
      dispatch({
        type: ADD_TO_APPLE_WALLET_STOP,
        payload: false
      });
      return resApi.data.orbitResponse.applePkPassLocation;

    } else {
      return dispatch({
        type: ADD_TO_APPLE_WALLET_STOP,
        payload: false
      });
    }
  },
};

export default walletActions;
