import React from "react";
import _, { get } from "lodash";
import PlusLogo from "../../../../assets/images/plus.svg";
import MinusLogo from "../../../../assets/images/minus.svg";
import InfoIcon from "../../../../assets/images/Info-icon.svg";
import MainIcon from "../../../../assets/images/Main_icon.svg";
import moment from "moment";
import {
  getMonthlyTicketType,
  validity,
} from "../../utils/commonUtils/commonUtils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default ({
  fare,
  operatorCode,
  ticketFareRuleLabels,
  tabSelected,
  countList,
  handleSelectRemoveMethod,
  handleSelectAddMethod,
  proxyAssetEndpoint,
  isLoading,
  handleTicketCount,
  ticketCount,
  handleInputAddRemove,
}) => {
  // const passengerSubLabel = (row) => {
  //     if (['ONE-WAY', 'ROUND-TRIP'].includes(row.ticketTypeName)) {
  //         const {data, defaultData, isRequesting}  = ticketFareRuleLabels;
  //         if (isRequesting) {return ''}
  //         const subLabel = get(data, ['categoryLabels', operatorCode, row.passengerTypeName,  'ageGroup']);
  //         const defaultSubLabel = get(defaultData, [ row.serviceType === 'airport'? 'airportCategoryLabels': 'categoryLabels', row.passengerTypeName, 'ageGroup'], '');
  //         return subLabel !== undefined ? subLabel : defaultSubLabel;
  //     } else if (row.ticketTypeName === 'MONTHLY-PASS'){
  // 		const date = moment(new Date())
  // 		return `Valid ${date.startOf('month').format('DD MMMM')} - ${date.endOf('month').format('DD MMMM')}`
  // 	} else if (row.ticketTypeName === 'MONTHLY-PASS-N'){
  // 		const nextMonth = moment(new Date()).startOf('month').add(1, 'months');
  // 		return `Valid ${nextMonth.startOf('month').format('DD MMMM')} - ${nextMonth.endOf('month').format('DD MMMM')}`
  // 	} else {
  // 		const {data}  = ticketFareRuleLabels;
  // 		const subLabel = get(data, ['categoryLabels', operatorCode, row.ticketTypeName,  'ageGroup']);
  //         return subLabel ? subLabel : ''
  // 	}
  // }

  const passengerSubLabel = (row, tabSelected) => {
    const { data, defaultData, isRequesting } = ticketFareRuleLabels;
    if (isRequesting) {
      return "";
    }
    const key = `${operatorCode}_${row.ticketTypeName}_${row.passengerTypeName}.ageGroup`;
    return get(data, key, "");
  };

  const ticketFareRuleHints = (row, tabSelected) => {
    const { data, defaultData, isRequesting } = ticketFareRuleLabels;
    if (isRequesting) {
      return "";
    }
    const key = `${operatorCode}_${row.ticketTypeName}_${row.passengerTypeName}.ticketRules`;
    const fareRuleText = get(data, key, "");

    if (!fareRuleText) {
      return null;
    }

    return (
      <OverlayTrigger
        trigger="click"
        placement="right"
        overlay={<Tooltip>{fareRuleText}</Tooltip>}
        rootClose
      >
        <img className="ticket-hints" src={InfoIcon} alt="hints" />
      </OverlayTrigger>
    );
  };

  const multiRideTicketType = (row) => {
    let ticketType = row.ticketTypeName;
    ticketType = _.includes(ticketType, "MONTHLY")
      ? getMonthlyTicketType(ticketType)
      : ticketType;
    return ticketType;
  };

  // 	if (!isLoading && fare.length === 0) {
  //     return (
  //       <tr>
  //         <td className="text-center" colSpan={3}>No Ticket Found</td>
  //       </tr>
  //     );
  //   }

  if (_.isEmpty(fare)) {
    return (
      <tr className="text-center">
        <td>
          <div className="emptyiconcenter">
            <img src={MainIcon} alt="Main icon" />
            <p className="emptytickettext">
              This ticket type is not available for this route.
            </p>
          </div>
        </td>
      </tr>
    );
  }

  return _.map(_.sortBy(fare, ["sortOrder"]), (data, index) => {
    return (
      <tr key={index}>
        <td>
          <span className="psnger">
            {tabSelected === "multiTrip"
              ? multiRideTicketType(data)
              : data.passengerTypeName}
            {ticketFareRuleHints(data, tabSelected)}
          </span>{" "}
          <span className="age">
            {_.includes(data.ticketTypeName, "MONTHLY")
              ? validity(data.ticketTypeName)
              : passengerSubLabel(data, tabSelected)}
          </span>
        </td>
        <td>
          <span className="psnger-fare">
            ${parseFloat(data.computedFare).toFixed(2)}{" "}
          </span>
        </td>
        <td>
          <div className="containerbox">
            <span
              className="minus"
              onClick={() => handleSelectRemoveMethod(data, index)}
            >
              <img src={MinusLogo} alt="delete ticket" />
            </span>
            <input
              type="text"
              id={`${data.id}`}
              value={get(ticketCount, data.id, 0)}
			        className="ticketCount-box"
              onChange={handleTicketCount}
              onBlur={()=>handleInputAddRemove(data, parseInt(get(ticketCount, data.id, 0)))}
            />
            <span
              className={`plus ${get(ticketCount, data.id, 0) > 49? 'pointer-none' : ''}`}
              onClick={() => handleSelectAddMethod(data, index)}
            >
              <img src={PlusLogo} alt="add ticket" />{" "}
            </span>
          </div>
        </td>
      </tr>
    );
  });
};
