import React from 'react';
import { Card, Button} from 'react-bootstrap';
import Theme from '../Theme/index';
import GroupImg from '../../../../assets/images/Group.svg';
import {  withRouter } from 'react-router-dom';
import { compose } from 'redux';


const EmailSuccess=({history})=> {

    const handleNewSearch =()=>{
        history.push('./home');
    }

return (
	<Theme className="ticket-generate-content postion-relative">
		<Card className="checkout_Final">
			<Card.Body>
				<img src={GroupImg} alt="GroupImg" />
				<h3 className="mt-4">Your ticket and receipt has been sent successfully.</h3>
                <div className="message mt-2 mb-4">Your email was {localStorage.getItem("ticketEmail")}</div>
				<Button className="btn" onClick={handleNewSearch}>New Search</Button>
			</Card.Body>
		</Card>		
	</Theme>
		);
}


export default compose(withRouter)(EmailSuccess);;
