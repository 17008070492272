// Community
const COM = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "18 and up"
  },
  CHILD: {
    sortOrder: 1,
    passengerSubLabel: "5 to 17"
  },
  SENIOR: {
    sortOrder: 2,
    passengerSubLabel: "65 and up"
  },
  STUDENT: {
    sortOrder: 3,
    passengerSubLabel: "With College ID"
  },
  DANJ: {
    sortOrder: 4,
    passengerSubLabel: "With valid ID"
  },
};

// Suburban
const SUB = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "18 and up"
  },
  CHILD: {
    sortOrder: 1,
    passengerSubLabel: "5 to 17"
  },
  SENIOR: {
    sortOrder: 2,
    passengerSubLabel: "65 and up"
  },
  STUDENT: {
    sortOrder: 3,
    passengerSubLabel: "With College ID"
  },
  DANJ: {
    sortOrder: 4,
    passengerSubLabel: "With valid ID"
  },
};

// Shortline
const HTL = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "18 and up"
  },
  CHILD: {
    sortOrder: 1,
    passengerSubLabel: "5 to 17"
  },
  SENIOR: {
    sortOrder: 2,
    passengerSubLabel: "65 and up"
  },
  STUDENT: {
    sortOrder: 3,
    passengerSubLabel: "With College ID"
  },
  DANJ: {
    sortOrder: 4,
    passengerSubLabel: "With valid ID"
  },
};

// Rockland Coaches
const RCK = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "18 and up"
  },
  CHILD: {
    sortOrder: 1,
    passengerSubLabel: "5 to 17"
  },
  SENIOR: {
    sortOrder: 2,
    passengerSubLabel: "65 and up"
  },
  STUDENT: {
    sortOrder: 3,
    passengerSubLabel: "With College ID"
  },
  DANJ: {
    sortOrder: 4,
    passengerSubLabel: "With valid ID"
  },
};

const DillonsBusService = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "18 and up"
  },
  CHILD: {
    sortOrder: 1,
    passengerSubLabel: "65 and up"
  },
};

const HudsonTransitLine = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "17 and up"
  },
  CHILD: {
    sortOrder: 1,
    passengerSubLabel: "5 to 7"
  }
};

const VanGalder = {
  ADULT: {
    sortOrder: 0,
    passengerSubLabel: "18 and up"
  }
};

const MapKeys = {COM, SUB, HTL, RCK, DillonsBusService, HudsonTransitLine, VanGalder};

export default MapKeys;
