const initialState = {
  isAuthenticated: false,
  isLoading: false,
  error: "",
  updateUserResult: {},
  viewUserResult: {},
  viewCardsData: {},
  showAddCardForm: false,
  saveCardResults: {},
  getAllTickets: [],
  showConfirmModal: false,
  deleteCardResults: {},
  recurringResult:{},
  recurringError:{},
  updateUserResultError:{},
  userAccountsData:"",
  viewCardErr:{},
  viewAccountsErr:{},
  bookedTicketsErr:{},
  addCardError:{}
};
export default (state = initialState, action) => {
  switch (action.type) {
    // case "UPDATE_EMAIL_SUCCESS":
    //   return { ...state, updateUserResult: action.payload };
    // case "UPDATE_EMAIL_FAILED":
    //   return { ...state, error: action.payload };
    case "VIEW_USER_ACCOUNTS":
      return { ...state, viewUserResult: action.payload };
    case "VIEW_USER_ACCOUNTS_FAILED":
      return { ...state, viewAccountsErr: action.payload };
    case "VIEW_USER_CREDIT_CARDS":
      return { ...state, viewCardsData: action.payload };
    case "VIEW_USER_CREDIT_CARDS_FAILED":
      return { ...state, viewCardErr: action.payload };
    case "UPDATE_USER_ACCOUNTS":
      return { ...state, updateUserResult: action.payload };
    case "UPDATE_USER_ACCOUNTS_FAILED":
      return { ...state, updateUserResultError: action.payload };
    case "SWITCH_TO_ADD_CARD":
      return { ...state, showAddCardForm: action.payload };
    case "SWITCH_TO_ADD_CARD_ERROR":
      return { ...state, error: action.payload };
    case "SAVE_CREDIT_CARDS":
      return { ...state, saveCardResults: action.payload };
    case "SAVE_CREDIT_CARDS_FAILED":
      return { ...state, addCardError: action.payload };
    case "DELETE_CREDIT_CARDS":
      return { ...state, deleteCardResults: action.payload };
    case "DELETE_CREDIT_CARDS_FAILED":
      return { ...state, error: action.payload };
    case "FETCH_ALL_BOOKED_TICKETS_SUCCESS":
      return { ...state, allBookedTickets: action.payload };
    case "FETCH_ALL_BOOKED_TICKETS_FAILED":
      return { ...state, bookedTicketsErr: action.payload };
    case "GET_ORDER_RECIPT":
      return { ...state, reciptData: action.payload };
    case "SWITCH_PROFILE_MODAL_ON_OFF":
      return { ...state, showConfirmModal: action.payload };
    case "SWITCH_PROFILE_MODAL_ON_OFF_ERROR":
      return { ...state, error: action.payload };
    case "GET_ACCOUNTS_DATA_FOR_MODAL":
      return { ...state, userAccountsData: action.payload };
    case "GET_ACCOUNTS_DATA_FOR_MODAL_ERROR":
      return { ...state, error: action.payload };
    case "GET_RECURRING_TOKEN":
      return { ...state, recurringResult: action.payload };
    case "GET_RECURRING_TOKEN_FAILED":
      return { ...state, recurringError: action.payload };
    case "REQUEST_START":
      return {
        ...state,
        isLoading: action.payload
      };
    case "REQUEST_STOP":
      return {
        ...state,
        isLoading: action.payload
      };
    case "SET_PRODUCT_ID":
      return {
        ...state,
        buyAgainProductId: action.payload
      }
    default:
      return state;
  }
};
