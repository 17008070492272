import React from "react";
import { Container } from "react-bootstrap";
import LogoPoweredBy from '../../../../assets/images/logo-powered-by.svg'

const FooterWithPoweredBy = () => {
  return (
    <footer className="footer-with-powered-by-title">
        <Container fluid>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div className="d-flex flex-row align-items-baseline">
              <label className="m-0 mr-3">Powered by</label>
              <img src={LogoPoweredBy} alt="Powered By" />
            </div>
          
          <div className="d-flex flex-row align-items-center">
            <p className="m-0">Release [Version: 0.5.0, Build Date: 07-08-2020 11:04:53]</p>
          </div>
          </div>
        </Container>
    </footer>
  );
};

export default FooterWithPoweredBy;
