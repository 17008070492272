
const handleMultipleSODs = (cartItems) => {
    let sortedData = {};
    let sodList = [];
    cartItems.forEach((ticket) => {
        let sod = ticket.originCity + "/" + ticket.destinationCity;
        if (!sortedData[sod]) {
            sortedData[sod] = [];
            sodList.push(ticket);
        }
        sortedData[sod].push(ticket);
    });
    return {sortedData: sortedData, sodList: sodList};
}
/**
 * This method used to focused on first invalid element in dom.
 */
const focusOnFirstInvalidElement = function () {
    const firstErrorField = document.querySelector('.error-field')
    if (firstErrorField) {
        firstErrorField.focus()
    }
}

const commonUtils = {
    handleMultipleSODs: handleMultipleSODs,
    focusOnFirstInvalidElement
}

export function queryStringToObject (url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        let key = decodeURIComponent(pair[0])
        let value = decodeURIComponent(pair[1])
        if (key.indexOf('[') !== -1) {
            key = key.split('[').join('.').split(']').join('.').split('..').join('.');
            const lastIndex = key.lastIndexOf('.')
            if (lastIndex !== -1) {
                key = key.substr(0, lastIndex)
                this.setProp(params, key, value)
            }
        } else {
            params[key] = value
        }
    }
    return params;
};

export const validity = (data) => {
	let currentMonth = new Date().getMonth() + 1;
	let nextMonth = new Date().getMonth() + 2 === 13 ? 1 : new Date().getMonth() + 2;
	const ticketMonth = data === 'MONTHLY-PASS' ? currentMonth : nextMonth;
	const month = ticketMonth;

	let days = 0;
	let months = [
		'',
		'January',
		'Febuary',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];

	if (
		month === 1 ||
		month === 3 ||
		month === 5 ||
		month === 7 ||
		month === 8 ||
		month === 10 ||
		month === 12
	) {
		days = 31;
	} else if (month === 4 || month === 6 || month === 9 || month === 11) {
		days = 30;
	} else if (month === 2) {
		const year = new Date().getFullYear();
		let isLeap = new Date(year, 1, 29).getDate() === 29;

		if (isLeap) {
			days = 29;
		} else {
			days = 28;
		}
	}
	const validity = `Valid 01 ${months[month]} - ${days} ${months[month]}`;
	return validity;
};

export const getMonthlyTicketType = (data) => {
	let months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	];

	let currentMonth = months[new Date().getMonth()];
	let nextMonth = new Date().getMonth() + 1 === 12 ? 0 : new Date().getMonth() + 1;
	let nextMonthFinal = months[nextMonth];
	const ticket =
		data === 'MONTHLY-PASS'
			? `Monthly (${currentMonth})`
			: `Monthly (${nextMonthFinal})`;
	return ticket;
};


export default commonUtils;