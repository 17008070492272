import React from "react";
import { Modal} from "react-bootstrap";

const ModalDialog = () => {
  return (
    <Modal
      className="custom-modal-popup view-order-modal"
    >
      <Modal.Body> 
      </Modal.Body>
      <Modal.Footer>
        <div className="modal-btn-group">
          </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDialog;
