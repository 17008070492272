import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import Footer from "../Footer/Footer";
import FooterWithPoweredBy from "../Footer/FooterWithPoweredBy";
import Header from "../Header/Header";
import auth from "../../actions/auth/auth";

class index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      sidebar: 0,
    };
  }
  handleResize = () => {
    this.setState({ windowHeight: window.innerHeight });
  };
  componentDidMount() {
    this.props.currentAuthenticatedUser();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleSidebar = () => {
    this.setState((state) => ({
      sidebar: state.sidebar ? 0 : 100,
    }));
  };

  hideSideBar = () => {
    this.setState({
      sidebar: 0,
    });
  };

  render() {
    const cognitoUser = JSON.parse(localStorage.getItem("cognitoUser"));
    const {
      shouldSpace = true,
      isPrintable = true,
      className = "",
      contentClassName = "",
      footer = 1,
      shouldFixedHeight = false,
    } = this.props;
    if (!this.props.isAuthenticated) return <Redirect to="/" />;
    return (
      <div
        className={`${
          !isPrintable ? "d-print-none" : "postiontheme"
        } ${className} ${shouldFixedHeight ? "fixed-layout" : ""}`}
      >
        {!_.isEmpty(cognitoUser) ? (
          <Header
            handleSidebar={this.handleSidebar}
            sidebar={this.state.sidebar}
          />
        ) : null}
        <div
          className={`${
            shouldSpace ? "section-padding" : ""
          } base-height ${contentClassName}`}
          onClick={this.hideSideBar}
        >
          {this.props.children}
        </div>
        {footer === 1 ? <Footer /> : <FooterWithPoweredBy />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => {
  return {
    currentAuthenticatedUser: () => dispatch(auth.currentAuthenticatedUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
