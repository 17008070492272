import { get } from "lodash";
import React from "react";
import { Spinner } from "react-bootstrap";
import { useExpanded, useFlexLayout, useResizeColumns, useRowSelect, useSortBy, useTable } from "react-table";

import ArrowDown from "../../../../assets/images/arrow_drop_down-24px.svg";
import ArrowUp from "../../../../assets/images/arrow_drop_up-24px.svg";
import { uniqueNo } from "../../utils/helper";

import "../../style/Table/Table.scss";

// const headerProps = (props, { column }) => getStyles(props, column.align);

function getAlignmentProps(align) {
  if (align === "center") {
    return "center";
  } else if (align === "right") {
    return "flex-end";
  } else {
    return "flex-start";
  }
}

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

const getStyles = (props, align = "left") => [
  props,
  {
    style: {
      justifyContent: getAlignmentProps(align),
      alignItems: "center",
      display: "flex",
      wordBreak: "break-all",
    },
  },
];

export const rowExpanderColumn = (headingText, options = {}, onExpand) => {
  return {
    // Build our expander column
    // id: "expander", // Make sure it has an ID
    ...options,
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded, column }) => (
      <span>{headingText}</span>
    ),
    Cell: ({ row, ...rest }) => {
      const depth = row.depth;
      const accessorKey =
        depth > 0 && options.subRowAccessor
          ? options.subRowAccessor
          : options.accessor;
      const cellText = get(row, `original.${accessorKey}`);

      const expandProps = row.getToggleRowExpandedProps();
      const onClick = expandProps.onClick;
      expandProps.onClick = (e) => {
        if (typeof onExpand === "function") {
          onExpand(row, rest);
        } else {
          onClick(e);
        }
      };
      return (
        <span
          className={`${row.isExpanded ? "expanded" : ""} can-extended`}
          {...expandProps}
          title={cellText}
        >
          {cellText}
        </span>
      );
    },
  };
};

const selectionColumn = (
  shouldRenderSelector,
  shouldEnable = () => true,
  onSelect
) => ({
  id: "selection",
  disableResizing: true,
  minWidth: 35,
  width: 35,
  maxWidth: 35,
  Header: ({ getToggleAllRowsSelectedProps }) => (
    <div>
      {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> */}
    </div>
  ),
  // The cell can use the individual row's getToggleRowSelectedProps method
  // to the render a checkbox
  Cell: ({ row, ...rest }) => {
    const cellProps = row.getToggleRowSelectedProps();
    if (onSelect) {
      cellProps.onChange = (e) => onSelect(e, row, rest);
    }
    return shouldRenderSelector(row) ? (
      <div>
        <IndeterminateCheckbox
          depth={row.depth}
          shouldEnable={shouldEnable(row, rest)}
          {...cellProps}
        />
      </div>
    ) : null;
  },
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, depth, shouldEnable, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    // console.log('rest', rest)
    const checkboxId = React.useRef(uniqueNo());
    return (
      <>
        <input
          type="checkbox"
          id={checkboxId.current}
          className="checkbox-custom"
          disabled={!shouldEnable}
          ref={resolvedRef}
          {...rest}
        />
        <label
          className="checkbox-custom-label"
          htmlFor={checkboxId.current}
        ></label>
      </>
    );
  }
);

function Table({
  columns,
  data,
  onSelectedRowChange,
  initialState = {},
  onSortChange,
  rawClassFnc = () => {},
  shouldRenderSelector = () => true,
  shouldEnable = () => true,
  onSelect,
  busy,
}) {
  // const customOrderByFn = React.useMemo(a => console.log("Hit Custom Func", a));
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    // selectedFlatRows,
    state: { selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      // manualSorting: true,
      // orderByFn: customOrderByFn
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    (hooks) => {
      hooks.allColumns.push((columns) => {
        if (onSelect) {
          columns.unshift(
            selectionColumn(shouldRenderSelector, shouldEnable, onSelect)
          );
        }
        return columns;
      });
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    },
    useExpanded,
    useRowSelect
  );
  React.useEffect(() => {
    if (typeof onSelectedRowChange === "function") {
      onSelectedRowChange(selectedRowIds);
    }
    // eslint-disable-next-line
  }, [selectedRowIds]);

  React.useEffect(() => {
    if (typeof onSortChange === "function") {
      onSortChange(sortBy);
    }
    // eslint-disable-next-line
  }, [sortBy]);

  const mainTableWrapperRef = React.useRef();

  return (
    <div style={{ position: "relative" }}>
      {busy ? (
        <div
          className="busy-area"
          style={{
            width: mainTableWrapperRef.current
              ? `${mainTableWrapperRef.current.offsetWidth}px`
              : "100%",
          }}
        >
          <div className="spinner">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>
      ) : null}

    <div className={`custom-table ${!rows.length && !busy ? 'no-data-in-table': ''}`} ref={mainTableWrapperRef}>
        <div {...getTableProps()} className="table">
          <div className="thead">
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps({
                  style: { display: "flex" },
                })}
                className="tr"
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps({
                      ...(column.isSortable
                        ? column.getSortByToggleProps()
                        : {}),
                      style: {
                        justifyContent: getAlignmentProps(column.align),
                      },
                    })}
                    className={`th ${column.headerClass ? column.headerClass: ''}`}
                  >
                    {column.render("Header")}
                    {column.isSortable ? (
                      <span className="sort-container">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img src={ArrowDown} alt="" />
                          ) : (
                            <img src={ArrowUp} alt="" />
                          )
                        ) : (
                          <>
                            <img src={ArrowUp} alt="" />
                            <img src={ArrowDown} alt="" />
                          </>
                        )}
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className="tbody">
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <div
                  {...row.getRowProps()}
                  className={`tr ${rawClassFnc(row, index)} row-depth-${
                    row.depth
                  }`}
                >
                  {row.cells.map((cell) => {
                    return (
                      <div
                        {...cell.getCellProps(cellProps)}
                        className={`td ${
                          cell.column.className ? cell.column.className : ""
                        }`}
                      >
                        <span
                          title={
                            ["string", "number"].includes(typeof cell.value)
                              ? cell.value
                              : ""
                          }
                        >
                          {cell.render("Cell")}
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {!rows.length && !busy ? (
              <div className="d-flex justify-content-center no-data-found">No Data Found</div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export const AppTable = Table;
