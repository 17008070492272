import appConstants from "../../constants/appConstants";

const initialState = {
  appleWallet: [],
  isLoading: false,
};

const {
  ADD_TO_APPLE_WALLET_START,
  ADD_TO_APPLE_WALLET_STOP,
  ADD_TO_APPLE_WALLET_COMPLETE
} = appConstants;


export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_APPLE_WALLET_START:
      return {
        ...state,
        isLoading: action.payload
      };

    case ADD_TO_APPLE_WALLET_STOP:
      return {
        ...state,
        isLoading: action.payload
      };

    case ADD_TO_APPLE_WALLET_COMPLETE:
      return {
        ...state,
        appleWallet: action.payload
      };

    default:
      return state;
  }
};
